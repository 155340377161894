import React from 'react';
import { useGetPlatformTypes } from 'hooks/queries/platformQueries';
import SelectAutoComplete from '../SelectAutoComplete';

function getOptionLabel(option) {
  return option ? option.name : '';
}

function getOptionSelected(option, value) {
  return value !== null && option.id === value.id;
}

function PlatformTypeSelector(props) {
  const { data: platformTypes, isLoading } = useGetPlatformTypes();

  return (
    <SelectAutoComplete
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      loading={isLoading}
      variables={platformTypes || []}
      {...props}
    />
  );
}

export default PlatformTypeSelector;
