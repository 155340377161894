import React from 'react';
import { Toolbar, Button, makeStyles, Typography } from '@material-ui/core';
import { format, getYear, getISOWeek, startOfWeek, endOfWeek } from 'date-fns';
import { fr } from 'date-fns/locale';

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: 100,
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.primary.main
        : theme.palette.background.default,
  },
  label: {
    color:
      theme.palette.type === 'light'
        ? theme.palette.primary.contrastText
        : theme.palette.background.default,
  },
  button: {
    margin: '0px',
    color: 'white',
  },
}));

const MyToolbar = ({ setOpenView, date }) => {
  const classes = useStyles();
  const today = new Date(date.valueOf());
  var weeknumber = getISOWeek(today);
  var year = getYear(today);
  var month = format(today, 'MMM', { locale: fr });

  const fromDate = format(startOfWeek(today, { weekStartsOn: 1 }), 'dd');
  const toDate = format(endOfWeek(today, { weekStartsOn: 1 }), 'dd');

  return (
    <Toolbar className={classes.toolbar}>
      <Button className={classes.button} color="primary" onClick={(e) => setOpenView('year')}>
        <span className={classes.label}>
          <Typography variant="h8">
            {year} Semaine {weeknumber}
          </Typography>
        </span>
      </Button>
      <Button className={classes.button} color="primary" onClick={(e) => setOpenView('date')}>
        <span className={classes.label}>
          <Typography align="center" variant="h4">
            {fromDate} {month} au {toDate}
          </Typography>
        </span>
      </Button>
    </Toolbar>
  );
};

export default MyToolbar;
