import makeStyles from '@material-ui/styles/makeStyles';

export const useStylesLeft = makeStyles((theme) => ({
  left: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(2) + 'px !important',
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(5) + 'px !important',
    },
  },
}));

export const useStylesRight = makeStyles((theme) => ({
  right: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(2) + 'px !important',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(5) + 'px !important',
    },
  },
}));

export default makeStyles((theme) => ({
  formGroup: {
    justifyContent: 'flex-start',
  },
  gutterLeft: {
    paddingLeft: theme.spacing(4),
  },
  gutterRight: {
    paddingRight: theme.spacing(4),
  },
}));
