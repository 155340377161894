import React, { useMemo, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useGetPlatforms } from 'hooks/queries/platformQueries';

import { Loading, Label } from 'components';
import DelayPlatform from './DelayPlatform';

// values is the list of platforms,
// Warning : if it's edit mode then the backend return this format [{platform:{id:""},travelTime:"",zone: ""}]
// if it's add mode then values is []
function DelayPlatformsEditor({ zoneId, values, onChangeZonePlatformTimes: onChange, errors }) {
  // get the list of all platforms, this endpoint include inactive platforms
  const { data: platforms, isLoading: isLoadingPlatforms } = useGetPlatforms();
  const activPlatforms = useMemo(
    () =>
      platforms
        ?.filter((platform) => platform.isActive)
        .map((platform) => {
          return { id: platform.id, name: platform.name };
        }),
    [platforms],
  );

  // on add mode, make values look like [{platform:{id:""},travelTime}]
  // otherwise : keep the old zoneplatformsTimes and and add  [{platform:{id:""},zone: {id: ""}, travelTime}]
  // for the not existing platforms
  useEffect(() => {
    if (activPlatforms && !isLoadingPlatforms) {
      zoneId
        ? onChange([
            ...activPlatforms
              .filter((item) => !values.some((v) => v.platform.id === item.id))
              .map((platform) => {
                return { platform: { id: platform.id }, zone: { id: zoneId }, travelTime: '' };
              }),
            ...values,
          ])
        : onChange([
            ...activPlatforms.map((platform) => {
              return { platform: { id: platform.id }, travelTime: '' };
            }),
            ...values,
          ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platforms, isLoadingPlatforms]);

  return (
    <Grid container spacing={2}>
      <Grid item container>
        <Grid item xs={4}>
          <Label>Plateformes</Label>
        </Grid>
        <Grid item xs={8}>
          <Label>Délai</Label>
        </Grid>
      </Grid>
      {isLoadingPlatforms ? (
        <Grid item xs={6}>
          <Loading />
        </Grid>
      ) : !activPlatforms || activPlatforms?.length === 0 ? (
        <Grid item xs={6}>
          <Label subLabel>Aucune plateforme</Label>
        </Grid>
      ) : (
        activPlatforms.map((platform) => (
          <DelayPlatform
            key={platform.id}
            values={{
              id: platform.id,
              name: platform.name,
              travelTime:
                values.find((v) => {
                  return v.platform.id === platform.id;
                })?.travelTime || '',
            }}
            onChange={onChange}
            zonePlatformTimes={values}
            errors={errors}
          />
        ))
      )}
    </Grid>
  );
}

export default DelayPlatformsEditor;
