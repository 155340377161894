import React, { useState } from 'react';
import { IconButton, CircularProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';

import { ConfirmDialog, ButtonWithLoading, AlertDialog } from 'components';

function DeleteButton({
  onDelete,
  isDeleting,
  disabled,
  deleteMessage,
  parentWidth,
  iconButton,
  informationMessage,
  showInfoDialogCondition,
}) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const onDeleteConfirm = () => {
    onDelete();
    setOpenConfirm(false);
  };
  // open the right dialog
  const openDialog = () => {
    showInfoDialogCondition ? setOpenAlert(true) : setOpenConfirm(true);
  };
  return (
    <>
      {iconButton ? (
        <IconButton onClick={() => setOpenConfirm(true)} disabled={isDeleting || disabled}>
          {isDeleting ? (
            <CircularProgress size={20} />
          ) : (
            <DeleteIcon color={disabled ? 'disabled' : 'error'} />
          )}
        </IconButton>
      ) : (
        <ButtonWithLoading
          disabled={isDeleting || disabled}
          isLoading={isDeleting}
          onClick={openDialog}
        >
          Supprimer
        </ButtonWithLoading>
      )}
      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={onDeleteConfirm}
        message={deleteMessage}
        parentWidth={parentWidth}
      />
      <AlertDialog
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        message={informationMessage}
        title="Information"
      />
    </>
  );
}

export default DeleteButton;

DeleteButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
  /** by default the button is disabled when isDeleting is true.
   * It should contain the extra conditions to disable the button if there is any.*/
  disabled: PropTypes.bool,
  /** the text to show when confirmDialog is open.*/
  deleteMessage: PropTypes.string.isRequired,
  parentWidth: PropTypes.number,
  /** show delete button as circular button*/
  iconButton: PropTypes.bool,
  /** the text to show when AlertDialog is open. Required when showInfoDialogCondition is passed */
  informationMessage: PropTypes.string,
  /** a condition to show AlertDialog instead of confirmDialog */
  showInfoDialogCondition: PropTypes.bool,
};
