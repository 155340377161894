import React, { useMemo, useState, useEffect } from 'react';
import {
  TableRow,
  TableCell,
  TextField,
  FormControlLabel,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import get from 'lodash/get';

import { TableButtons, Container, Table, Loading } from 'components';
import { routes } from 'config';
import { useDeleteVehicleMutation } from 'hooks/mutations/vehicleMutations';
import { useGetVehicles } from 'hooks/queries/vehicleQueries';

const headCells = [
  { id: 'name', label: 'Nom' },
  { id: 'type.name', label: 'Type' },
  { id: 'numberPlate', label: 'Immatriculation' },
  { id: 'capacity', label: 'Capacité' },
  { id: 'isActive', label: 'Statut' },
];

function filterVehicles(data, search, activeOnly) {
  if (search === '') {
    return activeOnly ? data.filter((vehicle) => vehicle.isActive) : data;
  }

  const searchRegexp = new RegExp(search, 'gi');
  const searchedFields = ['name', 'numberPlate'];
  return activeOnly
    ? data
        .filter((vehicle) => vehicle.isActive)
        .filter((u) => {
          return searchedFields.some((field) => get(u, field, '').match(searchRegexp));
        })
    : data.filter((u) => {
        return searchedFields.some((field) => get(u, field, '').match(searchRegexp));
      });
}

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
}));

function Vehicle() {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState(null);
  const [filter, setFilter] = useState({
    search: '',
    activeOnly: true,
  });
  const [
    deleteVehicle,
    { isLoading: isDeleting, isSuccess: isDeleted },
  ] = useDeleteVehicleMutation();
  const { data: vehciles, isLoading } = useGetVehicles();
  const filtredVehciles = useMemo(
    () => (vehciles ? filterVehicles(vehciles, filter.search, filter.activeOnly) : []),
    [vehciles, filter],
  );

  // unselect the row after deleted
  useEffect(() => {
    if (isDeleted && !isDeleting) {
      setSelectedItem(null);
    }
  }, [isDeleted, isDeleting]);

  return (
    <Container fullHeight={isLoading}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className={classes.filterContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.activeOnly}
                  onChange={(e) => setFilter((prev) => ({ ...prev, activeOnly: e.target.checked }))}
                />
              }
              label="Actif seulement"
            />
            <TextField
              value={filter.search}
              onChange={(e) => setFilter((prev) => ({ ...prev, search: e.target.value }))}
              placeholder="Nom/Immatriculation"
              label="Rechercher"
              variant="outlined"
            />
          </div>

          <Table
            data={filtredVehciles}
            getKey={(vehicle) => vehicle.id}
            getRowKey={(vehicle) => vehicle.id}
            paginate
            headCells={headCells}
            renderRow={(row) => (
              <TableRow
                key={row.id}
                hover
                tabIndex={-1}
                onClick={() => setSelectedItem(selectedItem === row ? null : row)}
                selected={row === selectedItem}
              >
                <TableCell>{row.name}</TableCell>
                <TableCell> {row.type ? row.type.name : ''}</TableCell>
                <TableCell> {row.numberPlate}</TableCell>
                <TableCell> {row.capacity}</TableCell>
                <TableCell>{row.isActive ? 'Actif' : 'Inactif'}</TableCell>
              </TableRow>
            )}
          />
          <TableButtons
            deleteMessage="Vous voulez vraiment supprimer ce véhicule ?"
            informationMessage="Ce véhicule est déjà inactif"
            editRoute={(item) => `${routes.vehicles.root}/edit/${item.id}`}
            addRoute={routes.vehicles.new}
            onDelete={() => deleteVehicle(selectedItem.id)}
            selectedItem={selectedItem}
            isDeleting={isDeleting}
          />
        </>
      )}
    </Container>
  );
}

export default Vehicle;
