import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import uuidv4 from 'uuid/v4';
import clsx from 'clsx';

import { Label } from 'components';
import DailyClientDacMenu from './DailyClientDacMenu';
import useStyles from './styles';
import { Alert } from '@material-ui/lab';

const headCells = [
  { label: 'LUNDI' },
  { label: 'MARDI' },
  { label: 'MERCREDI' },
  { label: 'JEUDI' },
  { label: 'VENDREDI' },
];
const firstRow = [
  { label: '' },
  { label: 'POIDS (kg)' },
  { label: 'POIDS (kg)' },
  { label: 'POIDS (kg)' },
  { label: 'POIDS (kg)' },
  { label: 'POIDS (kg)' },
  { label: '' },
];

function WeeklyFollowUpTable({ data, dateLabel }) {
  const classes = useStyles();
  const [pickupAnchorEls, setPickupAnchorEls] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 20,
  });

  const onCellClick = (e, row, key) => {
    if (row.daysOfTheWeek[key].length > 0) {
      setSelectedItem({ dayItem: row.daysOfTheWeek[key], user: row.user });
      setPickupAnchorEls(e.currentTarget);
    } else {
      unselectCell();
    }
  };

  const closeMenu = () => {
    setPickupAnchorEls(null);
  };

  const unselectCell = () => {
    setSelectedItem(null);
    setPickupAnchorEls(null);
  };

  const displayedRows = data.slice(
    pagination.page * pagination.rowsPerPage,
    pagination.page * pagination.rowsPerPage + pagination.rowsPerPage,
  );

  const partialTotalByDay = (dayIndex) =>
    displayedRows.reduce(
      (a, b) => a + b.daysOfTheWeek[dayIndex].reduce((partialSum, i) => partialSum + i.weight, 0),
      0,
    );

  return (
    <>
      {displayedRows.length === 0 && (
        <Alert severity="warning" className={classes.alert}>
          Aucune collecte enregistrée pour cette semaine.
        </Alert>
      )}
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.title}>{dateLabel}</TableCell>
              {headCells.map((item) => (
                <TableCell key={uuidv4()} className={classes.title}>
                  {item.label}
                </TableCell>
              ))}
              <TableCell className={classes.title} align="center">
                TOTAL POIDS/CLIENTS
              </TableCell>
            </TableRow>
            <TableRow>
              {firstRow.map((item) => (
                <TableCell key={uuidv4()}>{item.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedRows.length > 0 ? (
              <>
                {displayedRows.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell>{row.user.name}</TableCell>
                    {Object.keys(row.daysOfTheWeek).map((key) => (
                      <TableCell
                        key={key}
                        className={clsx({
                          [classes.selected]:
                            selectedItem &&
                            selectedItem.user.id === row.user.id &&
                            selectedItem.dayItem === row.daysOfTheWeek[key] &&
                            pickupAnchorEls,
                        })}
                        onClick={(e) => onCellClick(e, row, key)}
                      >
                        {row.daysOfTheWeek[key].length === 0
                          ? ''
                          : row.daysOfTheWeek[key].reduce(
                              (partialSum, b) => (b.weight ? partialSum + b.weight : partialSum),
                              0,
                            ) || 0}
                      </TableCell>
                    ))}

                    <TableCell align="center" className={classes.totalRow}>
                      {row.totalWeight}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell className={classes.totalRow}>TOTAL</TableCell>
                  <TableCell className={classes.totalRow}>{partialTotalByDay(1)}</TableCell>
                  <TableCell className={classes.totalRow}>{partialTotalByDay(2)}</TableCell>
                  <TableCell className={classes.totalRow}>{partialTotalByDay(3)}</TableCell>
                  <TableCell className={classes.totalRow}>{partialTotalByDay(4)}</TableCell>
                  <TableCell className={classes.totalRow}>{partialTotalByDay(5)}</TableCell>
                  <TableCell align="center" className={classes.totalRow}>
                    {displayedRows.reduce((a, b) => a + b.totalWeight, 0)}
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={7}>
                  <Label subLabel> Aucun élément </Label>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[20, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onChangePage={(event, newPage) => setPagination((prev) => ({ ...prev, page: newPage }))}
          onChangeRowsPerPage={(event) =>
            setPagination((prev) => ({
              rowsPerPage: parseInt(event.target.value, 10),
              page: 0,
            }))
          }
        />
      </TableContainer>

      <DailyClientDacMenu
        data={selectedItem}
        open={Boolean(pickupAnchorEls)}
        anchorEl={pickupAnchorEls}
        onClose={closeMenu}
      />
    </>
  );
}

export default WeeklyFollowUpTable;
