import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { pick } from 'lodash';
import { format } from 'date-fns';

import {
  Container,
  Section,
  ButtonsContainer,
  ButtonWithLoading,
  Loading,
  ErrorPage,
  CancelButton,
  SubmitAndRedirectButton,
  AlertDialog,
} from 'components';
import { useGetSameDayPickups } from 'hooks/queries/pickupQueries';
import { useGetDeposit, useGetDepositPickups } from 'hooks/queries/depositQueries';
import { useUpdateDepositMutation } from 'hooks/mutations/depositMutations';
import { useChangePickupsDeposit } from 'hooks/mutations/pickupMutation';

import { routes } from 'config';
import { PICKUP_STATUS } from 'config/constants';
import LeftSection from './components/LeftSection';
import RightSection from './components/RightSection';
import { validateForm } from './validation';

const initialValues = {
  depositTime: new Date(),
  depositSignature: null,
  comments: '',
  isRefused: false,
  contactName: '',
};

function DepositForm() {
  const { id } = useParams();
  const history = useHistory();
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(null);
  const valuesBeforeUpdate = useRef(values);
  const { data: deposit, isLoading, error } = useGetDeposit(id);
  const [openAlert, setOpenAlert] = useState(false);
  const [
    updateDeposit,
    { isLoading: isUpdating, isSuccess: isUpdated },
  ] = useUpdateDepositMutation();
  // the platform signature is checked or not
  const [isSignatureChecked, setIsSignatureChecked] = useState(false);
  // newContactName wont replace values.contactName
  // it will be only be visible in the signature BLOB
  const [isContactNameDeleted, setIsContactNameDeleted] = useState(false);
  const [newContactName, setNewContactName] = useState('');
  // the name that appears in the signing BLOB,
  // is used to verifiy that contact name and the signedwith are equal
  const [signedWith, setSignedWith] = useState('');
  // a deposit contains at least one pickup, all the pickups in a deposit are assigned to the same vehicle
  const { data: depositPickups } = useGetDepositPickups(values.id);
  const { data: allAfternoonPickups } = useGetSameDayPickups(
    depositPickups && depositPickups[0]
      ? format(new Date(depositPickups[0].date), 'yyyy/MM/dd')
      : null,
    false,
  );
  const [
    updateMorningPickupsDeposit,
    { isLoading: isChangingPickupsDeposit },
  ] = useChangePickupsDeposit();

  useEffect(() => {
    if (id && deposit && !isLoading) {
      setValues(deposit);
      valuesBeforeUpdate.current = deposit;
      deposit.depositSignature && setIsSignatureChecked(true);
    }
  }, [id, deposit, isLoading]);

  useEffect(() => {
    if (values.isRefused) {
      // when isRefused the backend will reset these values (isn't required to do this on the frontend)
      // it's just a feedback to the user
      setValues((prev) => ({ ...prev, depositSignature: null }));
      setIsSignatureChecked(false);
    }
  }, [values.isRefused]);

  // suggest to the driver to report the morning deposit if in afternoon he has the same depot and the vehicle capcity can
  // take the pickups of morning and afternoon together and there is no morning pickups not treated (planned)

  useEffect(() => {
    if (
      depositPickups &&
      depositPickups[0].isAm &&
      depositPickups.every(
        (pickup) => pickup.status && pickup.status.codename !== PICKUP_STATUS.PLANNED,
      ) &&
      allAfternoonPickups &&
      allAfternoonPickups.length > 0
    ) {
      // afternoon pickups with same vehicle and same platform as the morning deposit
      const afternoonpickups = allAfternoonPickups.filter(
        (pickup) =>
          pickup.vehicle.id === depositPickups[0].vehicle.id &&
          pickup.deposit.platform.id === deposit.platform.id,
      );
      if (!afternoonpickups || afternoonpickups.length === 0) {
        return;
      }
      const vehicleMaxCapacity = depositPickups[0].vehicle.capacity;
      // get the morning collected pickups for the vehicle
      const morningCapacity = depositPickups
        .filter((pickup) => pickup.status.codename === PICKUP_STATUS.COLLECTED)
        .reduce((partialSum, b) => partialSum + b.weight, 0);
      const afternoonCapacity = afternoonpickups.reduce(
        (partialSum, b) => partialSum + b.weight,
        0,
      );
      if (morningCapacity + afternoonCapacity <= vehicleMaxCapacity) {
        setOpenAlert(true);
        updateMorningPickupsDeposit({
          oldDeposit: values.id,
          newDeposit: afternoonpickups[0].deposit.id,
        });
      }
    }
  }, [
    allAfternoonPickups,
    depositPickups,
    updateMorningPickupsDeposit,
    values.id,
    deposit.platform.id,
  ]);

  const submit = () => {
    const formErrors = validateForm(
      values,
      isSignatureChecked,
      signedWith,
      newContactName,
      isContactNameDeleted,
    );
    if (formErrors !== true) {
      setErrors(formErrors);
    } else {
      setErrors({});
      updateDeposit({
        ...pick(values, ['id', 'depositSignature', 'comments', 'isRefused']),
        // a deposit contains at least one pickup
        // initiate depositTime date part with the first pickup date
        depositTime: `${format(new Date(depositPickups[0].date), 'yyyy-MM-dd')} ${format(
          values.depositTime,
          'HH:mm',
        )}:00`,
        contactName:
          isSignatureChecked && !values.contactName ? newContactName : values.contactName,
      });
    }
  };

  if (isLoading || isChangingPickupsDeposit) {
    return (
      <Container fullHeight>
        <Loading />
      </Container>
    );
  } else if (error && error.response) {
    return (
      <Container fullHeight>
        <ErrorPage error={error} notFoundMessage="Le dépôt n'existe pas" />
      </Container>
    );
  }

  return (
    <Container>
      <Section spacing={2}>
        <LeftSection
          values={values}
          onChange={setValues}
          depositPickups={depositPickups}
          errors={errors}
        />
        <RightSection
          values={values}
          onChange={setValues}
          errors={errors}
          isSignatureChecked={isSignatureChecked}
          setIsSignatureChecked={setIsSignatureChecked}
          isContactNameDeleted={isContactNameDeleted}
          setIsContactNameDeleted={setIsContactNameDeleted}
          setSignedWith={setSignedWith}
          newContactName={newContactName}
          setNewContactName={setNewContactName}
        />
      </Section>
      <ButtonsContainer>
        <SubmitAndRedirectButton
          onClick={submit}
          isLoading={isUpdating}
          isSuccess={isUpdated}
          redirectPath={routes.circuit.root}
          isError={errors}
        />
        <ButtonWithLoading onClick={submit} isLoading={isUpdating}>
          Valider
        </ButtonWithLoading>
        <CancelButton redirectPath={routes.circuit.root} />
      </ButtonsContainer>
      <AlertDialog
        open={openAlert}
        title="Information"
        message={
          "Vous avez un dépôt l'après-midi aux mêmes plateformes, et la capacité du véhicule " +
          "est inférieure au poids des collectes du matin et de l'après-midi. " +
          "Les collectes du dépôt du matin seront reportées pour l'après-midi."
        }
        onClose={() => {
          setOpenAlert(false);
          history.push(routes.circuit.root);
        }}
      />
    </Container>
  );
}

export default DepositForm;
