import React from 'react';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { Loading } from 'components';
import { useGetProductTypes } from 'hooks/queries/pickupQueries';

function ProductTypeInput({ value, onChange, disabled, row }) {
  const { data, isLoading } = useGetProductTypes();
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <RadioGroup
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          row={row}
        >
          {data.map((productType) => (
            <FormControlLabel
              key={productType.id}
              value={String(productType.id)}
              control={<Radio />}
              label={productType.name}
              disabled={disabled}
            />
          ))}
        </RadioGroup>
      )}
    </>
  );
}

export default ProductTypeInput;
