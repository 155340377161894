import React, { useMemo } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { Loading } from 'components';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import identity from 'lodash/identity';

// return a function that gets the specified label
//  for an option or the default emptyLabel if empty value is allowed
function createOptionLabelGetter(allowEmpty, emptyLabel, emptyValue, getOptionLabelProp) {
  return (option) => {
    if (allowEmpty && option === emptyValue) {
      return emptyLabel;
    }
    return getOptionLabelProp(option);
  };
}

function isStringOptionSelected(option, value) {
  return option === value;
}

/**
 * Component that allows selection of item in a given list, with autocomplete support.
 */
function Select({
  variables,
  placeholder = '',
  onChange,
  disabled,
  loading,
  filterVariables = identity,
  allowEmpty,
  emptyLabel,
  emptyValue = '',
  TextInputProps = {},
  removeAdornment,
  getOptionLabel: getOptionLabelProp = identity,
  getOptionSelected = isStringOptionSelected,
  iconClasseName,
  ...other
}) {
  const handleChange = (event, value) => {
    onChange(event, value);
  };

  const options = useMemo(() => {
    const filteredVariables = filterVariables(variables);
    if (allowEmpty) {
      return [emptyValue, ...filteredVariables];
    }
    return filteredVariables;
  }, [variables, allowEmpty, emptyValue, filterVariables]);

  const getOptionLabel = createOptionLabelGetter(
    allowEmpty,
    emptyLabel,
    emptyValue,
    getOptionLabelProp,
  );

  return (
    <Autocomplete
      loading={loading}
      autoComplete
      loadingText="Chargement..."
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleChange}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          {...TextInputProps}
          placeholder={placeholder}
          InputProps={
            removeAdornment
              ? params.InputProps
              : {
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={iconClasseName} color="disabled" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <Loading small /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }
          }
        />
      )}
      {...other}
    />
  );
}

export default Select;
