import { useMutation, useQueryCache } from 'react-query';
import { useSnackbar } from 'notistack';

import {
  addRecurrence as addRequest,
  updateRecurrence as updateRequest,
} from 'services/api/terraLeoApi/recurrenceApi';

function useAddRecurrenceMutation() {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(addRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryCache.refetchQueries('recurrences');
      enqueueSnackbar('la collecte a bien été ajoutée.', {
        variant: 'success',
      });
    },
  });
}

function useUpdateRecurrenceMutation() {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(updateRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryCache.refetchQueries('recurrences');
      enqueueSnackbar('La collecte a bien été modifiée.', {
        variant: 'success',
      });
    },
  });
}

export { useAddRecurrenceMutation, useUpdateRecurrenceMutation };
