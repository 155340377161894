import React, { useState, useRef } from 'react';
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  Divider,
  IconButton,
  Menu,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';

import LogoText from 'assets/aisiYellowLogo.png';
import { useAuth } from 'contexts/authContext';
import MainListItems from './ListItems';
import useStyles from './styles';

function Layout({ open, setOpen }) {
  const classes = useStyles();
  const { user, logout } = useAuth();
  const [openProfile, setOpenProfile] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpenProfile((prevOpen) => !prevOpen);
  };

  const handelLogOutClicked = () => {
    logout();
    setOpenProfile(false);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenProfile(false);
  };

  return (
    <>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.grow} />
          <div onClick={handleToggle} style={{ cursor: 'pointer' }}>
            {user.nickname}
          </div>
          <IconButton
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className={classes.profileIcon}
            color="inherit"
          >
            <AccountCircleIcon />
          </IconButton>
          <Menu
            open={openProfile}
            anchorEl={anchorRef.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            getContentAnchorEl={null}
            disablePortal
            keepMounted
            onClose={handleClose}
            autoFocus={false}
          >
            <MenuList>
              <MenuItem onClick={handelLogOutClicked}>Se déconnecter</MenuItem>
            </MenuList>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbarIcon}>
          <img src={LogoText} className={classes.logo} alt="logo" />
          <IconButton onClick={() => setOpen(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <MainListItems />
      </Drawer>
    </>
  );
}

export default Layout;
