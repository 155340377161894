import terraLeoApiClient from 'services/api/terraLeoApi/terraLeoApiClient';

import { BACKEND_CLIENT_HOURS_ENDPOINTS } from '../constants';

async function getClientHours(id) {
  const response = await terraLeoApiClient.getRequest(
    BACKEND_CLIENT_HOURS_ENDPOINTS.GET_CLIENT_HOURS(id),
  );
  return response.data;
}

// clientHours look like : { dayNumber : {am [start,end] , pm : []}}
async function addClientHours({ id, clientHours }) {
  const response = await terraLeoApiClient.postRequest(
    BACKEND_CLIENT_HOURS_ENDPOINTS.ADD_CLIENT_HOURS(id),
    clientHours,
  );
  return response.data;
}

// clientHours look like : { platformHourId : [start,end]}}
async function updateClientHours({ id, clientHours }) {
  const response = await terraLeoApiClient.patchRequest(
    BACKEND_CLIENT_HOURS_ENDPOINTS.EDIT_CLIENT_HOURS(id),
    clientHours,
  );
  return response.data;
}

async function deleteClientHours({ id, clientHoursIdArray }) {
  const response = await terraLeoApiClient.deleteRequest(
    BACKEND_CLIENT_HOURS_ENDPOINTS.DELETE_CLIENT_HOURS(id),
    {
      data: clientHoursIdArray,
    },
  );
  return response.data;
}

export { getClientHours, addClientHours, updateClientHours, deleteClientHours };
