import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty, pick, cloneDeep } from 'lodash';

import { useGetAddress } from 'hooks/queries/geoApiQueries';
import {
  getOpeningHoursChanges,
  openingDatesToHourString,
  openingHourStringToDates,
} from 'services/openingHoursService';
import { useGetClientHours } from 'hooks/queries/clientHoursQueries';
import { useGetClient } from 'hooks/queries/clientQueries';
import { useAddClientmMutation, useUpdateClientMutation } from 'hooks/mutations/clientMutations';
import {
  useAddClientHoursMutation,
  useUpdateClientHoursMutation,
  useDeleteClientHoursMutation,
} from 'hooks/mutations/clientHoursMutations';
import {
  useAddBillableClientmMutation,
  useUpdateBillableClientMutation,
} from 'hooks/mutations/billableClientMutations';
import {
  Container,
  Section,
  ButtonsContainer,
  ButtonWithLoading,
  ConfirmDialog,
  AlertDialog,
  ErrorPage,
  Loading,
  CancelButton,
  SubmitAndRedirectButton,
} from 'components';
import LeftSection from './component/LeftSection';
import RightSection from './component/RightSection';
import { validateForm } from './validation';
import { routes } from 'config';

const initialValues = {
  name: '',
  address: '',
  additionalAddress: '',
  zip: '',
  city: '',
  contactName: '',
  phone: '',
  email: '',
  latitude: '',
  longitude: '',
  platform: '',
  dailyWasteAmount: 0,
  agreementNumber: '',
  isActive: true,
  billable: '',
  zone: '',
  type: '',
  comments: '',
};

/**
 * this component interacts with 3 endpoints : /clients /billables and /client/{id}/hours
 */
function ClientForm() {
  const { id } = useParams();
  const [values, setValues] = useState(initialValues);
  const [isBillable, setIsBillable] = useState(false);
  const [errors, setErrors] = useState(null);
  const valuesBeforeUpdate = useRef(values);
  const { data: client, error, isLoading } = useGetClient(id);
  const [
    addCollectableClient,
    { isLoading: isAddingClient, isSuccess: isAdded, data: addedClient },
  ] = useAddClientmMutation(setErrors);
  const [
    updateClient,
    { isLoading: isUpdatingClient, isSuccess: isUpdated },
  ] = useUpdateClientMutation(setErrors);
  // billable client
  const [
    addBillableClient,
    { isSuccess: isBillableClientAdded, data: addedBillableClient },
  ] = useAddBillableClientmMutation();
  const [updateBillableClient] = useUpdateBillableClientMutation();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  // client hours
  const [timeTable, setTimeTable] = useState({});
  const timeTableBeforeUpdate = useRef(timeTable);
  const { data: clientHours, isLoading: isLoadingHours } = useGetClientHours(id);
  const [addClientHours] = useAddClientHoursMutation();
  const [updateClientmHours] = useUpdateClientHoursMutation();
  const [deleteClientHours] = useDeleteClientHoursMutation();

  useGetAddress(values.address, values.city, values.zip, (lat, long) => {
    setValues((prev) => ({ ...prev, latitude: lat, longitude: long }));
  });

  // load client
  useEffect(() => {
    if (id && client && !isLoading) {
      setValues(client);
      valuesBeforeUpdate.current = client;
      setIsBillable(client.billable.name === client.name);
    }
  }, [id, client, isLoading]);

  // load client hours
  useEffect(() => {
    if (id && clientHours && !isLoadingHours) {
      const convertedClientHours = openingHourStringToDates(clientHours);
      setTimeTable(convertedClientHours);
      timeTableBeforeUpdate.current = convertedClientHours;
    }
  }, [id, clientHours, isLoadingHours]);

  const isTimeTableChanged = useMemo(
    () => JSON.stringify(timeTableBeforeUpdate.current) !== JSON.stringify(timeTable),
    [timeTable],
  );

  useEffect(() => {
    // reset form and add client hours when the client is successfully added
    if (isAdded && addedClient) {
      // add client hours if the timeTable has changed
      if (isTimeTableChanged) {
        addClientHours({
          id: addedClient.id,
          clientHours: openingDatesToHourString(timeTable),
        });
        // reset timeTable
        setTimeTable({});
      }
      // reset form
      setValues(initialValues);
    }
  }, [
    isAdded,
    addedClient,
    addClientHours,
    setTimeTable,
    setValues,
    isTimeTableChanged,
    timeTable,
  ]);

  // when a new billable client is added, need to add a clone in collectable client
  useEffect(() => {
    if (isBillableClientAdded && addedBillableClient) {
      const valuesClone = cloneDeep(values);
      if (values.type) {
        valuesClone.type = values.type.id;
      }
      valuesClone.zone = values.zone.id;
      valuesClone.billable = values.billable.id;
      valuesClone.platform = values.platform.id;
      valuesClone.billable = addedBillableClient.id;
      // remove the spacing in the phone added by the mask
      if (values.phone) {
        valuesClone.phone = removePhoneEmptySpace();
      }
      addCollectableClient(valuesClone);
    }
  }, [isBillableClientAdded, addedBillableClient]); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = () => {
    const formErrors = validateForm(values, isBillable);
    if (formErrors !== true) {
      setErrors(formErrors);
    } else {
      setErrors({});
      if (id) {
        if (valuesBeforeUpdate.current.name === valuesBeforeUpdate.current.billable.name) {
          valuesBeforeUpdate.current.isActive === true && values.isActive === false
            ? setOpenConfirm(true)
            : valuesBeforeUpdate.current.isActive === false && values.isActive === true
            ? setOpenAlert(true)
            : update();
        } else {
          update();
        }
      } else {
        add();
      }
    }
  };

  const update = () => {
    const valuesClone = cloneDeep(values);
    // optionnel attributs
    if (values.type) {
      valuesClone.type = values.type.id;
    }
    if (values.billable) {
      valuesClone.billable = values.billable.id;
    }
    // zone and platform required attributs
    valuesClone.zone = values.zone.id;
    valuesClone.platform = values.platform.id;

    // remove the spacing in the phone added by the mask
    if (values.phone) {
      valuesClone.phone = removePhoneEmptySpace();
    }

    // check if the collectable client becames billable client
    if (
      valuesBeforeUpdate.current.billable.name !== valuesBeforeUpdate.current.name &&
      isBillable
    ) {
      // if it's the case add extra field changeToBillable
      valuesClone.changeToBillable = true;
      //to make sure that billable isn't null
      valuesClone.billable = valuesBeforeUpdate.current.billable.id;
      updateClient(valuesClone);
    } else {
      if (isBillable) {
        updateBillableClient({
          id: valuesClone.billable,
          ...pick(valuesClone, [
            'name',
            'address',
            'additionalAddress',
            'zip',
            'city',
            'phone',
            'email',
            'isActive',
          ]),
        });
        // we can't assign another billable client for a billable client.
        // to make sure that billable hasn't changed, we assigne it to it's orignal value
        valuesClone.billable = valuesBeforeUpdate.current.billable.id;
        updateClient(valuesClone);
      } else {
        updateClient(valuesClone);
      }
    }
    // update client opening hours if it has been modified
    updateTimeTable();
  };

  const updateTimeTable = () => {
    if (isTimeTableChanged) {
      const [newClientHours, modifiedClientHours, deletedClientHoursIds] = getOpeningHoursChanges(
        timeTable,
        timeTableBeforeUpdate.current,
      );
      // call the API to add, update, delete client opening hours
      deletedClientHoursIds.length > 0 &&
        deleteClientHours({ id, clientHoursIdArray: deletedClientHoursIds });
      !isEmpty(modifiedClientHours) && updateClientmHours({ id, clientHours: modifiedClientHours });
      !isEmpty(newClientHours) && addClientHours({ id, clientHours: newClientHours });
    }
  };

  const add = () => {
    const valuesClone = cloneDeep(values);
    // optionnel attributs
    if (values.type) {
      valuesClone.type = values.type.id;
    }
    if (values.billable) {
      valuesClone.billable = values.billable.id;
    }
    // remove the spacing in the phone added by the mask
    if (values.phone) {
      valuesClone.phone = removePhoneEmptySpace();
    }

    // zone and platform are required attributs
    valuesClone.zone = values.zone.id;
    valuesClone.platform = values.platform.id;

    if (isBillable) {
      addBillableClient(
        pick(valuesClone, [
          'name',
          'address',
          'additionalAddress',
          'zip',
          'city',
          'phone',
          'email',
          'isActive',
        ]),
      );
      // when a new billable client is added, we need to add it's clone in collectable table. this happens in the useEffect
    } else {
      addCollectableClient(valuesClone);
    }
  };

  const onCloseAlert = () => {
    update();
    setOpenAlert(false);
  };

  const onConfirmDelete = () => {
    update();
    setOpenConfirm(false);
  };

  // before update or add, should remove the extra space in phone number
  // added by the mask, otherwise backend will reply with BAD REQUEST
  const removePhoneEmptySpace = () => {
    return values.phone.replace(/\D/g, '');
  };

  if (isLoading) {
    return (
      <Container fullHeight>
        <Loading />
      </Container>
    );
  } else if (error && error.response) {
    return (
      <Container fullHeight>
        <ErrorPage error={error} notFoundMessage="Le client n'existe pas" />
      </Container>
    );
  }

  return (
    <Container>
      <Section spacing={2}>
        <LeftSection
          values={values}
          onChange={setValues}
          isBillable={isBillable}
          setIsBillable={setIsBillable}
          errors={errors}
          isEditMode={id}
          valuesBeforeUpdate={valuesBeforeUpdate}
        />
        <RightSection
          values={values}
          onChange={setValues}
          timeTable={timeTable}
          setTimeTable={setTimeTable}
          errors={errors}
          isEditMode={id}
          isBillable={isBillable}
        />
      </Section>
      <ButtonsContainer>
        <SubmitAndRedirectButton
          onClick={submit}
          isLoading={isAddingClient || isUpdatingClient}
          isSuccess={isAdded || isUpdated}
          redirectPath={routes.clients.root}
          isError={errors}
        />
        <ButtonWithLoading isLoading={isAddingClient || isUpdatingClient} onClick={submit}>
          Valider
        </ButtonWithLoading>
        <CancelButton redirectPath={routes.clients.root} />
      </ButtonsContainer>
      <ConfirmDialog
        open={openConfirm}
        message={
          'Attention, la désactivation de ce client entraînera la désactivation ' +
          'de tous les clients de collecte associés. Voulez-vous continuer ?'
        }
        onClose={() => setOpenConfirm(false)}
        onConfirm={onConfirmDelete}
      />
      <AlertDialog
        open={openAlert}
        message={
          'Attention, ceci ne réactivera pas les clients collectables associés.' +
          'Vous devrez les réactiver manuellement.'
        }
        onClose={onCloseAlert}
      />
    </Container>
  );
}

export default ClientForm;
