import React from 'react';
import { TextField } from '@material-ui/core';

import { maskPhoneNumber } from 'services/formatUtils';

function PhoneNumberInput({ value, onChange, ...props }) {
  return (
    <TextField
      value={maskPhoneNumber(value)}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Téléphone"
      inputProps={{ maxLength: 14 }}
      {...props}
    />
  );
}

export default PhoneNumberInput;
