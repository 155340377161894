import terraLeoApiClient from 'services/api/terraLeoApi/terraLeoApiClient';

import { BACKEND_PLANNING_ENDPOINTS } from '../constants';

async function duplicatePlanning(data) {
  const response = await terraLeoApiClient.postRequest(
    `${BACKEND_PLANNING_ENDPOINTS.DUPLICATE}?n=${data.n}&weekNumber=${data.weekNumber}&startWeek=${data.startWeek}`,
  );
  return response.data;
}

async function generatePlanning() {
  const response = await terraLeoApiClient.postRequest(BACKEND_PLANNING_ENDPOINTS.GENERATE);
  return response.data;
}

export { duplicatePlanning, generatePlanning };
