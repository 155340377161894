import { useMutation, useQueryCache } from 'react-query';
import { useSnackbar } from 'notistack';

import {
  addBillableClient as addRequest,
  updateBillableClient as updateRequest,
  deleteBillableClient as deleteRequest,
} from 'services/api/terraLeoApi/billableClientApi';

function useAddBillableClientmMutation() {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(addRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryCache.refetchQueries('billableClients');
      queryCache.refetchQueries('clients');
      enqueueSnackbar('le client de facturation a bien été ajouté.', {
        variant: 'success',
      });
    },
  });
}

function useUpdateBillableClientMutation() {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(updateRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryCache.refetchQueries('billableClients');
      queryCache.refetchQueries('clients');
      enqueueSnackbar('Le client de facturation a bien été modifié.', {
        variant: 'success',
      });
    },
  });
}

function useDeleteBillableClientMutation() {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();

  return useMutation(deleteRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryCache.refetchQueries('billableClients');
      queryCache.refetchQueries('clients');
      enqueueSnackbar('Le client de facturation a bien été supprimé.', {
        variant: 'success',
      });
    },
  });
}

export {
  useAddBillableClientmMutation,
  useUpdateBillableClientMutation,
  useDeleteBillableClientMutation,
};
