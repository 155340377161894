import { useMutation, useQueryCache } from 'react-query';
import { useSnackbar } from 'notistack';

import {
  addPlatform as addRequest,
  updatePlatform as updateRequest,
  deletePlatform as deleteRequest,
} from 'services/api/terraLeoApi/platformApi';

function useAddPlatformMutation(setErrors) {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(addRequest, {
    onError: (error) => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
      if (error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      }
    },
    onSuccess: () => {
      // update the list of cached plaforms
      queryCache.refetchQueries('platforms');
      enqueueSnackbar('la plateforme a bien été ajoutée.', {
        variant: 'success',
      });
    },
  });
}

function useUpdatePlatformMutation(setErrors) {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(updateRequest, {
    onError: (error) => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
      if (error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      }
    },
    onSuccess: () => {
      queryCache.refetchQueries('platforms');
      enqueueSnackbar('La plateforme a bien été modifiée.', {
        variant: 'success',
      });
    },
  });
}

function useDeletePlatformMutation() {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(deleteRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryCache.refetchQueries('platforms');
      enqueueSnackbar('La plateforme a bien été supprimée.', {
        variant: 'success',
      });
    },
  });
}

export { useAddPlatformMutation, useUpdatePlatformMutation, useDeletePlatformMutation };
