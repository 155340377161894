import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
  Slide,
} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(() => ({
  paper: {
    width: ({ parentWidth }) => `${parentWidth - 50}px`,
  },
}));

function ConfirmDialog({ open, onClose, onConfirm, message, title, parentWidth }) {
  const classes = useStyles({ parentWidth });
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      fullWidth={true}
      PaperProps={{
        className: classes.paper,
      }}
    >
      <DialogTitle id="alert-dialog-title">{title || 'Confirmation'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Confirmer
        </Button>
        <Button onClick={onClose}>Annuler</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
