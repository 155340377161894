import { useQuery } from 'react-query';

import { getRecurrence, getRecurrences } from 'services/api/terraLeoApi/recurrenceApi';

function useGetRecurrence(id) {
  return useQuery(['recurrence', id], () => getRecurrence(id), { enabled: id });
}

function useGetRecurrences() {
  return useQuery('recurrences', getRecurrences);
}

export { useGetRecurrence, useGetRecurrences };
