import terraLeoApiClient from 'services/api/terraLeoApi/terraLeoApiClient';

/**
 * Send a login request
 * @param {Object} data Request body
 * @param {string} data.email
 * @param {string} data.password
 */
async function login(data) {
  const response = await terraLeoApiClient.postRequest('/auth/login', data);
  return response;
}

/**
 * Get a new auth token from a previously issued refresh token
 * @param {Object} data Request body
 * @param {string} data.refreshToken The refresh token
 */
async function refreshToken(data) {
  const response = await terraLeoApiClient.postRequest('/auth/refresh-token', data);
  return response;
}

export { login, refreshToken };
