import { Menu, MenuItem, MenuList } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import { Label } from 'components';
import { useGetDac } from 'hooks/queries/dacQueries';
import { green } from 'theme/colors';

function DailyClientDacMenu({ data, ...menuProps }) {
  const getDac = useGetDac();

  const availableDacs = data ? data.dayItem.filter((dac) => dac.fileDigitalSignature) : [];

  return (
    <Menu
      keepMounted
      autoFocus={false}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...menuProps}
    >
      <MenuList>
        {availableDacs.length > 0 ? (
          availableDacs.map((dac) => (
            <MenuItem key={dac.dacId} onClick={() => getDac(dac.dacId)}>
              {dac.dacId} <GetAppIcon style={{ color: green }} />
            </MenuItem>
          ))
        ) : (
          <MenuItem>
            <Label subLabel>Aucun DAC disponible</Label>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
}

export default DailyClientDacMenu;
