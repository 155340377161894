import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles';

function ErrorPageBase({ message, errorCode }) {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" justify="center">
      <Grid item>
        <div className={classes.noselect}>
          <div className={classes.errorCode}>{errorCode}</div>
          <Typography className={classes.message}>
            <span className={classes.oops}>Oops!</span>{' '}
            {message ? message : 'Une erreur est survenue, veuillez réessayer ultérieurement.'}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}

export default ErrorPageBase;
