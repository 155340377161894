import React from 'react';
import { Grid, TextField } from '@material-ui/core';

import { FormGroup, OpeningDateInput, Label, ErrorHelper } from 'components';

function LeftSection({ values, onChange, timeTable, setTimeTable, errors }) {
  return (
    <FormGroup.Right sm={12} md={6} lg={6}>
      <Grid container spacing={2}>
        <Grid item container alignItems="baseline">
          <Grid item xs={4}>
            <Label small>Horaire</Label>
          </Grid>
          <Grid item xs={8}>
            <OpeningDateInput value={timeTable} onChange={setTimeTable} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Agrément N°</Label>
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              value={values.agreementNumber}
              onChange={(e) => onChange((prev) => ({ ...prev, agreementNumber: e.target.value }))}
              placeholder="Agrément"
            />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>SIRET N°</Label>
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              value={values.siret}
              onChange={(e) => onChange((prev) => ({ ...prev, siret: e.target.value }))}
              placeholder="SIRET"
              type="number"
            />
            {errors?.siret && <ErrorHelper message={errors.siret} />}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Commentaire</Label>
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              value={values.comments}
              onChange={(e) => onChange((prev) => ({ ...prev, comments: e.target.value }))}
              placeholder="Commentaire"
              rows={4}
              multiline
            />
          </Grid>
        </Grid>
      </Grid>
    </FormGroup.Right>
  );
}

export default LeftSection;
