import React, { useState, useMemo } from 'react';

import {
  Button,
  Typography,
  Switch,
  makeStyles,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { omitBy } from 'lodash';

import { dayToNumber } from 'services/dateUtils';
import OpeningDay from './OpeningDay';
import ErrorHelper from 'components/ErrorHelper';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  addButtonContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
  timepicker: {
    maxWidth: '100px',
  },
}));

const initialeState = {
  days: {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
  },
  fullDay: true,
  time: {
    amStart: null,
    amEnd: null,
    pmStart: null,
    pmEnd: null,
  },
};

function NewOpeningDateDialog({ open, onClose, onAdd, disabledDays }) {
  const classes = useStyles();
  const [tempTimeTable, setTimeTable] = useState(initialeState);
  const [invalid, setInvalid] = useState(false);

  const add = () => {
    const { amStart, amEnd, pmStart, pmEnd } = tempTimeTable.time;
    let error = false;
    let timePeriod = {};
    if (tempTimeTable.fullDay) {
      if (amStart > amEnd) {
        setInvalid(true);
        error = true;
      } else {
        invalid && setInvalid(false);
        Object.keys(omitBy(tempTimeTable.days, (day) => day === false)).forEach((day) => {
          timePeriod[dayToNumber(day)] = {
            am: [amStart, amEnd],
            pm: [amStart, amEnd],
          };
        });
      }
    } else {
      if (
        amStart > amEnd ||
        amStart > pmStart ||
        amStart > pmEnd ||
        amEnd > pmStart ||
        amEnd > pmEnd ||
        pmStart > pmEnd
      ) {
        error = true;
        setInvalid(true);
      } else {
        invalid && setInvalid(false);
        Object.keys(omitBy(tempTimeTable.days, (day) => day === false)).forEach((day) => {
          timePeriod[dayToNumber(day)] = {
            am: [amStart, amEnd],
            pm: [pmStart, pmEnd],
          };
        });
      }
    }
    if (!error) {
      onAdd(timePeriod);
      onClose();
      // reset
      setTimeTable(initialeState);
    }
  };

  const noDayIsSelected = useMemo(
    () => Object.keys(omitBy(tempTimeTable.days, (day) => day === false)).length === 0,
    [tempTimeTable.days],
  );

  const invalidTime = useMemo(() => {
    if (!tempTimeTable.fullDay) {
      return (
        !tempTimeTable.time.amStart ||
        !tempTimeTable.time.amEnd ||
        !tempTimeTable.time.pmStart ||
        !tempTimeTable.time.pmEnd
      );
    } else {
      return !tempTimeTable.time.amStart || !tempTimeTable.time.amEnd;
    }
  }, [tempTimeTable.time, tempTimeTable.fullDay]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="#new-opening-date-dialog-title">
      <DialogTitle id="new-opening-date-dialog-title">Définir des horaires d'ouverture</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Switch
              checked={tempTimeTable.fullDay}
              onChange={(e) => setTimeTable((prev) => ({ ...prev, fullDay: e.target.checked }))}
            />
          }
          label="Continu"
        />
        <div className={classes.container}>
          <Typography component="span">AM</Typography>
          <KeyboardTimePicker
            ampm={false}
            emptyLabel="Début"
            value={tempTimeTable.time.amStart}
            onChange={(value) =>
              setTimeTable((prev) => ({ ...prev, time: { ...prev.time, amStart: value } }))
            }
            className={classes.timepicker}
            invalidDateMessage="Invalide"
          />
          <KeyboardTimePicker
            emptyLabel="Fin"
            ampm={false}
            value={tempTimeTable.time.amEnd}
            disabled={!tempTimeTable.time.amStart}
            onChange={(value) =>
              setTimeTable((prev) => ({ ...prev, time: { ...prev.time, amEnd: value } }))
            }
            className={classes.timepicker}
            invalidDateMessage="Invalide"
          />
        </div>
        {!tempTimeTable.fullDay && (
          <div className={classes.container}>
            <Typography component="span">PM</Typography>
            <KeyboardTimePicker
              emptyLabel="Début"
              ampm={false}
              disabled={!tempTimeTable.time.amEnd}
              value={tempTimeTable.time.pmStart}
              onChange={(value) =>
                setTimeTable((prev) => ({ ...prev, time: { ...prev.time, pmStart: value } }))
              }
              className={classes.timepicker}
              invalidDateMessage="Invalide"
            />

            <KeyboardTimePicker
              emptyLabel="Fin"
              ampm={false}
              disabled={!tempTimeTable.time.pmStart}
              value={tempTimeTable.time.pmEnd}
              onChange={(value) =>
                setTimeTable((prev) => ({ ...prev, time: { ...prev.time, pmEnd: value } }))
              }
              className={classes.timepicker}
              invalidDateMessage="Invalide"
            />
          </div>
        )}
        <OpeningDay
          values={tempTimeTable.days}
          disabledDays={disabledDays}
          onChange={setTimeTable}
        />
        {invalid && <ErrorHelper message="Horaires invalides" />}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={add} disabled={noDayIsSelected || invalidTime}>
          Ajouter
        </Button>
        <Button color="default" onClick={onClose}>
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default NewOpeningDateDialog;
