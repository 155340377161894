import React from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import { useAuth } from 'contexts/authContext';

import { FormGroup, Signature, Label, RefusalMessage, DeleteButton } from 'components';

function RightSection({
  values,
  onChange,
  readOnly,
  isClientChecked,
  setIsClientChecked,
  isContactNameDeleted,
  setIsContactNameDeleted,
  setSignedWith,
  newContactName,
  setNewContactName,
  errors,
}) {
  const { user } = useAuth();
  const deleteContactName = () => {
    onChange((prev) => ({ ...prev, clientSignature: null }));
    setIsContactNameDeleted(true);
  };

  return (
    <FormGroup.Right sm={12} lg={6}>
      <Grid container spacing={2}>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Signature client</Label>
            <div>
              {values.client.contactName && isContactNameDeleted === false ? (
                <>
                  <Typography component="span"> {values.client.contactName}</Typography>
                  <DeleteButton
                    isDeleting={false}
                    iconButton
                    deleteMessage="Vous voulez vraiment supprimer ce contact ?"
                    onDelete={deleteContactName}
                    disabled={readOnly || values.isRefused}
                  />
                </>
              ) : (
                <TextField
                  value={newContactName}
                  placeholder="Contact"
                  onChange={(e) => setNewContactName(e.target.value)}
                  disabled={!isClientChecked || readOnly}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={8}>
            <Signature
              isChecked={isClientChecked}
              setIsChecked={setIsClientChecked}
              signature={values.clientSignature}
              setSignature={(value) => onChange((prev) => ({ ...prev, clientSignature: value }))}
              contactName={
                values.client.contactName && isContactNameDeleted === false
                  ? values.client.contactName
                  : newContactName
              }
              error={errors?.clientSignature}
              setSignedWith={(name) => setSignedWith(name)}
              isRefused={values.isRefused}
              readOnly={readOnly}
            />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Signature chauffeur</Label>
          </Grid>
          <Grid item xs={8}>
            <Signature
              contactName={user.email}
              signature={values.driverSignature}
              setSignature={(value) => onChange((prev) => ({ ...prev, driverSignature: value }))}
              required
              error={errors?.driverSignature}
              readOnly={readOnly}
            />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Commentaires</Label>
            <Label subLabel>Refus de collecte</Label>
          </Grid>
          <Grid item xs={8}>
            <RefusalMessage
              comments={values.comments}
              setComments={(value) => onChange((prev) => ({ ...prev, comments: value }))}
              isChecked={values.isRefused}
              setIsChecked={(value) => onChange((prev) => ({ ...prev, isRefused: value }))}
              error={errors?.comments}
              readOnly={readOnly}
            />
          </Grid>
        </Grid>
      </Grid>
    </FormGroup.Right>
  );
}

export default RightSection;
