import { useQuery } from 'react-query';

import { getClientHours } from 'services/api/terraLeoApi/clientHoursApi';

function useGetClientHours(clientId) {
  // run the query only if the id is defined
  return useQuery(['clientHours', clientId], () => getClientHours(clientId), {
    enabled: clientId,
  });
}

export { useGetClientHours };
