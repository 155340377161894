import { useQuery } from 'react-query';

import { getPlatformHours } from 'services/api/terraLeoApi/platformHoursApi';

function useGetPlatformHours(platformId) {
  // run the query if the id is defined
  return useQuery(['platformHours', platformId], () => getPlatformHours(platformId), {
    enabled: platformId,
  });
}

export { useGetPlatformHours };
