import { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { isUndefined, noop } from 'lodash';

import JWT from 'services/jwt';
import { clearTokens, getToken, saveTokens } from 'services/userService';
import useRefreshToken from './useRefreshToken';
import useAutoLogout from './useAutoLogout';
import { Loading } from 'components';
import terraLeoApiClient from 'services/api/terraLeoApi/terraLeoApiClient';

const AuthContext = createContext({
  isAuthenticated: false,
  user: null,
  login: noop,
  logout: noop,
});

const initialState = {
  user: null,
  isInitialized: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'resolveUser':
      return {
        ...state,
        user: action.payload.user,
        isInitialized: true,
      };
    case 'logout':
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
}

/**
 * Context provider for authentication.
 *
 * Provides an object with the following properties:
 * - isAuthenticated: true if the user is authenticated
 * - user: the user object extracted from the auth token
 * - login: a function that, given a token, logs in the user in the context
 * - logout: a function that logs out the user/clears the context
 */
function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  /**
   * Load user using the uath token stored in the local storage
   */
  const load = useCallback(() => {
    let user = null;
    const token = getToken();
    if (token) {
      const jwt = new JWT(token);
      user = jwt.getUser();
      terraLeoApiClient.setBearerToken(token);
    }
    dispatch({ type: 'resolveUser', payload: { user } });
  }, []);

  /**
   * Login user with a set of tokens issued by the API
   * @param {string} token The auth token
   * @param {string} refreshToken The refresh token
   */
  const login = useCallback(
    (token, refreshToken) => {
      saveTokens(token, refreshToken);
      load();
    },
    [load],
  );

  /**
   * Clear auth state
   */
  const logout = useCallback(() => {
    clearTokens();
    terraLeoApiClient.removeBearerToken();

    dispatch({ type: 'logout' });
  }, []);

  // Restore the auth state wherever the app reloads
  useEffect(() => {
    if (!state.user && !state.isInitialized) {
      load();
    }
  });

  useRefreshToken(login);
  useAutoLogout(logout);

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        isAuthenticated: state.user !== null,
        login,
        logout,
      }}
    >
      {state.isInitialized ? children : <Loading />}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const value = useContext(AuthContext);
  if (isUndefined(value)) {
    throw new Error('Cannot use hook `useAuth` outside of a `AuthProvider` component');
  }
  return value;
}

export { AuthProvider, useAuth };
