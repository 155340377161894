import terraLeoApiClient from 'services/api/terraLeoApi/terraLeoApiClient';

import { BACKEND_PLATFORM_HOURS_ENDPOINTS } from '../constants';

async function getPlatformHours(id) {
  const response = await terraLeoApiClient.getRequest(
    BACKEND_PLATFORM_HOURS_ENDPOINTS.GET_PLATFORM_HOURS(id),
  );
  return response.data;
}
// platformHours look like : { dayNumber : {am [start,end] , pm : []}}
async function addPlatformHours({ id, platformHours }) {
  const response = await terraLeoApiClient.postRequest(
    BACKEND_PLATFORM_HOURS_ENDPOINTS.ADD_PLATFORM_HOURS(id),
    platformHours,
  );
  return response.data;
}

// platformHours look like : { platformHourId : [start,end]}
async function updatePlatformHours({ id, platformHours }) {
  const response = await terraLeoApiClient.patchRequest(
    BACKEND_PLATFORM_HOURS_ENDPOINTS.EDIT_PLATFORM_HOURS(id),
    platformHours,
  );
  return response.data;
}

async function deletePlatformHours({ id, platformHoursIdArray }) {
  const response = await terraLeoApiClient.deleteRequest(
    BACKEND_PLATFORM_HOURS_ENDPOINTS.DELETE_PLATFORM_HOURS(id),
    {
      data: platformHoursIdArray,
    },
  );
  return response.data;
}

export { getPlatformHours, addPlatformHours, updatePlatformHours, deletePlatformHours };
