import React, { useMemo } from 'react';
import { useGetBillableClients } from 'hooks/queries/billableClientQueries';
import SelectAutoComplete from '../SelectAutoComplete';

// show the name of the billable client in the dropdown
function getOptionLabel(option) {
  return option ? option.name : '';
}

// Options are equal if they have the same id
function getOptionSelected(option, value) {
  return value !== null && option.id === value.id;
}

function BillableClientSelector(props) {
  const { data: billableClients, isLoading } = useGetBillableClients();

  const data = useMemo(
    () => (billableClients ? billableClients.filter((billable) => billable.isActive) : []),
    [billableClients],
  );

  return (
    <SelectAutoComplete
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      loading={isLoading}
      variables={data}
      {...props}
    />
  );
}

export default BillableClientSelector;
