import { omitBy } from 'lodash';
import {
  requiredNotEmptyString,
  validEmail,
  validPhone,
  requiredvalidZip,
  positiveAndNonNull,
  requiredValue,
} from 'services/validationUtils';

export const validateForm = (values, isBillable) => {
  const validations = {
    // email is not required, but if it's set should be validated
    email: validEmail(values.email),
    address: requiredNotEmptyString(values.address),
    zip: requiredvalidZip(values.zip),
    city: requiredNotEmptyString(values.city),
    name: requiredNotEmptyString(values.name),
    phone: validPhone(values.phone),
    platform: requiredValue(values.platform),
    zone: requiredValue(values.zone),
    dailyWasteAmount: positiveAndNonNull(values.dailyWasteAmount),
    billable: validateBillable(values.billable, isBillable),
  };
  const errors = omitBy(validations, (validation) => validation === true);

  return Object.keys(errors).length > 0 ? errors : true;
};

// billable is required if it's a collectable client,
const validateBillable = (billable, isBillable) => {
  if (!isBillable) {
    return billable ? true : 'Champ obligatoire';
  }
  return true;
};
