import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { find } from 'lodash';
import {
  Container,
  Section,
  ButtonsContainer,
  ButtonWithLoading,
  Loading,
  ErrorPage,
  CancelButton,
  SubmitAndRedirectButton,
} from 'components';
import { routes } from 'config';
import { useAddZoneMutation, useUpdateZoneMutation } from 'hooks/mutations/zoneMutations';
import {
  useAddZonePlatformTimesMutation,
  useUpdateZonePlatformTimesMutation,
  useDeleteZonePlatformTimesMutation,
} from 'hooks/mutations/zonePlatformTimesMutations';
import { useGetZone } from 'hooks/queries/zoneQueries';
import { useGetPlatformHours } from 'hooks/queries/zonePlatformTimesQueries';
import { useGetAddress } from 'hooks/queries/geoApiQueries';
import LeftSection from './components/LeftSection';
import RightSection from './components/RightSection';
import { validateForm } from './validation';

const initialValues = {
  name: '',
  comments: '',
  startAddress: '',
  startAdditionalAddress: '',
  startZip: '',
  startCity: '',
  latitude: '',
  longitude: '',
  isActive: true,
};

function ZoneForm() {
  const { id } = useParams();
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(null);
  const valuesBeforeUpdate = useRef(values);
  const { data: zone, isLoading, error } = useGetZone(id);
  const [
    addZone,
    { isLoading: isAdding, isSuccess: isAdded, data: addedZone },
  ] = useAddZoneMutation(setErrors);
  const [updateZone, { isLoading: isUpdating, isSuccess: isUpdated }] = useUpdateZoneMutation(
    setErrors,
  );
  // zone platform times
  const [tempZonePlatformTimes, setTempZonePlatformTimes] = useState([]);
  const { data: zonePlatformTimes, isLoading: isLoadingZonePlatformTimes } = useGetPlatformHours(
    id,
  );
  const [addZonePlatformTimes] = useAddZonePlatformTimesMutation();
  const [updateZonePlatformTimes] = useUpdateZonePlatformTimesMutation();
  const [deleteZonePlatformTimes] = useDeleteZonePlatformTimesMutation();
  const zonePlatformTimesBeforeUpdate = useRef(zonePlatformTimes);

  useGetAddress(values.startAddress, values.startCity, values.startZip, (lat, long) => {
    setValues((prev) => ({ ...prev, latitude: lat, longitude: long }));
  });

  useEffect(() => {
    if (id && zone && !isLoading) {
      setValues(zone);
      valuesBeforeUpdate.current = zone;
    }
  }, [id, zone, isLoading]);

  useEffect(() => {
    if (id && zonePlatformTimes && !isLoadingZonePlatformTimes) {
      setTempZonePlatformTimes(zonePlatformTimes);
      zonePlatformTimesBeforeUpdate.current = zonePlatformTimes;
    }
  }, [id, zonePlatformTimes, isLoadingZonePlatformTimes]);

  useEffect(() => {
    if (isAdded && addedZone) {
      const zonePlatformToAdd = tempZonePlatformTimes.map((item) => {
        return {
          travelTime: item.travelTime.trim() === '' ? 0 : item.travelTime,
          platform: item.platform.id,
          zone: addedZone.id,
        };
      });
      zonePlatformToAdd.forEach((item) => {
        addZonePlatformTimes(item);
      });
      setValues(initialValues);
      setTempZonePlatformTimes(
        tempZonePlatformTimes.map((item) => {
          return { ...item, travelTime: '' };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdded, addedZone]);

  const submit = () => {
    const formErrors = validateForm(values, tempZonePlatformTimes);
    if (formErrors !== true) {
      setErrors(formErrors);
    } else {
      setErrors({});
      if (id) {
        tempZonePlatformTimes.forEach((item) => {
          // check if the item exist or not
          const itemExist = find(zonePlatformTimesBeforeUpdate.current, [
            'platform.id',
            item.platform.id,
          ]);

          const travelTimeTrimed = item.travelTime.trim();
          if (itemExist && travelTimeTrimed !== '') {
            updateZonePlatformTimes({ ...item, platform: item.platform.id, zone: item.zone.id });
          } else if (itemExist && travelTimeTrimed === '') {
            deleteZonePlatformTimes({ ...item, platform: item.platform.id, zone: item.zone.id });
          } else if (travelTimeTrimed !== '') {
            addZonePlatformTimes({ ...item, platform: item.platform.id, zone: item.zone.id });
          }
        });
        updateZone(values);
      } else {
        addZone(values);
      }
    }
  };

  if (isLoading) {
    return (
      <Container fullHeight>
        <Loading />
      </Container>
    );
  } else if (error && error.response) {
    return (
      <Container fullHeight>
        <ErrorPage error={error} notFoundMessage="La zone n'existe pas" />
      </Container>
    );
  }

  return (
    <Container>
      <Section spacing={2}>
        <LeftSection values={values} onChange={setValues} errors={errors} isEditMode={id} />
        <RightSection
          values={tempZonePlatformTimes}
          zoneId={id}
          onChange={setTempZonePlatformTimes}
          errors={errors}
        />
      </Section>
      <Grid item>
        <ButtonsContainer>
          <SubmitAndRedirectButton
            onClick={submit}
            isLoading={isAdding || isUpdating}
            isSuccess={isAdded || isUpdated}
            redirectPath={routes.zone.root}
            isError={errors}
          />
          <ButtonWithLoading onClick={submit} isLoading={isAdding || isUpdating}>
            Valider
          </ButtonWithLoading>

          <CancelButton redirectPath={routes.zone.root} />
        </ButtonsContainer>
      </Grid>
    </Container>
  );
}

export default ZoneForm;
