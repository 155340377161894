import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { green } from 'theme/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    cursor: 'pointer',
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      padding: theme.spacing(2),
    },
    transition: 'all 0.4s ',
    '&:hover': {
      boxShadow: '1px 1px 15px 1px #707070',
      backgroundColor: '#F8F8F8',
    },
  },
  label: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    color: green,
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  icon: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
    fontSize: 90,
    color: green,
  },
}));

function Item({ Icon, label, SvgIcon, route }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.container} onClick={() => history.push(route)}>
      <Icon className={classes.icon} />
      <Typography component="div" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
}
export default Item;
