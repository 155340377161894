import axios from 'axios';

/**
 * Basic response error interceptor that logs the error and what caused it.
 */
function interceptResponseError(error) {
  console.error('API error for config:', error.config);
  if (error.response) {
    // API responded with an error status code (4xx or 5xx)
    console.error('API returned an error response:', error.response);
  } else if (error.request) {
    // API call failed
    console.error('API call failed with no response for request:', error.request);
  } else {
    // Unknown console
    console.error('Unknown API error', error.message);
  }

  return Promise.reject(error);
}

/**
 * Create a client for a generic HTTP API.
 * @param {Object} config Client configuration
 * @param {string} config.baseURL Base URL of the HTTP service
 */
function createApiClient({ baseURL }) {
  const axiosInstance = axios.create({ baseURL });

  /**
   * Get the API's base URL
   * @return {string}
   */
  function getBaseUrl() {
    return axiosInstance.defaults.baseURL;
  }

  /**
   * Set a bearer token that will be used in every future requests' headers.
   * @param {string} token
   */
  function setBearerToken(token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  /**
   * Get the current bearer token.
   * @return {string|null} The token or null if none is set
   */
  function getBearerToken() {
    const authHeader = axiosInstance.defaults.headers.common['Authorization'];
    if (!authHeader) {
      return null;
    }

    return authHeader.replace('Bearer ', '');
  }

  /**
   * Remove the bearer token from future requests' headers
   */
  function removeBearerToken() {
    delete axios.defaults.headers.common['Authorization'];
  }

  function getRequest(url, config) {
    return axiosInstance.get(url, config);
  }

  function deleteRequest(url, config) {
    return axiosInstance.delete(url, config);
  }

  function postRequest(url, data, config) {
    return axiosInstance.post(url, data, config);
  }

  function putRequest(url, data, config) {
    return axiosInstance.put(url, data, config);
  }

  function patchRequest(url, data, config) {
    return axiosInstance.patch(url, data, config);
  }

  function init() {
    axiosInstance.interceptors.response.use(null, interceptResponseError);
  }

  init();

  return {
    getBaseUrl,
    setBearerToken,
    getBearerToken,
    removeBearerToken,
    getRequest,
    deleteRequest,
    postRequest,
    putRequest,
    patchRequest,
  };
}

export default createApiClient;
