import { isWithinInterval } from 'date-fns';

export const numberToDay = (dayNumber) => {
  if (!dayNumber) {
    return new Error(
      'dayNumber param in numberToDay method should be a of type number or a String',
    );
  }
  switch (dayNumber.toString()) {
    case '1':
      return 'Lundi';
    case '2':
      return 'Mardi';
    case '3':
      return 'Mercredi';
    case '4':
      return 'Jeudi';
    default:
      return 'Vendredi';
  }
};

export const dayToNumber = (day) => {
  switch (day) {
    case 'monday':
      return 1;
    case 'tuesday':
      return 2;
    case 'wednesday':
      return 3;
    case 'thursday':
      return 4;
    default:
      return 5;
  }
};

/**
 * Return the closest day to today within an date interval.
 *
 * @param {string|Date|null} minDate
 * @param {string|Date|null} maxDate
 * @param {Date}
 */
export function getClosestDayInInterval(minDate, maxDate) {
  const today = new Date();
  // If one of the interval bounds is null, just return today
  if (!minDate || !maxDate) {
    return today;
  }

  const minDateClean = new Date(minDate);
  const maxDateClean = new Date(maxDate);
  if (isWithinInterval(today, { start: minDateClean, end: maxDateClean })) {
    return today;
  }

  return minDateClean;
}
