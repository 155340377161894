import { useQuery } from 'react-query';

import { getZones, getZone } from 'services/api/terraLeoApi/zoneApi';

function useGetZone(id) {
  return useQuery(['zone', id], () => getZone(id), { enabled: id });
}

function useGetZones() {
  return useQuery('zones', getZones);
}

export { useGetZone, useGetZones };
