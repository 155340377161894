import React from 'react';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
  },
}));

const DAYS = [
  { name: 'monday', number: '1', label: 'Lu' },
  { name: 'tuesday', number: '2', label: 'Ma' },
  { name: 'wednesday', number: '3', label: 'Me' },
  { name: 'thursday', number: '4', label: 'Je' },
  { name: 'friday', number: '5', label: 'Ve' },
];

function OpeningDay({ values, onChange, className, disabledDays }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, className)}>
      {DAYS.map((day) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={values[day.name]}
              onChange={(e) =>
                onChange((prev) => ({
                  ...prev,
                  days: { ...prev.days, [day.name]: e.target.checked },
                }))
              }
              disabled={disabledDays.some((dayNumber) => dayNumber === day.number)}
            />
          }
          label={day.label}
        />
      ))}
    </div>
  );
}
export default OpeningDay;
