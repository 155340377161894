import { createMuiTheme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { frFR } from '@material-ui/core/locale';

import * as colors from './colors';

const spacing = 10;

export default createMuiTheme(
  {
    overrides: {
      MuiTableCell: {
        // left and right borders
        root: {
          '&:first-child': {
            borderLeft: '1px solid black',
          },
          '&:last-child': {
            borderRight: '1px solid black',
          },
        },
        head: {
          backgroundColor: 'white',
          color: '#14B3B4',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        },
      },
      MuiTableRow: {
        root: {
          backgroundColor: 'white',
          '&:nth-of-type(even)': {
            backgroundColor: grey[100],
          },
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: colors.hoverYellow,
          },
          cursor: 'pointer',
        },
      },
      MuiTable: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: 0,
          '& .MuiTableRow-head:first-child': {
            '& .MuiTableCell-root': {
              borderTop: '1px solid black',
              borderCollapse: 'separate',
              '&:first-child': {
                borderTopLeftRadius: '4px',
              },
              '&:last-child': {
                borderTopRightRadius: '4px',
              },
            },
          },
          '& .MuiTableRow-root:not(.MuiTableRow-head):last-child': {
            '& .MuiTableCell-root': {
              borderCollapse: 'separate',
              borderBottom: '1px solid black',
              '&:first-child': {
                borderBottomLeftRadius: '4px',
              },
              '&:last-child': {
                borderBottomRightRadius: '4px',
              },
            },
          },
        },
      },
      MuiTableSortLabel: {
        root: {
          '&.MuiTableSortLabel-active': {
            color: colors.yellow,
          },
        },
      },
      MuiDrawer: {
        paper: {
          background: 'white',
          '& *': { color: colors.green },
        },
      },
      MuiButton: {
        root: {
          textTransform: 'none',
          margin: spacing,
          borderRadius: '20px',
        },
      },
      MuiSelect: {
        icon: {
          marginLeft: '20px',
        },
        select: {
          minWidth: 100,
        },
      },
      MuiDialogContent: {
        root: {
          padding: spacing * 3,
        },
      },
    },
    fonts: {
      main: "'Ubuntu', 'Helvetica', 'Arial', sans-serif",
    },
    spacing: spacing,
    palette: {
      primary: { main: colors.yellow },
      secondary: { main: colors.green },
      text: { primary: colors.text },
    },
    props: {
      MuiButton: {
        variant: 'contained',
        disableElevation: true,
      },
      MuiSwitch: {
        color: 'primary',
      },
      MuiCheckbox: {
        color: 'primary',
      },
      MuiRadio: { color: 'primary' },
    },
  },
  frFR,
);
