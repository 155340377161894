import React from 'react';
import { ListItem, ListItemIcon, ListItemText, List } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import { getRoleFromArray } from 'services/userService';
import { useAuth } from 'contexts/authContext';
import itemsList from './itemsList';
import useStyles from './styles';

export default function MainListItems() {
  const classes = useStyles();
  const { user } = useAuth();
  return (
    <List className={classes.list}>
      {itemsList(getRoleFromArray(user)).map((item) => {
        return (
          item.visible && (
            <NavLink key={item.text} to={item.to} style={{ textDecoration: 'none' }}>
              <ListItem button key={item.text}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            </NavLink>
          )
        );
      })}
    </List>
  );
}
