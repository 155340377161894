import React, { useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import SignatureCanvas from 'react-signature-canvas';
import './styles.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SignatureModal({ signature: value, name, open, onClose, onConfirm, setSignedWith }) {
  const signatureRef = useRef(null);

  const clear = () => {
    signatureRef.current.clear();
  };

  const save = () => {
    const { current } = signatureRef;
    if (current.isEmpty()) {
      onConfirm(null);
    } else {
      const ctx = current.getCanvas().getContext('2d');
      ctx.font = '40px Arial';
      ctx.fillText(name, 10, 50);
      setSignedWith && setSignedWith(name);
      onConfirm(current.toDataURL('image/png'));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="signature-dialog-title"
      aria-describedby="signature-dialog-description"
      TransitionComponent={Transition}
      fullScreen
    >
      <DialogTitle id="signature-dialog-title">Signature</DialogTitle>
      <DialogContent>
        <SignatureCanvas
          penColor="black"
          canvasProps={{
            className: 'sigCanvas',
          }}
          ref={(node) => {
            signatureRef.current = node;
            if (value && node) {
              node.fromDataURL(value);
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={clear} color="primary">
          Effacer
        </Button>
        <Button onClick={save} color="primary">
          Enregistrer
        </Button>
        <Button onClick={onClose} color="primary">
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SignatureModal;
