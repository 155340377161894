import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import useDebounce from 'hooks/useDebounce';
import { useGetAddressesList } from 'hooks/queries/geoApiQueries';

function AddressWithSuggestion({ value, onChange, onAutoComplete }) {
  const [addressResult, setAddressResult] = useState([]);
  const getAddress = useGetAddressesList(setAddressResult);
  // the goal is to only have the API call fire when user stops typing
  // to prevent hitting the API rapidly.
  const debouncedSearchTerm = useDebounce(value, 200);
  useEffect(() => {
    if (debouncedSearchTerm) {
      getAddress(debouncedSearchTerm);
    } else {
      setAddressResult([]);
    }
  }, [debouncedSearchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelect = (event, addressObject) => {
    if (addressObject) {
      var foundValue = addressResult.filter((obj) => obj.properties.label === addressObject.label);
      if (foundValue.length === 1) {
        onAutoComplete({
          zip: foundValue[0].properties.postcode,
          city: foundValue[0].properties.city,
          longitude: foundValue[0].geometry[0],
          latitude: foundValue[0].geometry[1],
        });
      }
      // fires when combobox is cleared
      // reset address inputs
    } else {
      onAutoComplete({
        zip: '',
        city: '',
        latitude: '',
        longitude: '',
      });
    }
  };

  return (
    <Autocomplete
      freeSolo
      options={addressResult.map((option) => option.properties)}
      getOptionLabel={(option) => option.name}
      renderOption={(option) => <React.Fragment>{option.label}</React.Fragment>}
      onInputChange={(e, val) => onChange(val)}
      inputValue={value}
      onChange={onSelect}
      renderInput={(params) => <TextField {...params} fullWidth placeholder="N° et rue" />}
    />
  );
}

export default AddressWithSuggestion;
