import { useQuery } from 'react-query';

import { getPlatformsTypes, getPlatform, getPlatforms } from 'services/api/terraLeoApi/platformApi';

function useGetPlatformTypes() {
  // platformTypes are considered fresh for 1h then will be fetched again
  return useQuery('platformTypes', getPlatformsTypes, { staleTime: 3600000 });
}

function useGetPlatform(id) {
  // run the query if the id is defined
  return useQuery(['platform', id], () => getPlatform(id), { enabled: id });
}

function useGetPlatforms() {
  return useQuery('platforms', getPlatforms);
}

export { useGetPlatformTypes, useGetPlatform, useGetPlatforms };
