import React from 'react';
import { useGetClientsTypes } from 'hooks/queries/clientQueries';
import SelectAutoComplete from '../SelectAutoComplete';

function getOptionLabel(option) {
  return option ? option.name : '';
}

function getOptionSelected(option, value) {
  return value !== null && option.id === value.id;
}

function ClientTypeSelector(props) {
  const { data: clientsTypes, isLoading } = useGetClientsTypes();

  return (
    <SelectAutoComplete
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      loading={isLoading}
      variables={clientsTypes || []}
      {...props}
    />
  );
}

export default ClientTypeSelector;
