import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  IconButton,
  makeStyles,
  Checkbox,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useParams } from 'react-router-dom';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Alert } from '@material-ui/lab';
import { get } from 'lodash';
import {
  Container,
  ButtonsContainer,
  ErrorHelper,
  ButtonWithLoading,
  Label,
  Loading,
  ErrorPage,
  ClientSelector,
  CancelButton,
  SubmitAndRedirectButton,
} from 'components';
import {
  useAddRecurrenceMutation,
  useUpdateRecurrenceMutation,
} from 'hooks/mutations/recurrenceMutations';
import { useGetRecurrence } from 'hooks/queries/recurrenceQueries';
import { routes } from 'config';
import { validateForm } from './validation';

const useStyles = makeStyles(() => ({
  endDateContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));
const initialValues = {
  startDate: new Date(),
  endDate: new Date(),
  client: '',
  recurrence: '1',
  preferredDays: {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
  },
};

const dayLabels = {
  0: 'lundi',
  1: 'mardi',
  2: 'mercredi',
  3: 'jeudi',
  4: 'vendredi',
};

function RecurrenceForm() {
  const classes = useStyles();
  const { id } = useParams();
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(null);
  const valuesBeforeUpdate = useRef(values);
  const { data: recurrence, isLoading, error } = useGetRecurrence(id);
  const [
    addReccurence,
    { isLoading: isAdding, isSuccess: isAdded, error: errorAdd },
  ] = useAddRecurrenceMutation();
  const [
    updateReccurence,
    { isLoading: isUpdating, error: errorUpdate, isSuccess: isUpdated },
  ] = useUpdateRecurrenceMutation();

  useEffect(() => {
    if (id && recurrence && !isLoading) {
      recurrence.recurrence = String(recurrence.recurrence);
      let preferredDays = initialValues.preferredDays;
      for (const day of recurrence.preferredDays) preferredDays[day] = true;
      setValues({
        ...recurrence,
        endDate: new Date(recurrence.endDate),
        preferredDays,
        startDate: new Date(recurrence.startDate),
      });
      valuesBeforeUpdate.current = recurrence;
    }
  }, [id, isLoading, recurrence]);

  useEffect(() => {
    isAdded && setValues(initialValues);
  }, [isAdded]);

  const updatePreferredDays = ({ target: { name, checked } }) => {
    const days = { ...values.preferredDays, [name]: checked };
    setValues((prev) => ({ ...prev, preferredDays: days }));
  };

  const submit = () => {
    const formErrors = validateForm(values);
    if (formErrors !== true) {
      setErrors(formErrors);
    } else {
      setErrors({});
      const valuesClone = { ...values };
      valuesClone.client = values.client.id;
      valuesClone.preferredDays = `[${Object.entries(values.preferredDays)
        .filter(([_, value]) => value)
        .map(([key]) => key)
        .join(',')}]`;
      id ? updateReccurence(valuesClone) : addReccurence(valuesClone);
    }
  };

  if (isLoading) {
    return (
      <Container fullHeight>
        <Loading />
      </Container>
    );
  } else if (error && error.response) {
    return (
      <Container fullHeight>
        <ErrorPage error={error} notFoundMessage="La collecte n'existe pas" />
      </Container>
    );
  }
  return (
    <Container>
      <Grid item container xs={6} spacing={2}>
        <Grid item xs={12} alignItems="center">
          {(get(errorAdd, 'response.status', '') === 400 ||
            get(errorUpdate, 'response.status', '') === 400) && (
            <Alert severity="error">
              La période saisie chevauche une période déjà existante pour ce client.
            </Alert>
          )}
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={4}>
            <Label small>Clients</Label>
          </Grid>
          <Grid item xs={8}>
            <ClientSelector
              value={values.client}
              onChange={(e, value) => setValues((prev) => ({ ...prev, client: value }))}
              style={{ flexGrow: 1 }}
              placeholder="Client"
              disabled={id ? true : false}
            />
            {errors?.client && <ErrorHelper message={errors.client} />}
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={4}>
            <Label small>Date de début</Label>
          </Grid>
          <Grid item xs={8}>
            <KeyboardDatePicker
              value={values.startDate}
              onChange={(date) => setValues((prev) => ({ ...prev, startDate: date }))}
              format="dd/MM/yyyy"
              cancelLabel="Annuler"
              invalidDateMessage=""
            />
            {errors?.startDate && <ErrorHelper message={errors.startDate} />}
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={4}>
            <Label small>Date de fin </Label>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.endDateContainer}>
              <KeyboardDatePicker
                value={values.endDate}
                onChange={(date) => setValues((prev) => ({ ...prev, endDate: date }))}
                format="dd/MM/yyyy"
                cancelLabel="Annuler"
                invalidDateMessage=""
              />
              <IconButton onClick={() => setValues((prev) => ({ ...prev, endDate: null }))}>
                <ClearIcon color="error" />
              </IconButton>
            </div>
            {errors?.endDate && <ErrorHelper message={errors.endDate} />}
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={4}>
            <Label small>Fréquence hebdomadaire</Label>
          </Grid>
          <Grid item xs={8}>
            <RadioGroup
              row
              value={values.recurrence}
              onChange={(e) => setValues((prev) => ({ ...prev, recurrence: e.target.value }))}
            >
              <FormControlLabel value="1" label="1" control={<Radio />} />
              <FormControlLabel value="2" label="2" control={<Radio />} />
              <FormControlLabel value="3" label="3" control={<Radio />} />
              <FormControlLabel value="4" label="4" control={<Radio />} />
              <FormControlLabel value="5" label="5" control={<Radio />} />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={4}>
            <Label small>Jours préférés</Label>
          </Grid>
          <Grid item xs={8}>
            {Object.entries(values.preferredDays).map(([key, value]) => (
              <FormControlLabel
                control={<Checkbox checked={value} onChange={updatePreferredDays} />}
                label={dayLabels[key]}
                name={key}
              />
            ))}
            {errors?.preferredDays && <ErrorHelper message={errors.preferredDays} />}
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <ButtonsContainer>
          <SubmitAndRedirectButton
            onClick={submit}
            isLoading={isAdding || isUpdating}
            isSuccess={isAdded || isUpdated}
            redirectPath={routes.recurrence.root}
            isError={errors}
          />
          <ButtonWithLoading onClick={submit} isLoading={isAdding || isUpdating}>
            Valider
          </ButtonWithLoading>

          <CancelButton redirectPath={routes.recurrence.root} />
        </ButtonsContainer>
      </Grid>
    </Container>
  );
}

export default RecurrenceForm;
