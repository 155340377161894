import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
  section: {
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
  },
  subSection: {
    alignItems: 'flex-start',
    marginBottom: 0,
    marginRight: 0,
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
}));
