import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { format } from 'date-fns';
import { find, get } from 'lodash';
import { TimePicker } from '@material-ui/pickers';
import WarningIcon from '@material-ui/icons/Warning';

import {
  Container,
  ButtonsContainer,
  ButtonWithLoading,
  Label,
  Loading,
  ErrorHelper,
  CancelButton,
} from 'components';
import { useGetParameters } from 'hooks/queries/parametersQueries';
import { useUpdateParameterMutation } from 'hooks/mutations/paramtersMutation';
import { validateForm } from './validation';
import { routes } from 'config';

const initialValues = {
  dacHours: null,
  planningDay: '',
  amMaxClientNumber: '',
  pmMaxClientNumber: '',
};

function Parameter() {
  const [values, setValues] = useState(initialValues);

  const { data: parameters, isLoading } = useGetParameters();
  const valuesBeforeUpdate = useRef(values);
  const [errors, setErrors] = useState(null);
  const [updateParameters, { isLoading: isUpdating }] = useUpdateParameterMutation();

  useEffect(() => {
    if (parameters && !isLoading) {
      const tempParam = { ...values };
      const tempDacHours = get(find(parameters, ['name', 'dacHours']), 'value', null);
      if (tempDacHours) {
        tempParam.dacHours = new Date('2020/12/23 ' + tempDacHours + ':00');
      }
      tempParam.planningDay = get(find(parameters, ['name', 'planningDay']), 'value', '');
      tempParam.amMaxClientNumber = get(
        find(parameters, ['name', 'amMaxClientNumber']),
        'value',
        '',
      );
      tempParam.pmMaxClientNumber = get(
        find(parameters, ['name', 'pmMaxClientNumber']),
        'value',
        '',
      );
      setValues(tempParam);
      valuesBeforeUpdate.current = tempParam;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters, isLoading]);

  // check if each row exist in database, otherwise show warning icon to identifiy the missing parameter
  const isPlanningDayDisabled = useMemo(
    () => !parameters || find(parameters, ['name', 'planningDay']) === undefined,
    [parameters],
  );

  const isDacHoursDisabled = useMemo(
    () => !parameters || find(parameters, ['name', 'dacHours']) === undefined,
    [parameters],
  );

  const isAmMaxClientNumberDisabled = useMemo(
    () => !parameters || find(parameters, ['name', 'amMaxClientNumber']) === undefined,
    [parameters],
  );

  const isPmMaxClientNumberDisabled = useMemo(
    () => !parameters || find(parameters, ['name', 'pmMaxClientNumber']) === undefined,
    [parameters],
  );

  const submit = () => {
    const formErrors = validateForm(
      values,
      isPlanningDayDisabled,
      isAmMaxClientNumberDisabled,
      isPmMaxClientNumberDisabled,
    );
    if (formErrors !== true) {
      setErrors(formErrors);
    } else {
      setErrors({});
      parameters.forEach((oldParam) => {
        // backend store dacHours as HH
        if (oldParam.name === 'dacHours') {
          if (
            format(new Date('2020/12/23 ' + oldParam.value + ':00'), 'HH') !==
            format(values[oldParam.name], 'HH')
          )
            updateParameters({ ...oldParam, value: format(values[oldParam.name], 'HH') });
        } else if (oldParam.value !== values[oldParam.name]) {
          updateParameters({ ...oldParam, value: values[oldParam.name] });
        }
      });
    }
  };

  if (isLoading) {
    return (
      <Container fullHeight>
        <Loading />
      </Container>
    );
  }

  return (
    <Container>
      <Grid container spacing={2} item sm={12} lg={6}>
        <Grid item container alignItems="center">
          <Grid item xs={6}>
            <Label small>Heure DAC</Label>
          </Grid>
          <Grid item xs={6}>
            <TimePicker
              value={values.dacHours}
              format="HH"
              onChange={(v) => setValues((prev) => ({ ...prev, dacHours: v }))}
              fullWidth
              ampm={false}
              disableToolbar
              views={['hours']}
              disabled={isDacHoursDisabled}
              InputProps={{
                endAdornment: isDacHoursDisabled ? <WarningIcon /> : null,
              }}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={6}>
            <Label small> Jour de planification</Label>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={values.planningDay}
              onChange={(e) => setValues((prev) => ({ ...prev, planningDay: e.target.value }))}
              type="number"
              fullWidth
              disabled={isPlanningDayDisabled}
              InputProps={{
                endAdornment: isPlanningDayDisabled ? <WarningIcon /> : null,
              }}
            />
          </Grid>
          {errors?.planningDay && <ErrorHelper message={errors.planningDay} />}
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={6}>
            <Label small>Nombre maximum de clients le matin</Label>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={values.amMaxClientNumber}
              onChange={(e) =>
                setValues((prev) => ({ ...prev, amMaxClientNumber: e.target.value }))
              }
              fullWidth
              type="number"
              disabled={isAmMaxClientNumberDisabled}
              InputProps={{
                endAdornment: isAmMaxClientNumberDisabled ? <WarningIcon /> : null,
              }}
            />
            {errors?.amMaxClientNumber && <ErrorHelper message={errors.amMaxClientNumber} />}
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={6}>
            <Label small>Nombre maximum de clients l'après midi</Label>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={values.pmMaxClientNumber}
              onChange={(e) =>
                setValues((prev) => ({ ...prev, pmMaxClientNumber: e.target.value }))
              }
              fullWidth
              type="number"
              disabled={isPmMaxClientNumberDisabled}
              InputProps={{
                endAdornment: isPmMaxClientNumberDisabled ? <WarningIcon /> : null,
              }}
            />
            {errors?.pmMaxClientNumber && <ErrorHelper message={errors.pmMaxClientNumber} />}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <ButtonsContainer>
          <ButtonWithLoading onClick={submit} isLoading={isUpdating} disabled={isUpdating}>
            Modifier
          </ButtonWithLoading>
          <CancelButton redirectPath={routes.home.root} />
        </ButtonsContainer>
      </Grid>
    </Container>
  );
}

export default Parameter;
