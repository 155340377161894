import { useQuery } from 'react-query';

import { getClientsTypes, getClient, getClients } from 'services/api/terraLeoApi/clientApi';

function useGetClientsTypes() {
  return useQuery('clientsTypes', getClientsTypes);
}

function useGetClient(id) {
  return useQuery(['client', id], () => getClient(id), { enabled: id });
}

function useGetClients() {
  return useQuery('clients', getClients);
}

export { useGetClientsTypes, useGetClient, useGetClients };
