import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import uuidv4 from 'uuid/v4';
import { DeleteButton } from 'components';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
}));

function TableButtons({
  onDelete,
  selectedItem,
  deleteMessage,
  informationMessage,
  isDeleting,
  rightButtons,
  editRoute,
  addRoute,
}) {
  const classes = useStyles();

  const mapButtons = (buttons) =>
    buttons?.map((button) => <React.Fragment key={`button_${uuidv4()}`}>{button}</React.Fragment>);

  return (
    <div className={classes.root}>
      {addRoute && (
        <Button color="primary" href={addRoute} disabled={isDeleting}>
          Ajouter
        </Button>
      )}
      {editRoute && (
        <Button
          color="primary"
          disabled={!Boolean(selectedItem) || isDeleting}
          href={selectedItem ? editRoute(selectedItem) : ''}
        >
          Modifier
        </Button>
      )}
      {onDelete && (
        <DeleteButton
          isDeleting={isDeleting}
          disabled={!Boolean(selectedItem)}
          onDelete={onDelete}
          deleteMessage={deleteMessage}
          informationMessage={informationMessage}
          showInfoDialogCondition={selectedItem && !selectedItem.isActive}
        />
      )}
      {mapButtons(rightButtons)}
    </div>
  );
}

export default TableButtons;
