import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  Menu,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import clsx from 'clsx';
import GetAppIcon from '@material-ui/icons/GetApp';

import { useGetZip } from 'hooks/queries/dacQueries';
import { Label } from 'components';
import { green } from 'theme/colors';
import useStyles from './styles';
import { Alert } from '@material-ui/lab';

function MonthlyFollowUpTable({ values, selectedMonthLabel, month, year }) {
  const classes = useStyles();
  const [pickupAnchorEls, setPickupAnchorEls] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 20,
  });
  const getZip = useGetZip();

  const onCellClick = (e, clientId) => {
    setSelectedItem(clientId);
    setPickupAnchorEls(e.currentTarget);
  };

  const closeMenu = () => {
    setPickupAnchorEls(null);
  };

  const displayedRows = values.slice(
    pagination.page * pagination.rowsPerPage,
    pagination.page * pagination.rowsPerPage + pagination.rowsPerPage,
  );

  return (
    <>
      {displayedRows.length === 0 && (
        <Alert severity="warning" className={classes.alert}>
          Aucune collecte n'a eu lieu sur cette période.
        </Alert>
      )}
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.title}>{selectedMonthLabel}</TableCell>
              <TableCell className={classes.title} align="center">
                POIDS (en kg)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedRows.length > 0 ? (
              <>
                {displayedRows.map((row) => (
                  <TableRow key={row.client.id}>
                    <TableCell
                      className={clsx({
                        [classes.selected]:
                          selectedItem &&
                          selectedItem.client.id === row.client.id &&
                          pickupAnchorEls,
                      })}
                      onClick={(e) => onCellClick(e, row)}
                    >
                      {row.client.name}
                    </TableCell>
                    <TableCell align="center" className={classes.totalRow}>
                      {row.weight}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell className={classes.totalRow}>TOTAL</TableCell>
                  <TableCell align="center" className={classes.totalRow}>
                    {displayedRows.reduce((a, b) => a + b.weight, 0)}
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  <Label subLabel>Aucun élément </Label>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[20, 25, 50]}
          component="div"
          count={values.length}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onChangePage={(event, newPage) => setPagination((prev) => ({ ...prev, page: newPage }))}
          onChangeRowsPerPage={(event) =>
            setPagination((prev) => ({
              rowsPerPage: parseInt(event.target.value, 10),
              page: 0,
            }))
          }
        />
      </TableContainer>
      <Menu
        open={Boolean(pickupAnchorEls)}
        anchorEl={pickupAnchorEls}
        keepMounted
        onClose={closeMenu}
        autoFocus={false}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuList>
          {selectedItem && (
            <MenuItem
              onClick={() => getZip(month, year, selectedItem.client.id, selectedItem.client.name)}
            >
              Télécharger
              <GetAppIcon style={{ color: green }} />
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </>
  );
}

export default MonthlyFollowUpTable;
