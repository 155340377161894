import { omitBy } from 'lodash';
import { positiveAndNonNull } from 'services/validationUtils';

export const validateForm = (
  values,
  isPlanningDayDisabled,
  isAmMaxClientNumberDisabled,
  isPmMaxClientNumberDisabled,
) => {
  const validations = {
    planningDay: isPlanningDayDisabled ? true : positiveAndNonNull(values.planningDay),
    amMaxClientNumber: isAmMaxClientNumberDisabled
      ? true
      : positiveAndNonNull(values.amMaxClientNumber),
    pmMaxClientNumber: isPmMaxClientNumberDisabled
      ? true
      : positiveAndNonNull(values.pmMaxClientNumber),
  };
  const errors = omitBy(validations, (validation) => validation === true);

  return Object.keys(errors).length > 0 ? errors : true;
};
