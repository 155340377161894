import { Redirect, Route } from 'react-router-dom';

import routes from 'config/routes';
import { useAuth } from 'contexts/authContext';
/**
 * A route component that can only be accessed if the user IS authenticated.
 *
 * If they are authenticated, redirects to the login page.
 */
function OnlyPrivateRoute({ children, ...rest }) {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      {...rest}
      render={() => (isAuthenticated ? children : <Redirect to={routes.login.root} />)}
    />
  );
}

export default OnlyPrivateRoute;
