import React, { useMemo } from 'react';
import { useGetClients } from 'hooks/queries/clientQueries';
import SelectAutoComplete from '../SelectAutoComplete';

function getOptionLabel(option) {
  return option.name;
}

function getOptionSelected(option, value) {
  return value !== null && option.id === value.id;
}

function ClientSelector(props) {
  const { data: clients, isLoading } = useGetClients();

  const data = useMemo(
    () =>
      clients
        ? clients
            .filter((client) => client.isActive)
            .map((client) => {
              return { id: client.id, name: client.name };
            })
        : [],
    [clients],
  );

  return (
    <SelectAutoComplete
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      loading={isLoading}
      variables={data}
      {...props}
    />
  );
}

export default ClientSelector;
