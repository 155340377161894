import React, { useState } from 'react';
import { TextField, Typography, Grid } from '@material-ui/core';
import TerraLeoLogo from 'assets/aisiBlueLogo.png';
import { ButtonWithLoading } from 'components';
import useLoginMutation from 'hooks/mutations/useLoginMutation';
import useStyles from './styles';

function Login() {
  const [login, { status }] = useLoginMutation();
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const submit = () => {
    login({ email, password });
  };

  const isLoading = status === 'loading';

  // check if email or password are empty
  const isEmpty = () => email === '' || password === '';

  return (
    <div className={classes.container}>
      <div>
        <Grid container className={classes.formContainer}>
          <Grid item sm={12} md={4} lg={5} className={classes.logoGridItem}>
            <div className={classes.logoContainer}>
              <img src={TerraLeoLogo} alt="logo" className={classes.logo} />
            </div>
          </Grid>
          <Grid item sm={12} md={8} lg={7} className={classes.formGridItem}>
            <div className={classes.form}>
              <Typography variant="h2">Connexion</Typography>
              <TextField
                label="Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                disabled={isLoading}
              />
              <TextField
                label="Mot de passe"
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onKeyDown={(event) => (event.key === 'Enter' ? submit() : null)}
                disabled={isLoading}
              />
              <ButtonWithLoading
                isLoading={isLoading}
                disabled={isLoading || isEmpty()}
                className={classes.loginButton}
                onClick={submit}
              >
                Connexion
              </ButtonWithLoading>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Login;
