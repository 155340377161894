export const API_GEO_ENDPOINTS = {
  SEARCH_ADDRESS: (address) => `/search/?q=${address}`,
};

export const BACKEND_USERS_ENDPOINTS = {
  LIST_USERS: '/users',
  GET_USER: (id) => `/users/${id}`,
  GET_USER_WITH_UUID: (uuid) => `/users/reset-password/${uuid}`,
  ADD_USER: '/users',
  EDIT_USER: (id) => `/users/${id}`,
  DELETE_USER: (id) => `/users/${id}`,
  FORGOT_PASSWORD: (id) => `/users/forgot-password/${id}`,
};

export const BACKEND_PLATFORMS_ENDPOINTS = {
  LIST_PLATEFORMS: '/platforms',
  GET_PLATFORM: (id) => `/platforms/${id}`,
  ADD_PLATFORM: '/platforms',
  EDIT_PLATFORM: (id) => `/platforms/${id}`,
  DELETE_PLATFORM: (id) => `/platforms/${id}`,
  LIST_PLATFORMS_TYPES: '/platforms-types',
};

export const BACKEND_DEPOSIT_ENDPOINTS = {
  GET_DEPOSIT: (id) => `/deposits/${id}`,
  EDIT_DEPOSIT: (id) => `/deposits/${id}`,
  GET_DEPOSIT_PICKUPS: (id) => `/deposits/${id}/pickups`,
};

export const BACKEND_PLATFORM_HOURS_ENDPOINTS = {
  GET_PLATFORM_HOURS: (id) => `/platforms/${id}/hours`,
  ADD_PLATFORM_HOURS: (id) => `/platforms/${id}/hours`,
  EDIT_PLATFORM_HOURS: (id) => `/platforms/${id}/hours`,
  DELETE_PLATFORM_HOURS: (id) => `/platforms/${id}/hours`,
};

export const BACKEND_CLIENTS_ENDPOINTS = {
  LIST_CLIENTS: '/clients',
  GET_CLIENT: (id) => `/clients/${id}`,
  ADD_CLIENT: '/clients',
  EDIT_CLIENT: (id) => `/clients/${id}`,
  DELETE_CLIENT: (id) => `/clients/${id}`,
  LIST_CLIENTS_TYPES: '/clients-types',
};

export const BACKEND_CLIENT_HOURS_ENDPOINTS = {
  GET_CLIENT_HOURS: (id) => `/clients/${id}/hours`,
  ADD_CLIENT_HOURS: (id) => `/clients/${id}/hours`,
  EDIT_CLIENT_HOURS: (id) => `/clients/${id}/hours`,
  DELETE_CLIENT_HOURS: (id) => `/clients/${id}/hours`,
};

export const BACKEND_ZONES_ENDPOINTS = {
  LIST_ZONES: '/zones',
  GET_ZONE: (id) => `/zones/${id}`,
  ADD_ZONE: '/zones',
  EDIT_ZONE: (id) => `/zones/${id}`,
  DELETE_ZONE: (id) => `/zones/${id}`,
};
export const BACKEND_ZONE_PLATFORM_TIMES_ENDPOINTS = {
  LIST_ZONE_PLATFORM: (id) => `/zones/${id}/zone-platform-times`,
  ADD_ZONE_PLATFORM: (id) => `/zones/${id}/zone-platform-times`,
  EDIT_ZONE_PLATFORM: (id, platformId) => `/zones/${id}/zone-platform-times/${platformId}`,
  DELETE_ZONE_PLATFORM: (id, platformId) => `/zones/${id}/zone-platform-times/${platformId}`,
};

export const BACKEND_BILLABLE_CLIENTS_ENDPOINTS = {
  LIST_BILLABLE_CLIENTS: '/billables',
  GET_BILLABLE_CLIENT: (id) => `/billables/${id}`,
  ADD_BILLABLE_CLIENT: '/billables',
  EDIT_BILLABLE_CLIENT: (id) => `/billables/${id}`,
  DELETE_BILLABLE_CLIENTS: (id) => `/billables/${id}`,
};

export const BACKEND_VEHICLES_ENDPOINTS = {
  LIST_VEHICLES: '/vehicles',
  LIST_VEHICLES_TYPES: '/vehicles-types',
  GET_VEHICLE: (id) => `/vehicles/${id}`,
  ADD_VEHICLE: '/vehicles',
  EDIT_VEHICLE: (id) => `/vehicles/${id}`,
  DELETE_VEHICLE: (id) => `/vehicles/${id}`,
};

export const BACKEND_PICKUPS_ENDPOINTS = {
  // pickups for a week, it take weeknumber, year and client as params
  // pickups by half day, it take date and isAm as params
  LIST_PICKUPS: '/pickups',
  // get the min and max date of pickups in the database
  GET_PICKUPS_DATE_RANGE: '/pickups/date-range',
  GET_PICKUPS: (id) => `/pickups/${id}`,
  GET_PRODUCT_TYPES: `/product-types`,
  ADD_PICKUPS: '/pickups',
  EDIT_PICKUPS: (id) => `/pickups/${id}`,
  DELETE_PICKUPS: (id) => `/pickups/${id}`,
  CHANGE_PICKUPS_DEPOSIT: '/pickups/change-deposit',
};

export const BACKEND_RECURRENCES_ENDPOINTS = {
  LIST_RECURRENCES: '/recurrences',
  LIST_RECURRENCES_PICKUP: '/recurrences/pickups',
  GET_RECURRENCE: (id) => `/recurrences/${id}`,
  ADD_RECURRENCE: '/recurrences',
  EDIT_RECURRENCE: (id) => `/recurrences/${id}`,
  DELETE_RECURRENCE: (id) => `/recurrences/${id}`,
};

export const BACKEND_FOLLOW_UP_ENDPOINTS = {
  WEEKLY: '/weekly-follow-up',
  MONTHLY: '/monthly-follow-up',
};

export const BACKEND_PARAMETERS_ENDPOINTS = {
  GET_PARAMS: '/parameters',
  PUT_PARAMS: (id) => `/parameters/${id}`,
};

export const BACKEND_PLANNING_ENDPOINTS = {
  DUPLICATE: '/planning/duplicate-week',
  GENERATE: '/planning/generate',
};

export const BACKEND_DAC_ENDPOINT = {
  GET_DAC: (dacNumber) => `/dacs/${dacNumber}`,
  GET_ZIP: (month, year, clientId) => `/get-zip?month=${month}&year=${year}&clientId=${clientId}`,
};
