import { useMutation, useQueryCache } from 'react-query';
import { useSnackbar } from 'notistack';

import {
  addUser as addRequest,
  updateUser as updateRequest,
  deleteUser as deleteRequest,
  updatePassword,
} from 'services/api/terraLeoApi/userApi';

function useAddUserMutation(setErrors) {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(addRequest, {
    onError: (error) => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
      if (error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      }
    },
    onSuccess: () => {
      // update the list of cached users
      queryCache.refetchQueries('users');
      enqueueSnackbar("L'utilisateur a bien été ajouté.", {
        variant: 'success',
      });
    },
  });
}

function useUpdateUserMutation(setErrors) {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(updateRequest, {
    onError: (error) => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
      if (error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      }
    },
    onSuccess: () => {
      queryCache.refetchQueries('users');
      enqueueSnackbar("L'utilisateur a bien été modifié.", {
        variant: 'success',
      });
    },
  });
}

function useDeleteUserMutation() {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(deleteRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryCache.refetchQueries('users');
      enqueueSnackbar("L'utilisateur a bien été supprimé.", {
        variant: 'success',
      });
    },
  });
}

function useUpdatePassword() {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(updatePassword, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryCache.refetchQueries('users');
      enqueueSnackbar('Le mot de passe a bien été modifié.', {
        variant: 'success',
      });
    },
  });
}

export { useAddUserMutation, useUpdateUserMutation, useDeleteUserMutation, useUpdatePassword };
