import { useMutation, useQueryCache } from 'react-query';
import { useSnackbar } from 'notistack';

import {
  addZone as addRequest,
  updateZone as updateRequest,
  deleteZone as deleteRequest,
} from 'services/api/terraLeoApi/zoneApi';

function useAddZoneMutation(setErrors) {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(addRequest, {
    onError: (error) => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
      if (error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      }
    },
    onSuccess: () => {
      // update the list of cached zones
      queryCache.refetchQueries('zones');
      enqueueSnackbar('La zone a bien été ajoutée.', {
        variant: 'success',
      });
    },
  });
}

function useUpdateZoneMutation(setErrors) {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(updateRequest, {
    onError: (error) => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
      if (error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      }
    },
    onSuccess: () => {
      queryCache.refetchQueries('zones');
      enqueueSnackbar('Le zone a bien été modifiée.', {
        variant: 'success',
      });
    },
  });
}

function useDeleteZoneMutation() {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(deleteRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryCache.refetchQueries('zones');
      enqueueSnackbar('La zone a bien été supprimée.', {
        variant: 'success',
      });
    },
  });
}

export { useAddZoneMutation, useUpdateZoneMutation, useDeleteZoneMutation };
