import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { format, isValid, getISODay } from 'date-fns';
import Slide from '@material-ui/core/Slide';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ClientSelector, Label, VehicleSelector, ErrorHelper, ButtonWithLoading } from 'components';
import { useAddPickupmMutation } from 'hooks/mutations/pickupMutation';
import ClientWeekPickupsTable from '../../TableComponents/ClientWeekPickupsTable';
import DayPeriodInput from '../../DayPeriodInput';
import { validateForm } from './validation';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// disable saturdays and sundays
function disableWeekends(date) {
  return getISODay(date) === 6 || getISODay(date) === 7;
}

const INITIAL_STATE = {
  date: null,
  vehicle: { id: '', name: '' },
  client: { id: '', name: '' },
  isAm: true,
  weight: null,
};

/**
 * This component is a modal and is used to add a pickup from the circuit table.
 */
function AddPickupDialog({ open, onClose: closeModal, selectedDate, isAm }) {
  const [values, setValues] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState({});
  const [addPickup, { isSuccess: isAdded, isLoading: isAdding }] = useAddPickupmMutation();

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      isAm: isAm,
      date: selectedDate,
    }));
  }, [selectedDate, isAm]);

  const resetForm = () => {
    setValues(INITIAL_STATE);
    setErrors({});
  };

  const submit = () => {
    const formErrors = validateForm(values);
    if (formErrors !== true) {
      setErrors(formErrors);
    } else {
      setErrors({});
      addPickup({
        ...values,
        date: format(values.date, 'yyyy-MM-dd 00:00:00'),
        client: values.client.id,
        vehicle: values.vehicle.id,
      });
    }
  };

  useEffect(() => {
    if (isAdded && !isAdding) {
      // reset form otherwise when we click again to add new pickup, the previous values will appear
      resetForm();
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdded]);

  const onClose = () => {
    resetForm();
    closeModal();
  };

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition} fullWidth>
      <DialogTitle>Ajout collecte</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item container direction="row" xs={12}>
            <Grid item xs={4}>
              <Label small>Véhicule</Label>
            </Grid>
            <Grid item xs={8}>
              <VehicleSelector
                value={values.vehicle}
                onChange={(e, value) => setValues((prev) => ({ ...prev, vehicle: value }))}
                disableClearable
              />
              {errors?.vehicle && <ErrorHelper message={errors.vehicle} />}
            </Grid>
          </Grid>
          <Grid item container direction="row" xs={12}>
            <Grid item xs={4}>
              <Label small> Client</Label>
            </Grid>
            <Grid item xs={8}>
              <ClientSelector
                value={values.client}
                onChange={(e, value) => setValues((prev) => ({ ...prev, client: value }))}
                disableClearable
                fullWidth
              />
              {errors?.client && <ErrorHelper message={errors.client} />}
            </Grid>
          </Grid>
          <Grid item container direction="row" xs={12} alignItems="center">
            <Grid item xs={4}>
              <Label small>Date </Label>
            </Grid>
            <Grid item xs={8}>
              <KeyboardDatePicker
                value={values.date}
                format="dd/MM/yyyy"
                onChange={(value) => setValues((prev) => ({ ...prev, date: value }))}
                fullWidth
                cancelLabel="Annuler"
                invalidDateMessage=""
                // is very important to not allow the user to add a pickup on weekends
                shouldDisableDate={disableWeekends}
              />
              {errors?.date && <ErrorHelper message={errors.date} />}
            </Grid>
          </Grid>
          <Grid item container direction="row" xs={12} alignItems="center">
            <Grid item xs={4}>
              <Label small>Poids total</Label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                value={values.weight}
                onChange={(e) => setValues((prev) => ({ ...prev, weight: e.target.value }))}
                type="number"
                placeholder="Poids total"
                InputProps={{
                  endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                  inputProps: { min: 0, max: 5000 },
                }}
              />
              {errors?.weight && <ErrorHelper message={errors.weight} />}
            </Grid>
          </Grid>
          <Grid item container direction="row" xs={12} alignItems="center">
            <Grid item xs={4}>
              <Label small>Période </Label>
            </Grid>
            <Grid item xs={8}>
              <DayPeriodInput
                value={values.isAm}
                onChange={(value) => setValues((prev) => ({ ...prev, isAm: value }))}
              />
            </Grid>
          </Grid>
          {values.date && isValid(values.date) && values.client.id && (
            <Grid item container direction="row" xs={12}>
              <Grid item xs={12}>
                <Label small>
                  Planification pour cette semaine pour le client : {values.client.name}
                </Label>
              </Grid>
              <Grid item xs={12}>
                <ClientWeekPickupsTable date={values.date} clientId={values.client.id} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonWithLoading color="primary" onClick={submit} isLoading={isAdding}>
          Valider
        </ButtonWithLoading>
        <Button onClick={onClose}>Annuler</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddPickupDialog;
