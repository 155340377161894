import React, { useState, useEffect, useRef } from 'react';
import { Grid, TextField, Checkbox, FormControlLabel, MenuItem, Select } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { USER_ROLES_LABELS, USER_ROLES } from 'config/constants';
import { getRoleFromArray } from 'services/userService';
import {
  Container,
  ButtonsContainer,
  ErrorHelper,
  ButtonWithLoading,
  Label,
  Loading,
  ErrorPage,
  CancelButton,
  SubmitAndRedirectButton,
} from 'components';
import { routes } from 'config';
import { validateForm } from './validation';
import { useGetUser } from 'hooks/queries/userQueries';
import { useAddUserMutation, useUpdateUserMutation } from 'hooks/mutations/userMutations';
import ResetPasswordButton from './ResetPasswordButton';

const initialValues = {
  nickname: '',
  email: '',
  isActive: true,
  roles: [],
};

function UserForm() {
  const { id } = useParams();
  const [values, setValues] = useState(initialValues);
  const [role, setRole] = useState(USER_ROLES.DRIVER);
  const [errors, setErrors] = useState(null);
  const valuesBeforeUpdate = useRef(values);
  const [addUser, { isLoading: isAdding, isSuccess: isAdded }] = useAddUserMutation(setErrors);
  const [updateUser, { isLoading: isUpdating, isSuccess: isUpdated }] = useUpdateUserMutation(
    setErrors,
  );
  const { data: user, isLoading, error } = useGetUser(id);

  useEffect(() => {
    if (id && user && !isLoading) {
      setValues(user);
      valuesBeforeUpdate.current = user;
      const roleFromArray = getRoleFromArray(user);
      setRole(roleFromArray);
      valuesBeforeUpdate.current.roles = roleFromArray;
    }
  }, [id, user, isLoading]);

  useEffect(() => {
    // reset form when is successfully added
    isAdded && setValues(initialValues);
  }, [isAdded]);

  const submit = () => {
    const valuesClone = { ...values };
    valuesClone.roles = [role];
    const formErrors = validateForm(valuesClone);
    if (formErrors !== true) {
      setErrors(formErrors);
    } else {
      setErrors({});
      if (id) {
        updateUser(valuesClone);
      } else {
        addUser(valuesClone);
      }
    }
  };

  if (isLoading) {
    return (
      <Container fullHeight>
        <Loading />
      </Container>
    );
  } else if (error && error.response) {
    return (
      <Container fullHeight>
        <ErrorPage error={error} notFoundMessage="L'utilisateur n'existe pas" />
      </Container>
    );
  }

  return (
    <Container>
      <Grid container spacing={2} sm={12} lg={6}>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={4}>
            <Label small>Nom d'affichage</Label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              value={values.nickname}
              onChange={(e) => setValues((prev) => ({ ...prev, nickname: e.target.value }))}
              fullWidth
              placeholder="Nom d'affichage"
            />
            {errors?.nickname && <ErrorHelper message={errors.nickname} />}
          </Grid>
          {id && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isActive}
                    onChange={(e) => setValues((prev) => ({ ...prev, isActive: e.target.checked }))}
                    name="Actif"
                  />
                }
                label="Actif"
              />
            </Grid>
          )}
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={4}>
            <Label small>Rôle</Label>
          </Grid>
          <Grid item xs={8}>
            <Select value={role} onChange={(e) => setRole(e.target.value)} fullWidth>
              <MenuItem value={USER_ROLES.ADMIN}>{USER_ROLES_LABELS[USER_ROLES.ADMIN]}</MenuItem>
              <MenuItem value={USER_ROLES.DRIVER}>{USER_ROLES_LABELS[USER_ROLES.DRIVER]}</MenuItem>
              <MenuItem value={USER_ROLES.PLANNER}>
                {USER_ROLES_LABELS[USER_ROLES.PLANNER]}
              </MenuItem>
            </Select>
            {errors?.role && <ErrorHelper message={errors.role} />}
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={4}>
            <Label small>Email</Label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              value={values.email}
              onChange={(e) => setValues((prev) => ({ ...prev, email: e.target.value }))}
              fullWidth
              placeholder="email"
            />
            {errors?.email && <ErrorHelper message={errors.email} />}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <ButtonsContainer>
          {id && <ResetPasswordButton userId={id} />}
          <SubmitAndRedirectButton
            onClick={submit}
            isLoading={isAdding || isUpdating}
            isSuccess={isAdded || isUpdated}
            redirectPath={routes.users.root}
            isError={errors}
          />

          <ButtonWithLoading onClick={submit} isLoading={isAdding || isUpdating}>
            Valider
          </ButtonWithLoading>

          <CancelButton redirectPath={routes.users.root} />
        </ButtonsContainer>
      </Grid>
    </Container>
  );
}

export default UserForm;
