import React, { useMemo } from 'react';
import { useGetPlatforms } from 'hooks/queries/platformQueries';
import SelectAutoComplete from '../SelectAutoComplete';

function getOptionLabel(option) {
  return option ? option.name : '';
}

function getOptionSelected(option, value) {
  return value !== null && option.id === value.id;
}

function PlatformSelector(props) {
  const { data: platforms, isLoading } = useGetPlatforms();

  const data = useMemo(
    () =>
      platforms
        ? platforms
            .filter((platform) => platform.isActive)
            .map((platform) => {
              return { id: platform.id, name: platform.name };
            })
        : [],
    [platforms],
  );

  return (
    <SelectAutoComplete
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      loading={isLoading}
      variables={data}
      {...props}
    />
  );
}

export default PlatformSelector;
