import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';

import {
  addZonePlatformTimes as addRequest,
  updateZonePlatformTimes as updateRequest,
  deleteZonePlatformTimes as deleteRequest,
} from 'services/api/terraLeoApi/zonePlatformTimes';

function useAddZonePlatformTimesMutation() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(addRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
  });
}

function useUpdateZonePlatformTimesMutation() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(updateRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
  });
}

function useDeleteZonePlatformTimesMutation() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(deleteRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
  });
}

export {
  useAddZonePlatformTimesMutation,
  useUpdateZonePlatformTimesMutation,
  useDeleteZonePlatformTimesMutation,
};
