import React, { useState, useMemo, useEffect } from 'react';
import {
  TableRow,
  TableCell,
  TextField,
  FormControlLabel,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import get from 'lodash/get';

import { TableButtons, Container, Table, Loading } from 'components';
import { routes } from 'config';
import { USER_ROLES_LABELS } from 'config/constants';

import { useDeleteUserMutation } from 'hooks/mutations/userMutations';
import { useGetUsers } from 'hooks/queries/userQueries';
import { getRoleFromArray } from 'services/userService';

const headCells = [
  { id: 'nickname', label: "Nom d'affichage" },
  { id: 'roles', label: 'Rôle' },
  { id: 'isActive', label: 'Statut' },
];

function filterUsers(users, search, activeOnly) {
  if (search === '') {
    return activeOnly ? users.filter((user) => user.isActive) : users;
  }

  const searchRegexp = new RegExp(search, 'gi');
  const searchedFields = ['nickname'];
  return activeOnly
    ? users
        .filter((user) => user.isActive)
        .filter((u) => {
          return searchedFields.some((field) => get(u, field, '').match(searchRegexp));
        })
    : users.filter((u) => {
        return searchedFields.some((field) => get(u, field, '').match(searchRegexp));
      });
}

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
}));

function Users() {
  const classes = useStyles();
  const [filter, setFilter] = useState({ search: '', activeOnly: true });
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteUser, { isLoading: isDeleting, isSuccess: isDeleted }] = useDeleteUserMutation();
  const { data: users, isLoading } = useGetUsers();
  const filteredUsers = useMemo(
    () => (users ? filterUsers(users, filter.search, filter.activeOnly) : []),
    [users, filter],
  );

  // unselect the row after it has been deleted
  useEffect(() => {
    if (isDeleted && !isDeleting) {
      setSelectedItem(null);
    }
  }, [isDeleted, isDeleting]);

  return (
    <Container fullHeight={isLoading}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className={classes.filterContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.activeOnly}
                  onChange={(e) => setFilter((prev) => ({ ...prev, activeOnly: e.target.checked }))}
                />
              }
              label="Actif seulement"
            />
            <TextField
              value={filter.search}
              onChange={(e) => setFilter((prev) => ({ ...prev, search: e.target.value }))}
              placeholder="Nom d'affichage"
              label="Rechercher"
              variant="outlined"
            />
          </div>

          <Table
            data={filteredUsers}
            getKey={(user) => user.id}
            getRowKey={(user) => user.id}
            paginate
            headCells={headCells}
            renderRow={(row) => (
              <TableRow
                key={row.id}
                hover
                tabIndex={-1}
                onClick={() => setSelectedItem(selectedItem === row ? null : row)}
                selected={row === selectedItem}
              >
                <TableCell>{row.nickname}</TableCell>
                <TableCell> {USER_ROLES_LABELS[getRoleFromArray(row)]}</TableCell>
                <TableCell>{row.isActive ? 'Actif' : 'Inactif'}</TableCell>
              </TableRow>
            )}
          />
          <TableButtons
            deleteMessage="Vous voulez vraiment supprimer cet utilisateur ?"
            informationMessage="Cet utilisateur est déjà inactif"
            editRoute={(item) => `${routes.users.root}/edit/${item.id}`}
            addRoute={routes.users.new}
            onDelete={() => deleteUser(selectedItem.id)}
            selectedItem={selectedItem}
            isDeleting={isDeleting}
          />
        </>
      )}
    </Container>
  );
}

export default Users;
