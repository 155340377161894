import { useQuery } from 'react-query';
import { getISOWeek, getYear } from 'date-fns';

import {
  getPickup,
  getPickups,
  getProductTypes,
  getHalfDayPickups,
  getPickupsDateRange,
} from 'services/api/terraLeoApi/pickupApi';

function useGetPickup(id) {
  return useQuery(['pickup', id], () => getPickup(id), { enabled: id });
}
/**
 * @param {String} date : is required
 * @param {Boolean} isAm : is optionnel, by default is false
 */
function useGetSameDayPickups(date, isAm) {
  return useQuery(['pickups', date, isAm], () => getHalfDayPickups(date, isAm), {
    enabled: date,
  });
}

/**
 * @param {Date} selectedDate : required
 * @param {*} clientId
 */
function useGetPickups(selectedDate, clientId, enabled = true) {
  const isoWeek = getISOWeek(selectedDate);
  const year = getYear(selectedDate);
  return useQuery(['pickups', selectedDate, clientId], () => getPickups(isoWeek, year, clientId), {
    enabled: enabled && isoWeek && year,
  });
}

function useGetProductTypes() {
  return useQuery('productTypes', () => getProductTypes(), {
    staleTime: 3600000,
  });
}

function useGetPickupsDateRange() {
  // refetch after 10 munites
  return useQuery('pickupsDateRange', () => getPickupsDateRange(), {
    staleTime: 600000,
  });
}

export {
  useGetPickup,
  useGetPickups,
  useGetProductTypes,
  useGetSameDayPickups,
  useGetPickupsDateRange,
};
