import { omitBy } from 'lodash';
import { requiredNotEmptyString, positive, requiredvalidZip } from 'services/validationUtils';

export const validateForm = (values, zonePlatformTimes) => {
  const validations = {
    name: requiredNotEmptyString(values.name),
    address: requiredNotEmptyString(values.startAddress),
    zip: requiredvalidZip(values.startZip),
    city: requiredNotEmptyString(values.startCity),
  };
  validations.platforms = {};
  zonePlatformTimes.forEach((item) => {
    const delayValidation = positive(item.travelTime);
    if (delayValidation !== true) {
      validations.platforms = {
        ...validations.platforms,
        [item.platform.id]: delayValidation,
      };
    }
  });
  // if there is no errors delete the object platforms
  if (Object.keys(validations.platforms).length === 0) {
    delete validations.platforms;
  }

  const errors = omitBy(validations, (validation) => validation === true);

  return Object.keys(errors).length > 0 ? errors : true;
};
