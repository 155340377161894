import { omitBy } from 'lodash';
import {
  requiredNotEmptyString,
  validEmail,
  nonNullAndLessThan,
  requiredValue,
} from 'services/validationUtils';

export const validateForm = (values) => {
  const validations = {
    name: requiredNotEmptyString(values.name),
    email: validEmail(values.email),
    capacity: nonNullAndLessThan(values.capacity, 5000),
    dacCode: requiredNotEmptyString(values.dacCode),
    startZone: requiredValue(values.startZone),
  };
  const errors = omitBy(validations, (validation) => validation === true);

  return Object.keys(errors).length > 0 ? errors : true;
};
