import React, { useState, useMemo } from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useGetPickups } from 'hooks/queries/pickupQueries';
import { WeekCircuitTable, Container, Loading } from 'components';
import Filter from './Components/Filter';
import { Button, makeStyles } from '@material-ui/core';
import DuplicateWeekDialog from './Components/DuplicateWeekDialog';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
}));

function useFilteredPickups({ selectedDate, vehicle }) {
  const { data: allPickups, isLoading } = useGetPickups(selectedDate);
  const pickups = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return vehicle ? allPickups.filter((pickup) => pickup.vehicle.id === vehicle.id) : [];
  }, [isLoading, vehicle, allPickups]);

  return [pickups, isLoading];
}

function Circuit() {
  const classes = useStyles();
  const [filter, setFilter] = useState({
    vehicle: null,
    selectedDate: new Date(),
  });

  const [pickups, isLoading] = useFilteredPickups(filter);
  const [openDuplicate, setOpenDuplicate] = useState(false);

  const openDialog = () => {
    setOpenDuplicate(true);
  };

  return (
    <Container fullHeight>
      <Filter values={filter} onChange={setFilter} />
      {isLoading ? (
        <Loading />
      ) : (
        <WeekCircuitTable values={pickups} firstDayOfSelectedPeriod={filter.selectedDate} />
      )}
      <div className={classes.buttonContainer}>
        <Button startIcon={<FileCopyIcon />} color="primary" onClick={openDialog}>
          Dupliquer la semaine
        </Button>
      </div>

      <DuplicateWeekDialog
        selectedDate={filter.selectedDate}
        open={openDuplicate}
        onClose={() => setOpenDuplicate(false)}
      />
    </Container>
  );
}

export default Circuit;
