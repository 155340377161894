import { omitBy } from 'lodash';
import {
  requiredNotEmptyString,
  requiredValidEmail,
  requiredValue,
} from 'services/validationUtils';

export const validateForm = (values) => {
  const validations = {
    email: requiredValidEmail(values.email),
    nickname: requiredNotEmptyString(values.nickname),
    role: requiredValue(values.roles),
  };
  const errors = omitBy(validations, (validation) => validation === true);

  return Object.keys(errors).length > 0 ? errors : true;
};
