import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';

import {
  addClientHours as addRequest,
  updateClientHours as updateRequest,
  deleteClientHours as deleteRequest,
} from 'services/api/terraLeoApi/clientHoursApi';

function useAddClientHoursMutation() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(addRequest, {
    onError: () => {
      enqueueSnackbar("Une Erreur est survenue lors de l'ajout des horaires.", {
        variant: 'error',
      });
    },
  });
}

function useUpdateClientHoursMutation() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(updateRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue lors de la modification des horaires.', {
        variant: 'error',
      });
    },
  });
}

function useDeleteClientHoursMutation() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(deleteRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue lors de la suppression des horaires.', {
        variant: 'error',
      });
    },
  });
}

export { useAddClientHoursMutation, useUpdateClientHoursMutation, useDeleteClientHoursMutation };
