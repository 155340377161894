import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TodayIcon from '@material-ui/icons/Today';
import SearchIcon from '@material-ui/icons/Search';
import BarChartIcon from '@material-ui/icons/BarChart';
import StoreIcon from '@material-ui/icons/Store';
import routes from 'config/routes';
import { Recycle } from 'components/Icons';
import { PeopleAltOutlined } from '@material-ui/icons';
import { USER_ROLES } from 'config/constants';

// toDO : change visible by the list of permitted useRoles

const itemsList = (userRole) => [
  {
    text: 'Accueil',
    icon: <DashboardIcon />,
    to: routes.home.root,
    visible: true,
  },
  {
    text: 'Clients',
    icon: <StoreIcon />,
    to: routes.clients.root,
    visible: true,
  },
  {
    text: 'Véhicules',
    icon: <LocalShippingIcon />,
    to: routes.vehicles.root,
    visible: userRole === USER_ROLES.ADMIN,
  },
  {
    text: 'Zone',
    icon: <LocationOnIcon />,
    to: routes.zone.root,
    visible: userRole === USER_ROLES.ADMIN,
  },
  {
    text: 'Plateformes',
    icon: <Recycle />,
    to: routes.platforms.root,
    visible: userRole === USER_ROLES.ADMIN,
  },
  {
    text: 'Collectes',
    icon: <AccessTimeIcon />,
    to: routes.recurrence.root,
    visible: userRole === USER_ROLES.ADMIN || userRole === USER_ROLES.PLANNER,
  },
  {
    text: 'Circuits',
    icon: <TodayIcon />,
    to: routes.circuit.root,
    visible: true,
  },
  {
    text: 'Suivi hebdo',
    icon: <SearchIcon />,
    to: routes.weeklyFollowUp.root,
    visible: userRole === USER_ROLES.ADMIN || userRole === USER_ROLES.PLANNER,
  },
  {
    text: 'Suivi mensuel',
    icon: <BarChartIcon />,
    to: routes.monthlyFollowUp.root,
    visible: userRole === USER_ROLES.ADMIN,
  },
  {
    text: 'Utilisateurs',
    icon: <PeopleAltOutlined />,
    to: routes.users.root,
    visible: userRole === USER_ROLES.ADMIN,
  },
  {
    text: 'Administration',
    icon: <SettingsIcon />,
    to: '/administration',
    visible: userRole === USER_ROLES.ADMIN,
  },
];

export default itemsList;
