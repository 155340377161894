import React, { forwardRef } from 'react';
import { Button, CircularProgress } from '@material-ui/core';

const ButtonWithLoading = forwardRef(
  (
    {
      isLoading,
      disabled = isLoading,
      label,
      onClick,
      color,
      circularProgressSize = 20,
      startIcon,
      ...other
    },
    ref,
  ) => {
    return (
      <Button
        ref={ref}
        color={color || 'primary'}
        onClick={onClick}
        startIcon={isLoading ? <CircularProgress size={circularProgressSize} /> : startIcon}
        disabled={disabled}
        {...other}
      />
    );
  },
);

export default ButtonWithLoading;
