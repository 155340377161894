import { Switch } from 'react-router-dom';

import { OnlyPrivateRoute, OnlyPublicRoute } from 'components';
import routes from 'config/routes';
import SecuredArea from './SecuredArea';
import Login from './Login';
import ResetPassword from './ResetPassword';

function Pages() {
  return (
    <Switch>
      <OnlyPublicRoute path={routes.login.root}>
        <Login />
      </OnlyPublicRoute>
      <OnlyPublicRoute path={routes.login.resetPassword} showMessage>
        <ResetPassword />
      </OnlyPublicRoute>
      <OnlyPrivateRoute path={routes.root}>
        <SecuredArea />
      </OnlyPrivateRoute>
    </Switch>
  );
}

export default Pages;
