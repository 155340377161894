import { useQuery } from 'react-query';
import { getYear, getISOWeek, format } from 'date-fns';
import { getWeeklyFollowUp, getMonthlyFollowUp } from 'services/api/terraLeoApi/followUpApi';

function useGetWeeklyFollowUp(date) {
  const weekNumber = getISOWeek(date);
  const year = getYear(date);
  return useQuery(['weeklyFollowUp', weekNumber, year], () => getWeeklyFollowUp(weekNumber, year));
}

function useGetMonthlyFollowUp(date, clientType) {
  const month = format(date, 'M');
  const year = getYear(date);
  return useQuery(['monthlyFollowUp', month, year, clientType], () =>
    getMonthlyFollowUp(month, year, clientType === 'collecte' ? 'collectable' : 'billable'),
  );
}

export { useGetWeeklyFollowUp, useGetMonthlyFollowUp };
