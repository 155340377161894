import React from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center ',
    height: '100%',
    flexGrow: ({ fullwidth }) => (fullwidth ? 1 : 0),
  },
}));

function Loading({ size, small, fullwidth, className }) {
  const classes = useStyles({ fullwidth });
  return (
    <div className={clsx(classes.loadingContainer, className)}>
      {small ? <CircularProgress size={20} /> : <CircularProgress size={size} />}
    </div>
  );
}

export default Loading;
