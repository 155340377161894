import terraLeoApiClient from 'services/api/terraLeoApi/terraLeoApiClient';

import { BACKEND_DEPOSIT_ENDPOINTS } from '../constants';

async function getDeposit(id) {
  const response = await terraLeoApiClient.getRequest(BACKEND_DEPOSIT_ENDPOINTS.GET_DEPOSIT(id));
  return response.data;
}

async function getDepositPickups(id) {
  const response = await terraLeoApiClient.getRequest(
    BACKEND_DEPOSIT_ENDPOINTS.GET_DEPOSIT_PICKUPS(id),
  );
  return response.data;
}

async function updateDeposit(deposit) {
  const response = await terraLeoApiClient.patchRequest(
    BACKEND_DEPOSIT_ENDPOINTS.EDIT_DEPOSIT(deposit.id),
    deposit,
  );
  return response.data;
}

export { getDeposit, updateDeposit, getDepositPickups };
