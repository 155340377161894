import React from 'react';
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import { TimePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import { FormGroup, ErrorHelper, Label } from 'components';
import ProductTypeInput from './ProductTypeInput';

function LeftSection({ values, onChange, errors, readOnly }) {
  return (
    <FormGroup.Left sm={12} lg={6}>
      <Grid container spacing={2}>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>DAC</Label>
          </Grid>
          <Grid item xs>
            <TextField fullWidth value={values.dacNumber} disabled />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Statut</Label>
          </Grid>
          <Grid item xs>
            <TextField
              value={values.status ? values.status.name : 'Non renseigné'}
              disabled
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Date de collecte</Label>
          </Grid>
          <Grid item xs={8}>
            <TextField value={format(new Date(values.date), 'dd/MM/yyyy')} disabled />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Heure de collecte</Label>
          </Grid>
          <Grid item xs={8}>
            <TimePicker
              value={values.collectionTime}
              onChange={(v) => onChange((prev) => ({ ...prev, collectionTime: v }))}
              ampm={false}
              disabled={readOnly || values.isRefused}
              cancelLabel="Annuler"
            />
            {errors?.collectionTime && <ErrorHelper message={errors.collectionTime} />}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Poids total</Label>
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              value={values.weight}
              onChange={(e) => onChange((prev) => ({ ...prev, weight: e.target.value }))}
              type="number"
              placeholder="Poids total"
              InputProps={{
                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                inputProps: { min: 0, max: 5000 },
              }}
              disabled={values.isRefused || readOnly}
            />
            {errors?.weight && <ErrorHelper message={errors.weight} />}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Produits</Label>
          </Grid>
          <Grid item xs>
            <ProductTypeInput
              value={values.type}
              onChange={(v) => onChange((prev) => ({ ...prev, type: v }))}
              row
              disabled={readOnly}
            />
            {errors?.type && <ErrorHelper message={errors.type} />}
          </Grid>
        </Grid>
      </Grid>
    </FormGroup.Left>
  );
}

export default LeftSection;
