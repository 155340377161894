import { forwardRef, useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  makeStyles,
  TextField,
  Slide,
} from '@material-ui/core';
import { Label, WeekPickerWithArrows, ErrorHelper } from 'components';
import { useDuplicatePlanning } from 'hooks/mutations/planningMutations';
import { validateForm } from './validation';
import { addDays, format, getISOWeek, startOfWeek } from 'date-fns';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getInitialState = (date) => {
  const selectedDate = new Date(
    format(startOfWeek(new Date(date), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
  );
  const today = new Date(format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd'));
  const isBefore =
    new Date(format(selectedDate, 'yyyy-MM-dd')) < new Date(format(today, 'yyyy-MM-dd'));
  const startDate = isBefore ? addDays(today, 7) : addDays(selectedDate, 7);
  return {
    duration: 1,
    minDate: startDate,
    startDate,
  };
};

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiPaper-root': {
      width: '500px',
    },
  },
}));

function DuplicateWeekDialog({ open, onClose, selectedDate }) {
  const classes = useStyles();
  const [state, setState] = useState(getInitialState(selectedDate));
  const [errors, setErrors] = useState({});
  const [
    duplicatePlanning,
    { isSuccess: isDuplicated, isLoading: isDuplicating },
  ] = useDuplicatePlanning();

  useEffect(() => {
    if (isDuplicated && !isDuplicating) {
      resetForm();
      onClose();
    }
  }, [isDuplicated, isDuplicating, onClose]);

  useEffect(() => {
    setState(getInitialState(selectedDate));
  }, [selectedDate]);

  const handleDurationChange = ({ target: { value } }) => {
    let newValue = value;
    if (Number(value) < 0) newValue = 0;
    setState((prev) => ({ ...prev, duration: newValue }));
  };

  const resetForm = () => {
    setErrors({});
  };

  const submit = () => {
    const formErrors = validateForm(state);
    if (formErrors !== true) {
      setErrors(formErrors);
    } else {
      setErrors({});
      duplicatePlanning({
        n: Number(state.duration),
        weekNumber: getISOWeek(selectedDate),
        startWeek: getISOWeek(state.startDate),
      });
    }
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Dialog
      className={classes.dialog}
      TransitionComponent={Transition}
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="alert-dialog-title">Dupliquer la semaine sur plusieurs semaines</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item container direction="row" xs={12} alignItems="center">
            <Grid item xs={4}>
              <Label small>Semaine de début</Label>
            </Grid>
            <Grid container item xs={8} justify="center">
              <WeekPickerWithArrows
                value={state.startDate}
                onChange={(date) => setState((prev) => ({ ...prev, startDate: date }))}
                fullWidth={false}
                minDate={state.minDate}
              />
            </Grid>
            {errors?.startDate && <ErrorHelper message={errors.startDate} />}
          </Grid>

          <Grid item container direction="row" xs={12} alignItems="center">
            <Grid item xs={4}>
              <Label small>Nombre de semaines</Label>
            </Grid>
            <Grid container item xs={8} justify="center">
              <TextField type="number" value={state.duration} onChange={handleDurationChange} />
            </Grid>
            {errors?.duration && <ErrorHelper message={errors.duration} />}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={submit} color="primary" autoFocus>
          Confirmer
        </Button>
        <Button onClick={handleClose}>Annuler</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DuplicateWeekDialog;
