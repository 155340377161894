import { useMutation, useQueryCache } from 'react-query';
import { useSnackbar } from 'notistack';

import { duplicatePlanning, generatePlanning } from 'services/api/terraLeoApi/planningApi';

function useDuplicatePlanning() {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(duplicatePlanning, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryCache.refetchQueries('recurrences');
      enqueueSnackbar('Le planning a été dupliqué.', {
        variant: 'success',
      });
    },
  });
}

function useGeneratePlanning() {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(generatePlanning, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryCache.refetchQueries('recurrences');
      enqueueSnackbar('La planification a été générée.', {
        variant: 'success',
      });
    },
  });
}

export { useDuplicatePlanning, useGeneratePlanning };
