import terraLeoApiClient from 'services/api/terraLeoApi/terraLeoApiClient';
import { BACKEND_FOLLOW_UP_ENDPOINTS } from '../constants';

async function getWeeklyFollowUp(weekNumber, year) {
  const response = await terraLeoApiClient.getRequest(
    BACKEND_FOLLOW_UP_ENDPOINTS.WEEKLY + `?weekNumber=${weekNumber}&year=${year}`,
  );
  return response.data;
}

async function getMonthlyFollowUp(month, year, clientType) {
  const response = await terraLeoApiClient.getRequest(
    BACKEND_FOLLOW_UP_ENDPOINTS.MONTHLY + `?month=${month}&year=${year}&clientType=${clientType}`,
  );
  return response.data;
}

export { getWeeklyFollowUp, getMonthlyFollowUp };
