import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';

import { login as loginRequest } from 'services/api/terraLeoApi/authApi';
import { useAuth } from 'contexts/authContext';

function useLoginMutation() {
  const { login } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(loginRequest, {
    onError: (error) => {
      const message =
        error.response && error.response.status === 401
          ? 'Email ou mot de passe incorrect'
          : 'Une erreur est survenue';
      enqueueSnackbar(message, { variant: 'error' });
    },
    onSuccess: (response) => {
      const { token, refreshToken } = response.data;
      login(token, refreshToken);
    },
  });
}

export default useLoginMutation;
