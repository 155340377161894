import { useMutation, useQueryCache } from 'react-query';
import { useSnackbar } from 'notistack';

import {
  addPickup as addRequest,
  updatePickup as updateRequest,
  deletePickup as deleteRequest,
  changePickupsDeposit,
} from 'services/api/terraLeoApi/pickupApi';

function useAddPickupmMutation() {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(addRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryCache.refetchQueries('pickups');
      enqueueSnackbar("L'intervention a bien été ajoutée.", {
        variant: 'success',
      });
    },
  });
}

function useUpdatePickupMutation() {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(updateRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryCache.refetchQueries('pickups');
      enqueueSnackbar("L'intervention a bien été modifiée.", {
        variant: 'success',
      });
    },
  });
}

function useDeletePickupMutation(unSelectItem) {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(deleteRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryCache.refetchQueries('pickups');
      enqueueSnackbar("L'intervention a bien été supprimée.", {
        variant: 'success',
      });
      // unselect the he selected item
      unSelectItem && unSelectItem();
    },
  });
}

function useChangePickupsDeposit() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(changePickupsDeposit, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
  });
}

export {
  useAddPickupmMutation,
  useUpdatePickupMutation,
  useDeletePickupMutation,
  useChangePickupsDeposit,
};
