import { useState } from 'react';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { Label } from 'components';
import NewOpeningDateDialog from './NewOpeningDateDialog';
import OpeningDateList from './OpeningDateList';

function OpeningDateInput({ value, onChange }) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const daysNumber = Object.keys(value).length;
  const disabledDays = Object.keys(value);

  return (
    <>
      {daysNumber < 5 && (
        <>
          <Button
            variant="text"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={() => setDialogOpen(true)}
          >
            Définir des horaires d'ouverture
          </Button>
          <NewOpeningDateDialog
            disabledDays={disabledDays}
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            onAdd={(newValue) => onChange({ ...value, ...newValue })}
          />
        </>
      )}

      {daysNumber === 0 ? (
        <Label subLabel>Aucun horaire défini</Label>
      ) : (
        <OpeningDateList onChange={onChange} values={value} />
      )}
    </>
  );
}

export default OpeningDateInput;
