import { makeStyles } from '@material-ui/core/styles';
import * as colors from 'theme/colors';

export default makeStyles((theme) => ({
  list: {
    '& .active': {
      '& *': {
        color: colors.yellow,
        fill: colors.yellow,
        fontWeight: 'bold',
      },
    },
  },
}));
