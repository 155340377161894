import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';

import {
  getUsers,
  getUser,
  forgotPassword,
  getUserWithUuid,
} from 'services/api/terraLeoApi/userApi';

function useGetUser(id) {
  // run the query if the id is defined
  return useQuery(['user', id], () => getUser(id), {
    enabled: id,
  });
}

function useGetUsers() {
  return useQuery('users', getUsers);
}

// send email to the user to renintialize his password
function useForgotPassword(id) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery('forgotPassword', () => forgotPassword(id), {
    enabled: false,
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Un mail a bien été envoyé.', {
        variant: 'success',
      });
    },
  });
}

function useGetUserWithUuid(uuid) {
  // run the query if the id is defined
  return useQuery(['userUuid', uuid], () => getUserWithUuid(uuid), {
    enabled: uuid,
  });
}
export { useGetUser, useGetUsers, useForgotPassword, useGetUserWithUuid };
