import terraLeoApiClient from 'services/api/terraLeoApi/terraLeoApiClient';

import { BACKEND_CLIENTS_ENDPOINTS } from '../constants';

async function getClients() {
  const response = await terraLeoApiClient.getRequest(BACKEND_CLIENTS_ENDPOINTS.LIST_CLIENTS);
  return response.data;
}

async function getClient(id) {
  const response = await terraLeoApiClient.getRequest(BACKEND_CLIENTS_ENDPOINTS.GET_CLIENT(id));
  return response.data;
}

async function getClientsTypes() {
  const response = await terraLeoApiClient.getRequest(BACKEND_CLIENTS_ENDPOINTS.LIST_CLIENTS_TYPES);
  return response.data;
}

async function addClient(client) {
  const response = await terraLeoApiClient.postRequest(
    BACKEND_CLIENTS_ENDPOINTS.ADD_CLIENT,
    client,
  );
  return response.data;
}

async function updateClient(client) {
  const response = await terraLeoApiClient.putRequest(
    BACKEND_CLIENTS_ENDPOINTS.EDIT_CLIENT(client.id),
    client,
  );
  return response.data;
}

async function deleteClient(id) {
  const response = await terraLeoApiClient.patchRequest(
    BACKEND_CLIENTS_ENDPOINTS.DELETE_CLIENT(id),
    { isActive: false },
  );
  return response.data;
}

export { getClients, getClient, getClientsTypes, addClient, updateClient, deleteClient };
