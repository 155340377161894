import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { CSVLink } from 'react-csv';
import { format, getYear, endOfWeek, startOfWeek, getDay, isSameMonth } from 'date-fns';
import { fr } from 'date-fns/locale';

import { useGetPickupsDateRange } from 'hooks/queries/pickupQueries';
import { useGetWeeklyFollowUp } from 'hooks/queries/followUpQueries';
import { Container, Loading, AlertDialog } from 'components';
import WeeklyFollowUpTable from './components/WeeklyFollowUpTable';
import Filter from './components/Filter';
import { getClosestDayInInterval } from 'services/dateUtils';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
}));

function WeeklyFollowUp() {
  const classes = useStyles();
  const [filter, setFilter] = useState({
    client: null,
    selectedDate: new Date(),
  });
  const csvInstance = useRef(null);
  const [open, setOpen] = useState(false);
  const { data: weeklyFollowUp, isLoading } = useGetWeeklyFollowUp(filter.selectedDate);
  const { data: pickupsDateRange } = useGetPickupsDateRange();

  const displayRows = useMemo(() => {
    if (weeklyFollowUp) {
      const allRows = weeklyFollowUp.map((user) => {
        let totalWeight = 0;
        const daysOfTheWeek = {
          1: [],
          2: [],
          3: [],
          4: [],
          5: [],
        };
        user.data.forEach((item) => {
          totalWeight = totalWeight + item.weight;
          daysOfTheWeek[getDay(new Date(item.date))]?.push(item);
        });
        return { user, daysOfTheWeek, totalWeight };
      });

      return filter.client ? allRows.filter((item) => item.user.id === filter.client.id) : allRows;
    } else {
      return [];
    }
  }, [weeklyFollowUp, filter.client]);

  const totalRow = useMemo(() => {
    return {
      1: displayRows.reduce(
        (a, b) => a + b.daysOfTheWeek[1].reduce((partialSum, i) => partialSum + i.weight, 0),
        0,
      ),
      2: displayRows.reduce(
        (a, b) => a + b.daysOfTheWeek[2].reduce((partialSum, i) => partialSum + i.weight, 0),
        0,
      ),
      3: displayRows.reduce(
        (a, b) => a + b.daysOfTheWeek[3].reduce((partialSum, i) => partialSum + i.weight, 0),
        0,
      ),
      4: displayRows.reduce(
        (a, b) => a + b.daysOfTheWeek[4].reduce((partialSum, i) => partialSum + i.weight, 0),
        0,
      ),
      5: displayRows.reduce(
        (a, b) => a + b.daysOfTheWeek[5].reduce((partialSum, i) => partialSum + i.weight, 0),
        0,
      ),
      totalClientsWeight: displayRows.reduce((a, b) => a + b.totalWeight, 0),
    };
  }, [displayRows]);

  const dateLabel = useMemo(() => {
    const start = startOfWeek(filter.selectedDate, { weekStartsOn: 1 });
    const end = endOfWeek(filter.selectedDate, { weekStartsOn: 1 });
    const week = isSameMonth(start, end)
      ? `${format(start, 'dd')} au ${format(end, 'dd')} ${format(filter.selectedDate, 'MMM', {
          locale: fr,
        })}`
      : `${format(start, 'dd')} ${format(filter.selectedDate, 'MMM', { locale: fr })} au ${format(
          end,
          'dd',
        )} ${format(end, 'MMM', { locale: fr })}`;
    return `Semaine du ${week} ${getYear(filter.selectedDate)}`;
  }, [filter.selectedDate]);

  const csvData = useMemo(
    () => [
      [dateLabel, 'LUNDI', 'MARDI', 'MERCREDI', 'JEUDI', 'VENDREDI', 'TOTAL POIDS/CLIENTS'],
      ['', 'POIDS(KG)', 'POIDS(KG)', 'POIDS(KG)', 'POIDS(KG)', 'POIDS(KG)', ''],
      ...displayRows.map((row) => {
        return [
          row.user.name,
          row.daysOfTheWeek[1].reduce((partialSum, b) => partialSum + b.weight, 0) || '',
          row.daysOfTheWeek[2].reduce((partialSum, b) => partialSum + b.weight, 0) || '',
          row.daysOfTheWeek[3].reduce((partialSum, b) => partialSum + b.weight, 0) || '',
          row.daysOfTheWeek[4].reduce((partialSum, b) => partialSum + b.weight, 0) || '',
          row.daysOfTheWeek[5].reduce((partialSum, b) => partialSum + b.weight, 0) || '',
          row.totalWeight,
        ];
      }),
      [
        'TOTAL',
        totalRow[1],
        totalRow[2],
        totalRow[3],
        totalRow[4],
        totalRow[5],
        totalRow.totalClientsWeight,
      ],
    ],
    [displayRows, dateLabel, totalRow],
  );

  const exportData = () => {
    if (!weeklyFollowUp || (weeklyFollowUp && weeklyFollowUp.length === 0)) {
      setOpen(true);
    } else {
      csvInstance.current && csvInstance.current.link.click();
    }
  };

  // Display the first available pickup week by default
  useEffect(() => {
    if (pickupsDateRange && pickupsDateRange[0]) {
      const { min: minDate, max: maxDate } = pickupsDateRange[0];
      setFilter((prev) => ({ ...prev, selectedDate: getClosestDayInInterval(minDate, maxDate) }));
    }
  }, [pickupsDateRange]);

  return (
    <Container fullHeight>
      <Filter values={filter} onChange={setFilter} />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <WeeklyFollowUpTable data={displayRows} dateLabel={dateLabel} />
          <div className={classes.buttonContainer}>
            <Button
              startIcon={<DescriptionIcon />}
              color="primary"
              onClick={exportData}
              disabled={displayRows.length === 0}
            >
              Exporter une semaine
            </Button>
            <CSVLink
              data={csvData}
              ref={csvInstance}
              separator={';'}
              filename={`Suivi hebdo ${dateLabel.replace('Semaine ', '')}.csv`}
            />
          </div>
        </>
      )}
      <AlertDialog
        message="Aucun objet n’est exportable sur la période sélectionnée"
        title="Information"
        open={open}
        onClose={() => setOpen(false)}
      />
    </Container>
  );
}

export default WeeklyFollowUp;
