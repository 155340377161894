import React, { useState, useEffect } from 'react';
import { TextField, Typography, Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { ButtonWithLoading, Label, Loading, ErrorPage, ErrorHelper } from 'components';
import useLoginMutation from 'hooks/mutations/useLoginMutation';
import { useUpdatePassword } from 'hooks/mutations/userMutations';
import { useGetUserWithUuid } from 'hooks/queries/userQueries';
import useStyles from './styles';

function ResetPassword() {
  const classes = useStyles();
  const { uuid } = useParams();
  const [values, setValues] = useState({ password: '', confirmPassword: '' });
  const { data, isLoading, error } = useGetUserWithUuid(uuid);
  const [updatePassword, { isSuccess: isPswUpdated, isLoading: updatingPsw }] = useUpdatePassword();
  const [login] = useLoginMutation();
  const [isSamePassword, setIsSamePassword] = useState(true);

  useEffect(() => {
    if (isPswUpdated && !updatingPsw) {
      login({ email: data.user.email, password: values.password });
    }
  }, [isPswUpdated, updatingPsw]); // eslint-disable-line react-hooks/exhaustive-deps

  const isEmpty = () => values.password === '' || values.confirmPassword === '';

  const submit = () => {
    if (values.password === values.confirmPassword) {
      updatePassword({ id: data.user.id, newPassword: values.password, token: data.token });
    } else {
      setIsSamePassword(false);
    }
  };

  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <Loading fullwidth />
      </div>
    );
  } else if (error && error.response) {
    return (
      <ErrorPage
        error={error}
        notFoundMessage={
          "Veuillez contacter l'administrateur afin de vous envoyer " +
          'un nouveau email pour réinitialiser votre mot de passe.'
        }
      />
    );
  }

  return (
    <div className={classes.container}>
      <div>
        <Grid container className={classes.formContainer}>
          <Grid item sm={12} md={4} lg={4}>
            <Label>Réinitialisation de mot de passe</Label>
            <Typography>Saissez un nouveau mot de passe pour {data.user.email}</Typography>
          </Grid>
          <Grid item sm={12} md={8} lg={8} className={classes.formGridItem}>
            <div className={classes.form}>
              <Label small>Nouveau mot de passe </Label>
              <TextField
                placeholder="Nouveau"
                type="password"
                value={values.password}
                onChange={(event) =>
                  setValues((prev) => ({ ...prev, password: event.target.value }))
                }
                disabled={isLoading}
              />
              <Label small>Confirmer le nouveau mot de passe</Label>
              <TextField
                placeholder="Confirmer"
                type="password"
                value={values.confirmPassword}
                onChange={(event) =>
                  setValues((prev) => ({ ...prev, confirmPassword: event.target.value }))
                }
                disabled={isLoading}
              />
              {!isSamePassword && (
                <ErrorHelper message="Le deux mot de passe ne correspondent pas" />
              )}

              <ButtonWithLoading
                isLoading={updatingPsw}
                disabled={updatingPsw || isEmpty()}
                className={classes.button}
                onClick={submit}
              >
                Réinitialiser
              </ButtonWithLoading>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ResetPassword;
