import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { getDac, getZip } from 'services/api/terraLeoApi/dacApi';

function useGetDac() {
  const { enqueueSnackbar } = useSnackbar();
  return (dacId) => {
    getDac(dacId)
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${dacId}.pdf`);
        document.body.appendChild(link);
        link.click();
        enqueueSnackbar('Téléchargé avec succès.', {
          variant: 'success',
        });
      })
      .catch(() =>
        enqueueSnackbar('Une Erreur est survenue.', {
          variant: 'error',
        }),
      );
  };
}

function useGetZip() {
  const { enqueueSnackbar } = useSnackbar();
  return (month, year, clientId, clientName) => {
    getZip(month, year, clientId)
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${month}-${year}-${clientName}.zip`);
        document.body.appendChild(link);
        link.click();
        enqueueSnackbar('Téléchargé avec succès.', {
          variant: 'success',
        });
      })
      .catch((error) => {
        if (
          Number(format(new Date(), 'MM')) >= Number(month) &&
          Number(format(new Date(), 'yyyy')) >= Number(year)
        ) {
          enqueueSnackbar('Mois non finalisé, extraction impossible', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Une Erreur est survenue.', {
            variant: 'error',
          });
        }
      });
  };
}

export { useGetDac, useGetZip };
