import { isBefore, isEqual } from 'date-fns';
import { omitBy } from 'lodash';

import { requiredValue, requiredValidDate } from 'services/validationUtils';

export const validateForm = (values) => {
  const validations = {
    client: requiredValue(values.client),
    startDate: requiredValidDate(values.startDate),
    endDate: validateEndDate(values.startDate, values.endDate),
    preferredDays: validatePreferredDays(values.recurrence, values.preferredDays),
  };
  const errors = omitBy(validations, (validation) => validation === true);

  return Object.keys(errors).length > 0 ? errors : true;
};
// end date isn't required
const validateEndDate = (startDate, endDate) => {
  if (endDate) {
    // should be a valid date
    const isValid = requiredValidDate(endDate);
    if (isValid !== true) {
      return isValid;
    }
    const start = new Date(startDate);
    start.setHours(0, 0, 0, 0);
    const end = new Date(endDate);
    end.setHours(0, 0, 0, 0);
    if (isBefore(end, start) || isEqual(start, end)) {
      return 'Doit être postérieure à la date de début';
    }
  }
  return true;
};

const validatePreferredDays = (recurrence, preferredDays) => {
  if (recurrence && preferredDays) {
    if (Object.entries(preferredDays).filter(([_, value]) => value).length > Number(recurrence)) {
      return 'Le nombre de jours préférés doit être inférieur ou égal à la fréquence hebdomadaire';
    }
  }

  return true;
};
