import terraLeoApiClient from 'services/api/terraLeoApi/terraLeoApiClient';

import { BACKEND_ZONE_PLATFORM_TIMES_ENDPOINTS } from '../constants';

async function getZonePlatformTimes(zoneId) {
  const response = await terraLeoApiClient.getRequest(
    BACKEND_ZONE_PLATFORM_TIMES_ENDPOINTS.LIST_ZONE_PLATFORM(zoneId),
  );
  return response.data;
}

async function addZonePlatformTimes(zonePlatformTimes) {
  const response = await terraLeoApiClient.postRequest(
    BACKEND_ZONE_PLATFORM_TIMES_ENDPOINTS.ADD_ZONE_PLATFORM(zonePlatformTimes.zone),
    zonePlatformTimes,
  );
  return response.data;
}

async function updateZonePlatformTimes(zonePlatformTimes) {
  const response = await terraLeoApiClient.putRequest(
    BACKEND_ZONE_PLATFORM_TIMES_ENDPOINTS.EDIT_ZONE_PLATFORM(
      zonePlatformTimes.zone,
      zonePlatformTimes.platform,
    ),
    zonePlatformTimes,
  );
  return response.data;
}

async function deleteZonePlatformTimes(zonePlatformTimes) {
  const response = await terraLeoApiClient.deleteRequest(
    BACKEND_ZONE_PLATFORM_TIMES_ENDPOINTS.DELETE_ZONE_PLATFORM(
      zonePlatformTimes.zone,
      zonePlatformTimes.platform,
    ),
  );
  return response.data;
}

export {
  addZonePlatformTimes,
  updateZonePlatformTimes,
  getZonePlatformTimes,
  deleteZonePlatformTimes,
};
