import React, { useState, useMemo } from 'react';
import {
  TableRow,
  TableCell,
  TextField,
  FormControlLabel,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import get from 'lodash/get';
import { format } from 'date-fns';

import { TableButtons, Container, Table, Loading } from 'components';
import routes from 'config/routes';
import { useGetRecurrences } from 'hooks/queries/recurrenceQueries';
import GeneratePlanningButton from './GeneratePlaningButton';

const headCells = [
  { id: 'client.name', label: 'Client' },
  { id: 'startDate', label: 'Date début' },
  { id: 'endDate', label: 'Date fin' },
  { id: 'recurrence', label: 'Fréquence souhaitée' },
  { id: 'isPlanificationComplete', label: 'Planifié complet' },
];

function filterPickupsRecurrences(recurrences, search, currentPickupsOnly) {
  if (search === '') {
    // show old collectes or not
    return currentPickupsOnly
      ? recurrences.filter((recurrence) =>
          recurrence.endDate ? new Date(recurrence.endDate) >= new Date() : true,
        )
      : recurrences;
  }
  const searchRegexp = new RegExp(search, 'gi');
  const searchedFields = ['client.name'];
  return currentPickupsOnly
    ? recurrences
        .filter((recurrence) => new Date(recurrence.endDate) >= new Date())
        .filter((u) => {
          return searchedFields.some((field) => get(u, field, '').match(searchRegexp));
        })
    : recurrences.filter((u) => {
        return searchedFields.some((field) => get(u, field, '').match(searchRegexp));
      });
}

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
}));

function Recurrence() {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState(null);
  const [filter, setFilter] = useState({
    search: '',
    currentPickupsOnly: true,
  });
  const { data: recurrences, isLoading } = useGetRecurrences();
  const filtredPickupRecurrences = useMemo(
    () =>
      recurrences
        ? filterPickupsRecurrences(recurrences, filter.search, filter.currentPickupsOnly)
        : [],
    [recurrences, filter],
  );

  const rightButtons = <GeneratePlanningButton />;

  return (
    <Container fullHeight={isLoading}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className={classes.filterContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.currentPickupsOnly}
                  onChange={(e) =>
                    setFilter((prev) => ({ ...prev, currentPickupsOnly: e.target.checked }))
                  }
                />
              }
              label="En cours seulement"
            />
            <TextField
              value={filter.search}
              onChange={(e) => setFilter((prev) => ({ ...prev, search: e.target.value }))}
              placeholder="Client"
              label="Rechercher"
              variant="outlined"
            />
          </div>

          <Table
            data={filtredPickupRecurrences}
            getKey={(recurrence) => recurrence.id}
            getRowKey={(recurrence) => recurrence.id}
            paginate
            headCells={headCells}
            renderRow={(row) => (
              <TableRow
                key={row.id}
                hover
                tabIndex={-1}
                onClick={() => setSelectedItem(selectedItem === row ? null : row)}
                selected={row === selectedItem}
              >
                <TableCell>{row.client.name}</TableCell>
                <TableCell>{format(new Date(row.startDate), 'dd/MM/yyyy')}</TableCell>
                <TableCell>
                  {row.endDate ? format(new Date(row.endDate), 'dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell>{row.recurrence}</TableCell>
                <TableCell>
                  <Checkbox checked={row.isPlanificationComplete} disabled={true} />
                </TableCell>
              </TableRow>
            )}
          />
          <TableButtons
            editRoute={(item) => `${routes.recurrence.root}/edit/${item.id}`}
            addRoute={routes.recurrence.new}
            selectedItem={selectedItem}
            rightButtons={[rightButtons]}
          />
        </>
      )}
    </Container>
  );
}

export default Recurrence;
