import { omitBy } from 'lodash';
import { nonNullAndLessThan, requiredValue, requiredValidDate } from 'services/validationUtils';

export const validateForm = (values) => {
  const validations = {
    // optionnel
    weight: values.weight ? nonNullAndLessThan(values.weight, 5000) : true,
    // required
    vehicle: requiredValue(values.vehicle.id),
    client: requiredValue(values.client.id),
    date: requiredValidDate(values.date, true),
  };
  const errors = omitBy(validations, (validation) => validation === true);

  return Object.keys(errors).length > 0 ? errors : true;
};
