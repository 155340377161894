import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { TimePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import { FormGroup, ErrorHelper, Label } from 'components';
import PickupsTable from './PickupsTable';

function LeftSection({ values, onChange, depositPickups, errors }) {
  return (
    <FormGroup.Left sm={12} lg={6}>
      <Grid container spacing={2}>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Véhicule</Label>
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              disabled
              value={depositPickups && depositPickups[0] ? depositPickups[0].vehicle.name : null}
            />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Date de dépôt</Label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              value={
                depositPickups && depositPickups[0]
                  ? format(new Date(depositPickups[0].date), 'dd/MM/yyyy')
                  : ''
              }
              disabled
            />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Heure de dépôt</Label>
          </Grid>
          <Grid item xs={8}>
            <TimePicker
              ampm={false}
              value={values.depositTime}
              onChange={(v) => onChange((prev) => ({ ...prev, depositTime: v }))}
              format="HH:mm"
            />
            {errors?.depositTime && <ErrorHelper message={errors.depositTime} />}
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item xs={12}>
            <Label small>Collectes associées avec statut collecté</Label>
          </Grid>
          <Grid item xs={12}>
            <PickupsTable values={depositPickups || []} />
          </Grid>
        </Grid>
      </Grid>
    </FormGroup.Left>
  );
}

export default LeftSection;
