import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { cloneDeep, pick } from 'lodash';
import { format } from 'date-fns';

import {
  Container,
  Section,
  ButtonsContainer,
  ButtonWithLoading,
  Loading,
  ErrorPage,
  CancelButton,
  SubmitAndRedirectButton,
} from 'components';
import { useUpdatePickupMutation } from 'hooks/mutations/pickupMutation';
import { useGetPickup } from 'hooks/queries/pickupQueries';
import { PICKUP_STATUS } from 'config/constants';
import { routes } from 'config';

import LeftSection from './components/LeftSection';
import RightSection from './components/RightSection';
import { validateForm } from './validation';

const initialValues = {
  dacNumber: '',
  status: PICKUP_STATUS.PLANNED,
  collectionTime: new Date(),
  weight: 0,
  type: '',
  clientSignature: null,
  driverSignature: null,
  client: {
    contactName: '',
  },
  date: new Date(),
  comments: '',
  vehicle: {},
  isRefused: false,
};

function PickupForm() {
  const { id } = useParams();
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(null);
  const valuesBeforeUpdate = useRef(values);
  const { data: pickup, isLoading, error } = useGetPickup(id);
  const [updatePickup, { isLoading: isUpdating, isSuccess: isUpdated }] = useUpdatePickupMutation();
  // the client signature is checked or not
  const [isClientChecked, setIsClientChecked] = useState(false);
  // newContactName wont replace values.client.contactName.
  // it will be only be visible in the client signature BLOB
  const [isContactNameDeleted, setIsContactNameDeleted] = useState(false);
  const [newContactName, setNewContactName] = useState('');
  // the name that appears in the signing BLOB,
  // is used to verifiy that contact name and the signedwith are equal
  const [signedWith, setSignedWith] = useState('');

  useEffect(() => {
    if (id && pickup && !isLoading) {
      const valuesClone = cloneDeep(pickup);
      if (valuesClone.type) {
        valuesClone.type = String(valuesClone.type.id);
      }
      // set the date part of collectionTime as the pickup date
      valuesClone.collectionTime = new Date(
        valuesClone.collectionTime ? valuesClone.collectionTime : valuesClone.date,
      );
      setValues(valuesClone);
      valuesBeforeUpdate.current = valuesClone;
      valuesClone.clientSignature && setIsClientChecked(true);
    }
  }, [id, pickup, isLoading]);

  useEffect(() => {
    if (values.isRefused) {
      // when isRefused the backend will reset these values (isn't required to do this on the frontend)
      // it's just a feedback to the user
      setValues((prev) => ({ ...prev, weight: 0, clientSignature: null, collectionTime: null }));
      setIsClientChecked(false);
    }
  }, [values.isRefused]);

  const submit = () => {
    const formErrors = validateForm(
      values,
      isClientChecked,
      signedWith,
      newContactName,
      isContactNameDeleted,
    );
    if (formErrors !== true) {
      setErrors(formErrors);
    } else {
      setErrors({});
      updatePickup({
        ...pick(values, [
          'id',
          'weight',
          'type',
          'clientSignature',
          'driverSignature',
          'comments',
          'isRefused',
        ]),
        collectionTime: values.collectionTime
          ? `${format(new Date(values.date), 'yyyy-MM-dd')} ${format(
              values.collectionTime,
              'HH:mm',
            )}:00`
          : null,
      });
    }
  };

  const readOnly = useMemo(
    () =>
      values.status &&
      (values.status.codename === PICKUP_STATUS.DELIVERED ||
        values.status.codename === PICKUP_STATUS.CANCELLED),
    [values],
  );

  if (isLoading) {
    return (
      <Container fullHeight>
        <Loading />
      </Container>
    );
  } else if (error && error.response) {
    return (
      <Container fullHeight>
        <ErrorPage error={error} notFoundMessage="La collecte n'existe pas" />
      </Container>
    );
  }

  return (
    <Container>
      <Section spacing={2}>
        <LeftSection values={values} onChange={setValues} errors={errors} readOnly={readOnly} />
        <RightSection
          values={values}
          onChange={setValues}
          errors={errors}
          readOnly={readOnly}
          isClientChecked={isClientChecked}
          setIsClientChecked={setIsClientChecked}
          isContactNameDeleted={isContactNameDeleted}
          setIsContactNameDeleted={setIsContactNameDeleted}
          setSignedWith={setSignedWith}
          newContactName={newContactName}
          setNewContactName={setNewContactName}
        />
      </Section>
      <ButtonsContainer>
        <SubmitAndRedirectButton
          onClick={submit}
          isLoading={isUpdating}
          disabled={readOnly || isLoading || isUpdating}
          isSuccess={isUpdated}
          redirectPath={routes.circuit.root}
          isError={errors}
        />
        <ButtonWithLoading
          onClick={submit}
          disabled={readOnly || isLoading || isUpdating}
          isLoading={isUpdating}
        >
          Valider
        </ButtonWithLoading>
        <CancelButton redirectPath={routes.circuit.root} />
      </ButtonsContainer>
    </Container>
  );
}

export default PickupForm;
