import { useEffect } from 'react';

import JWT from 'services/jwt';
import { getRefreshToken, getToken } from 'services/userService';
import useRefreshTokenMutation from 'hooks/mutations/useRefreshTokenMutation';

/**
 * Hook that takes care of refreshing the token periodically
 * @param {Function} onSuccess Callback called with the new set of tokens when the refresh succeeds
 */
function useRefreshToken(onSuccess) {
  const [requestNewToken, { isIdle, reset }] = useRefreshTokenMutation();

  useEffect(() => {
    let timeoutId = null;
    const token = getToken();

    // If the token is defined and the refresh process is not running, schedule a refresh
    if (isIdle && token) {
      const refreshTimeout = new JWT(token).getRefreshTimeout();
      timeoutId = setTimeout(() => {
        const refreshToken = getRefreshToken();
        if (refreshToken) {
          requestNewToken(
            { refreshToken },
            {
              onSuccess: (response) => {
                // On success log in with this token => will refresh the user and save new tokens
                const { token: newToken, refreshToken: newRefreshToken } = response.data;
                onSuccess(newToken, newRefreshToken);
                reset();
              },
            },
          );
        }
      }, refreshTimeout);
    }

    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  });
}

export default useRefreshToken;
