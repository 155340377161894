import { useQuery } from 'react-query';

import { getAddress } from 'services/api/geoApi/addressApi';

function useGetAddress(address, city, zip, onAddressChange) {
  const searchAddress = address + ',' + city + ' ' + zip;

  return useQuery(['geoAddres', searchAddress], () => getAddress(searchAddress), {
    enabled: address?.length >= 2 && city.length >= 2 && zip?.length === 5,
    onSuccess: (data) => {
      const { features } = data;
      if (features?.length > 0) {
        const [long, lat] = features[0].geometry.coordinates;
        onAddressChange(lat, long);
      }
    },
  });
}
/**
 * return the list of adresses corresponding to entred address
 * @param {Function} setResults
 */
function useGetAddressesList(setResults) {
  return (address) => {
    getAddress(address).then((data) => {
      data && setResults(data.features);
    });
  };
}

export { useGetAddress, useGetAddressesList };
