import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getDay } from 'date-fns';
import { max, find, get } from 'lodash';

import { EditPickupDialog } from 'components';
import WeekCircuitTableBase from '../WeekCircuitTableBase';
import { useGetParameters } from 'hooks/queries/parametersQueries';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& > :nth-child(2)': {
      marginBottom: theme.spacing(2),
    },
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}));

function WeekCircuitTable({ values, firstDayOfSelectedPeriod }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const { data: parameters } = useGetParameters();
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const displayRows = useMemo(() => {
    let morningDisplayRows = [];
    let afternoonDisplayRows = [];
    let morningDepositsRow = [];
    let afternoonDepositsRow = [];
    if (values) {
      let pickupDays = [
        { morning: [], afternoon: [] },
        { morning: [], afternoon: [] },
        { morning: [], afternoon: [] },
        { morning: [], afternoon: [] },
        { morning: [], afternoon: [] },
      ];

      values.forEach((pickup) => {
        pickup.isAm
          ? pickupDays[getDay(new Date(pickup.date)) - 1].morning.push(pickup)
          : pickupDays[getDay(new Date(pickup.date)) - 1].afternoon.push(pickup);
      });

      // sort pickups in each day period by pickupOrder
      pickupDays = pickupDays.map((item) => {
        return {
          morning: item.morning.sort((a, b) => a.pickupOrder - b.pickupOrder),
          afternoon: item.afternoon.sort((a, b) => a.pickupOrder - b.pickupOrder),
        };
      });

      // get morning deposits
      morningDepositsRow = pickupDays.map((day) => {
        return day.morning[0] ? day.morning[0].deposit : null;
      });
      // get afternoon deposits
      afternoonDepositsRow = pickupDays.map((day) => {
        return day.afternoon[0] ? day.afternoon[0].deposit : null;
      });

      let morningMaxClients = pickupDays.reduce((maxClientNumber, b) => {
        return maxClientNumber < b.morning.length ? b.morning.length : maxClientNumber;
      }, 0);

      let afternoonMaxClients = pickupDays.reduce((maxClientNumber, b) => {
        return maxClientNumber < b.afternoon.length ? b.afternoon.length : maxClientNumber;
      }, 0);

      // display rows can be more than the amMaxClientNumber and pmMaxClientNumber
      // this possible when morning pickups are affected to afternoon deposit
      // (when morning and afternoon platfom deposit is the same and vehicle's capcity isn't reached)
      // so we pick the max between the real pickups number and maxclientNumber parameter,
      // otherwise some pickups won't be displayed
      for (
        let maxClient = 0;
        maxClient <
        max([
          morningMaxClients,
          get(find(parameters, ['name', 'amMaxClientNumber']), 'value', '3'),
        ]);
        maxClient++
      ) {
        let morningRow = [];
        for (let day = 0; day < 5; day++) {
          morningRow.push(pickupDays[day].morning[maxClient]);
        }
        morningDisplayRows.push(morningRow);
      }

      for (
        let maxClient = 0;
        maxClient <
        max([
          afternoonMaxClients,
          get(find(parameters, ['name', 'pmMaxClientNumber']), 'value', '3'),
        ]);
        maxClient++
      ) {
        let afternoonRow = [];
        for (let day = 0; day < 5; day++) {
          afternoonRow.push(pickupDays[day].afternoon[maxClient]);
        }
        afternoonDisplayRows.push(afternoonRow);
      }
    }
    return {
      morningRows: morningDisplayRows,
      afternoonRows: afternoonDisplayRows,
      morningDepositRow: morningDepositsRow,
      afternoonDepositRow: afternoonDepositsRow,
    };
  }, [values, parameters]);

  return (
    <div className={classes.container}>
      {values.length === 0 && (
        <Alert severity="warning" className={classes.alert}>
          Aucune collecte n'est planifiée cette semaine pour le véhicule choisi.
        </Alert>
      )}
      <div>
        <WeekCircuitTableBase
          isMorningCircuit={true}
          displayRows={displayRows.morningRows}
          depositList={displayRows.morningDepositRow}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setOpen={setOpen}
          mondayOfTheSelectedPeriod={firstDayOfSelectedPeriod}
        />
      </div>
      <div>
        <WeekCircuitTableBase
          isMorningCircuit={false}
          displayRows={displayRows.afternoonRows}
          depositList={displayRows.afternoonDepositRow}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setOpen={setOpen}
          mondayOfTheSelectedPeriod={firstDayOfSelectedPeriod}
        />
      </div>

      {open && (
        <EditPickupDialog
          open={open}
          onClose={() => setOpen(false)}
          selectedPickup={selectedItem.item}
          unSelectItem={() => setSelectedItem(null)}
        />
      )}
    </div>
  );
}

export default WeekCircuitTable;
