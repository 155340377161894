import React from 'react';
import { makeStyles, Grid, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { green } from 'theme/colors';
import { ClientSelector, MonthPickerWithArrows } from 'components';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  inlineContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    width: '300px',
  },
  marginRight: {
    marginRight: theme.spacing(2),
    marginBottom: '0px',
  },
  radioGroup: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
  },
}));

function Filter({ values, onChange }) {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" className={classes.container}>
      <Grid item sm={12} md={4} lg={4}>
        <div className={classes.inlineContainer}>
          <PersonIcon style={{ color: green }} className={classes.marginRight} />
          <ClientSelector
            value={values.selectedClient}
            onChange={(e, value) => onChange((prev) => ({ ...prev, selectedClient: value }))}
            style={{ flexGrow: 1 }}
            placeholder="Client"
          />
        </div>
      </Grid>
      <Grid item sm={12} md={4} lg={4}>
        <RadioGroup
          row
          value={values.selectedClientType}
          onChange={(e) => onChange((prev) => ({ ...prev, selectedClientType: e.target.value }))}
          className={classes.radioGroup}
        >
          <FormControlLabel value="collecte" label="Collecte" control={<Radio />} />
          <FormControlLabel value="facturation" label="Facturation" control={<Radio />} />
        </RadioGroup>
      </Grid>
      <Grid item sm={12} md={4} lg={4}>
        <MonthPickerWithArrows
          value={values.selectedDate}
          onChange={(date) => onChange((prev) => ({ ...prev, selectedDate: date }))}
          fullWidth={false}
        />
      </Grid>
    </Grid>
  );
}

export default Filter;
