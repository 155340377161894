import React from 'react';
import { Typography } from '@material-ui/core';

function ErrorHelper({ message, className }) {
  return (
    <Typography component="div" color="error" className={className}>
      {message}
    </Typography>
  );
}
export default ErrorHelper;
