import React from 'react';
import { TextField, Grid, InputAdornment } from '@material-ui/core';
import { Label, ErrorHelper } from 'components';
import { get, upperFirst } from 'lodash';

function DelayPlatform({ values, onChange, zonePlatformTimes, errors }) {
  const onDelayChange = (event, platformId) => {
    const { value: delay } = event.target;
    onChange(
      zonePlatformTimes.map((item) => {
        return item.platform.id === platformId ? { ...item, travelTime: delay } : item;
      }),
    );
  };

  return (
    <Grid item container>
      <Grid item xs={4}>
        <Label small>{upperFirst(values.name)}</Label>
      </Grid>
      <Grid item xs={8}>
        <TextField
          onChange={(e) => onDelayChange(e, values.id)}
          value={values.travelTime}
          fullWidth
          placeholder="Délai"
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">Min</InputAdornment>,
          }}
        />
        {get(errors, `platforms.${values.id}`) && (
          <ErrorHelper message={get(errors, `platforms.${values.id}`)} />
        )}
      </Grid>
    </Grid>
  );
}

export default DelayPlatform;
