import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';

import { updateDeposit as updateRequest } from 'services/api/terraLeoApi/depositApi';

function useUpdateDepositMutation() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(updateRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('La saisie du dépôt a bien été enregistrée.', {
        variant: 'success',
      });
    },
  });
}

export { useUpdateDepositMutation };
