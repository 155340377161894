import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import {
  TableRow,
  TableBody,
  TablePagination,
  TableContainer,
  Table,
  TableCell,
} from '@material-ui/core';

import { TableHead, Label } from 'components';

function descendingComparator(a, b, orderBy) {
  if (get(b, orderBy) < get(a, orderBy)) {
    return -1;
  }
  if (get(b, orderBy) > get(a, orderBy)) {
    return 1;
  }
  return 0;
}

/**
 * Return the correct sorting function according to the order.
 */
function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(value, comparator) {
  const stabilizedThis = value.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function TableGeneric({
  headCells,
  data,
  initialOrder = null,
  initialRowsPerPage = 20,
  renderRow,
  getRowKey,
  paginate,
  emptyDataText,
  isSortable,
  size = 'medium',
}) {
  const [values, setValues] = useState({
    order: 'asc',
    orderBy: initialOrder,
    page: 0,
    rowsPerPage: initialRowsPerPage,
  });

  useEffect(() => {
    if (!paginate) {
      setValues((prev) => ({ ...prev, rowsPerPage: data.length }));
    }
  }, [paginate, data]);

  useEffect(() => {
    setValues((prev) => ({ ...prev, page: 0 }));
  }, [data]);

  const handleRequestSort = (event, property) => {
    const isAsc = values.orderBy === property && values.order === 'asc';
    setValues((prev) => ({
      ...prev,
      orderBy: property,
      order: isAsc ? 'desc' : 'asc',
    }));
  };

  const displayedRows = stableSort(data, getSorting(values.order, values.orderBy)).slice(
    values.page * values.rowsPerPage,
    values.page * values.rowsPerPage + values.rowsPerPage,
  );

  return (
    <TableContainer>
      <Table size={size}>
        <TableHead
          headCells={headCells}
          order={values.order}
          orderBy={values.orderBy}
          onRequestSort={handleRequestSort}
          isSortable={isSortable}
        />
        <TableBody>
          {displayedRows.length > 0 ? (
            displayedRows.map((row, rowIndex) => (
              <React.Fragment key={getRowKey ? getRowKey(row) : `table-row-${rowIndex}`}>
                {renderRow(row, rowIndex)}
              </React.Fragment>
            ))
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={headCells.length}>
                <Label subLabel>{emptyDataText || 'Aucun élément'} </Label>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {paginate && (
        <TablePagination
          rowsPerPageOptions={[20, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={values.rowsPerPage}
          page={values.page}
          onChangePage={(event, newPage) => setValues((prev) => ({ ...prev, page: newPage }))}
          onChangeRowsPerPage={(event) =>
            setValues((prev) => ({
              ...prev,
              rowsPerPage: parseInt(event.target.value, 10),
              page: 0,
            }))
          }
        />
      )}
    </TableContainer>
  );
}

export default TableGeneric;
