const routes = {
  root: '/',
  login: {
    root: '/login',
    resetPassword: '/reset-password/:uuid',
  },
  home: {
    root: '/home',
  },
  clients: {
    root: '/clients',
    new: '/clients/new',
    edit: '/clients/edit/:id',
  },
  users: {
    root: '/users',
    new: '/users/new',
    edit: '/users/edit/:id',
  },
  vehicles: {
    root: '/vehicles',
    new: '/vehicles/new',
    edit: '/vehicles/edit/:id',
  },
  zone: {
    root: '/zone',
    new: '/zone/new',
    edit: '/zone/edit/:id',
  },
  platforms: {
    root: '/platforms',
    new: '/platforms/new',
    edit: '/platforms/edit/:id',
  },
  pickup: {
    root: '/pickup',
    new: '/pickup/new',
    edit: '/pickup/edit/:id',
  },
  recurrence: {
    root: '/recurrence',
    new: '/recurrence/add',
    edit: '/recurrence/edit/:id',
  },
  deposit: {
    root: '/deposit',
    new: '/deposit/edit/:id',
  },
  circuit: {
    root: '/circuit',
    new: '/circuit/new',
    edit: '/circuit/edit/:id',
  },
  weeklyFollowUp: {
    root: '/weekly-follow-up',
  },
  monthlyFollowUp: {
    root: '/monthly-follow-up',
  },
  administration: { root: '/administration' },
};

export default routes;
