import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { fr } from 'date-fns/locale';
import { format, getYear } from 'date-fns';
import { CSVLink } from 'react-csv';

import { Container, Loading, AlertDialog } from 'components';
import { useGetPickupsDateRange } from 'hooks/queries/pickupQueries';
import { useGetMonthlyFollowUp } from 'hooks/queries/followUpQueries';
import MonthlyFollowUpTable from './components/MonthlyFollowUpTable';
import Filter from './components/Filter';
import { getClosestDayInInterval } from 'services/dateUtils';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
}));

function MonthlyFollowUp() {
  const classes = useStyles();
  const [filter, setFilter] = useState({
    selectedClient: null,
    selectedDate: new Date(),
    selectedClientType: 'collecte',
  });
  const csvInstance = useRef(null);
  const [open, setOpen] = useState(false);
  const { data: monthlyFollowUp, isLoading } = useGetMonthlyFollowUp(
    filter.selectedDate,
    filter.selectedClientType,
  );
  const { data: pickupsDateRange } = useGetPickupsDateRange();
  const selectedMonthLabel = useMemo(
    () => `${format(filter.selectedDate, 'MMMM', { locale: fr })} ${getYear(filter.selectedDate)}`,
    [filter.selectedDate],
  );

  const displayRows = useMemo(() => {
    if (monthlyFollowUp) {
      return filter.selectedClient
        ? monthlyFollowUp.filter((item) => item.client.id === filter.selectedClient.id)
        : monthlyFollowUp;
    } else {
      return [];
    }
  }, [monthlyFollowUp, filter]);

  const csvData = useMemo(
    () => [
      [selectedMonthLabel, 'POIDS (en kg)'],
      ...displayRows.map((row) => {
        return [row.client.name, row.weight];
      }),
      ['TOTAL', displayRows.reduce((a, b) => a + b.weight, 0)],
    ],
    [displayRows, selectedMonthLabel],
  );

  const exportData = () => {
    if (!displayRows || (displayRows && displayRows.length === 0)) {
      setOpen(true);
    } else {
      csvInstance.current && csvInstance.current.link.click();
    }
  };

  useEffect(() => {
    if (pickupsDateRange && pickupsDateRange[0]) {
      const { min: minDate, max: maxDate } = pickupsDateRange[0];
      setFilter((prev) => ({ ...prev, selectedDate: getClosestDayInInterval(minDate, maxDate) }));
    }
  }, [pickupsDateRange]);

  return (
    <Container fullHeight>
      <Filter values={filter} onChange={setFilter} />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <MonthlyFollowUpTable
            values={displayRows}
            selectedMonthLabel={selectedMonthLabel}
            month={format(filter.selectedDate, 'MM')}
            year={format(filter.selectedDate, 'yyyy')}
          />
          <div className={classes.buttonContainer}>
            <Button
              startIcon={<DescriptionIcon />}
              color="primary"
              onClick={exportData}
              disabled={displayRows.length === 0}
            >
              Exporter mois
            </Button>
            <CSVLink
              data={csvData}
              ref={csvInstance}
              separator={';'}
              filename={`Suivi mensuel ${selectedMonthLabel}.csv`}
            />
          </div>
        </>
      )}
      <AlertDialog
        message="Aucun objet n’est exportable sur la période sélectionnée"
        title="Information"
        open={open}
        onClose={() => setOpen(false)}
      />
    </Container>
  );
}

export default MonthlyFollowUp;
