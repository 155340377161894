import terraLeoApiClient from 'services/api/terraLeoApi/terraLeoApiClient';

import { BACKEND_ZONES_ENDPOINTS } from '../constants';

async function getZones() {
  const response = await terraLeoApiClient.getRequest(BACKEND_ZONES_ENDPOINTS.LIST_ZONES);
  return response.data;
}

async function getZone(id) {
  const response = await terraLeoApiClient.getRequest(BACKEND_ZONES_ENDPOINTS.GET_ZONE(id));
  return response.data;
}

async function addZone(zone) {
  const response = await terraLeoApiClient.postRequest(BACKEND_ZONES_ENDPOINTS.ADD_ZONE, zone);
  return response.data;
}

async function updateZone(zone) {
  const response = await terraLeoApiClient.putRequest(
    BACKEND_ZONES_ENDPOINTS.EDIT_ZONE(zone.id),
    zone,
  );
  return response.data;
}

async function deleteZone(id) {
  const response = await terraLeoApiClient.patchRequest(BACKEND_ZONES_ENDPOINTS.DELETE_ZONE(id), {
    isActive: false,
  });
  return response.data;
}

export { getZones, getZone, addZone, updateZone, deleteZone };
