import makeStyles from '@material-ui/styles/makeStyles';
import { hoverGrey, DarkGray } from 'theme/colors';
export default makeStyles((theme) => ({
  label: {
    color: hoverGrey,
    fontSize: '18px',
    fontWeight: 'bold',
    margin: theme.spacing(0.5, 0, 1, 0),
  },
  labelSmall: {
    color: hoverGrey,
    fontSize: '14px',
  },
  subLabel: {
    fontWeight: 'bold',
    color: DarkGray,
    fontSize: '14px',
    marginBottom: theme.spacing(0.5),
  },
}));
