import { makeStyles } from '@material-ui/core/styles';
import { green, darkGreen, hoverYellow } from 'theme/colors';

export default makeStyles((theme) => ({
  tableTitle: {
    backgroundColor: 'white',
    color: 'black',
    fontWeight: 'bold',
  },
  platformTitle: {
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: green,
  },
  weekTitle: {
    fontWeight: 'bold',
    backgroundColor: darkGreen,
    color: 'white',
  },
  selected: {
    backgroundColor: hoverYellow,
  },
  headCell: {
    fontSize: 12,
  },
  clientCell: {
    maxWidth: '150px',
  },
  tableRow: {
    height: '43px',
  },
}));
