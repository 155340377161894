import { omitBy } from 'lodash';
import { requiredValidDate, positiveAndNonNull } from 'services/validationUtils';

export const validateForm = (values) => {
  const validations = {
    duration: positiveAndNonNull(values.duration),
    startDate: requiredValidDate(values.startDate),
  };
  const errors = omitBy(validations, (validation) => validation === true);

  return Object.keys(errors).length > 0 ? errors : true;
};
