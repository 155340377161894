import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ButtonWithLoading } from 'components';

const DEFAULT_LABEL = 'Valider et retourner';

/**
 * Apply button that sumbit action and then redirects to a path.
 */
function SubmitAndRedirectButton({
  children = DEFAULT_LABEL,
  onClick,
  isSuccess,
  redirectPath,
  isLoading,
  isError,
  ...other
}) {
  // to prevent "valider" button from redirecting page
  const [isClicked, setIsClicked] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (isSuccess && isClicked) {
      history.push(redirectPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isClicked]);

  useEffect(() => {
    if (!isEmpty(isError) && isClicked) {
      setIsClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <>
      <ButtonWithLoading
        {...other}
        isLoading={isLoading}
        onClick={() => {
          setIsClicked(true);
          onClick();
        }}
      >
        {children}
      </ButtonWithLoading>
    </>
  );
}

SubmitAndRedirectButton.propTypes = {
  /** Button children, e.g. button label */
  children: PropTypes.string,
  /** Path where the button should redirect in success*/
  redirectPath: PropTypes.string.isRequired,
};

export default SubmitAndRedirectButton;
