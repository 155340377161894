import { useQuery } from 'react-query';

import { getDeposit, getDepositPickups } from 'services/api/terraLeoApi/depositApi';

function useGetDeposit(id) {
  return useQuery(['deposit', id], () => getDeposit(id), { enabled: id });
}
function useGetDepositPickups(id) {
  return useQuery(['depositPickups', id], () => getDepositPickups(id), { enabled: id });
}

export { useGetDeposit, useGetDepositPickups };
