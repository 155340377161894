import React from 'react';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';

function DayPeriodInput({ value, onChange, disabled }) {
  return (
    <RadioGroup value={String(value)} onChange={(e) => onChange(e.target.value === 'true')} row>
      <FormControlLabel value={'true'} control={<Radio />} label="Matin" disabled={disabled} />
      <FormControlLabel
        value={'false'}
        control={<Radio />}
        label="Après midi"
        disabled={disabled}
      />
    </RadioGroup>
  );
}

export default DayPeriodInput;
