import terraLeoApiClient from 'services/api/terraLeoApi/terraLeoApiClient';

import { BACKEND_PARAMETERS_ENDPOINTS } from '../constants';

async function getParameters() {
  const response = await terraLeoApiClient.getRequest(BACKEND_PARAMETERS_ENDPOINTS.GET_PARAMS);
  return response.data;
}

// update only one parameter
async function updateParameters(parameter) {
  const response = await terraLeoApiClient.putRequest(
    BACKEND_PARAMETERS_ENDPOINTS.PUT_PARAMS(parameter.id),
    parameter,
  );
  return response.data;
}

export { getParameters, updateParameters };
