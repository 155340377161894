import React, { useState } from 'react';
import { makeStyles, Checkbox, Grid } from '@material-ui/core';
import clsx from 'clsx';

import { DarkGray } from 'theme/colors';
import { ErrorHelper } from 'components';
import SignatureModal from './SignatureModal';

const useStyles = makeStyles(() => ({
  signatureContainer: {
    border: `solid black 2px`,
    height: '100px',
    width: '100%',
    cursor: 'pointer',
  },
  disabled: {
    cursor: 'default  ',
    backgroundColor: DarkGray,
  },
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
}));

function Signature({
  contactName,
  isChecked,
  setIsChecked,
  signature,
  setSignature,
  required,
  setSignedWith,
  isRefused,
  readOnly,
  error,
}) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const onConfirm = (signatureBLOB) => {
    setSignature(signatureBLOB);
    setOpen(false);
  };

  return (
    <Grid container>
      <Grid item xs={2}>
        {!required && (
          <div>
            <Checkbox
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
              disabled={isRefused || readOnly}
            />
          </div>
        )}
      </Grid>
      <Grid item xs={10}>
        <div
          className={clsx({
            [classes.signatureContainer]: true,
            [classes.disabled]: isChecked === false || !contactName || readOnly,
          })}
          onClick={() =>
            (required || isChecked) && contactName !== '' && !readOnly ? setOpen(true) : null
          }
        >
          {signature && <img src={signature} alt="signature" className={classes.img} />}
        </div>
        {error && <ErrorHelper message={error} />}

        <SignatureModal
          open={open}
          onConfirm={onConfirm}
          onClose={() => setOpen(false)}
          signature={signature}
          name={contactName}
          setSignedWith={setSignedWith}
        />
      </Grid>
    </Grid>
  );
}
export default Signature;
