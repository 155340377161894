import React from 'react';
import ErrorPageBase from './ErrorPageBase';

function ErrorPage({ error, notFoundMessage }) {
  const { status } = error.response;

  return (
    <>
      {status === 404 ? (
        <ErrorPageBase
          message={notFoundMessage || "L'élement que vous cherchez n'existe pas."}
          errorCode={status}
        />
      ) : status === 500 ? (
        <ErrorPageBase message="Erreur interne au serveur." errorCode={status} />
      ) : (
        <ErrorPageBase errorCode={status} />
      )}
    </>
  );
}
export default ErrorPage;
