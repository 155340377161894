import React, { useState } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import clsx from 'clsx';

import { OnlyPrivateRoute } from 'components';
import routes from 'config/routes';
import Layout from './Layout';
import Dashboard from './Dashboard';
import Users from './Users';
import UserForm from './Users/UserForm';
import ClientForm from './Client/ClientForm';
import Clients from './Client';
import PlatformForm from './Plateform/PlatformForm';
import Platforms from './Plateform';
import Vehicles from './Vehicle';
import VehicleForm from './Vehicle/VehicleForm';
import ZoneForm from './Zone/ZoneForm';
import Zones from './Zone';
import Circuit from './Circuit';
import Recurrence from './Recurrence';
import RecurrenceForm from './Recurrence/RecurrenceForm';
import PickupForm from './Circuit/PickupForm';
import DepositForm from './Circuit/DepositForm';
import WeeklyFollowUp from './WeeklyFollowUp';
import MonthlyFollowUp from './MonthlyFollowUp';
import Parameter from './Parameter';
import useStyles from './styles';

function SecuredArea() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Layout open={open} setOpen={setOpen} />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <Switch>
          <OnlyPrivateRoute path={routes.root} exact>
            <Redirect to={routes.home.root} />
          </OnlyPrivateRoute>
          <OnlyPrivateRoute path={routes.users.root} exact>
            <Users />
          </OnlyPrivateRoute>
          <OnlyPrivateRoute path={[routes.users.new, routes.users.edit]} exact>
            <UserForm />
          </OnlyPrivateRoute>
          <OnlyPrivateRoute path={routes.clients.root} exact>
            <Clients />
          </OnlyPrivateRoute>
          <OnlyPrivateRoute path={[routes.clients.new, routes.clients.edit]} exact>
            <ClientForm />
          </OnlyPrivateRoute>
          <OnlyPrivateRoute path={routes.platforms.root} exact>
            <Platforms />
          </OnlyPrivateRoute>
          <OnlyPrivateRoute path={[routes.platforms.edit, routes.platforms.new]} exact>
            <PlatformForm />
          </OnlyPrivateRoute>
          <OnlyPrivateRoute path={routes.vehicles.root} exact>
            <Vehicles />
          </OnlyPrivateRoute>
          <OnlyPrivateRoute path={[routes.vehicles.edit, routes.vehicles.new]} exact>
            <VehicleForm />
          </OnlyPrivateRoute>
          <OnlyPrivateRoute path={[routes.zone.edit, routes.zone.new]} exact>
            <ZoneForm />
          </OnlyPrivateRoute>
          <OnlyPrivateRoute path={routes.zone.root} exact>
            <Zones />
          </OnlyPrivateRoute>
          <OnlyPrivateRoute path={routes.circuit.root} exact>
            <Circuit />
          </OnlyPrivateRoute>
          <OnlyPrivateRoute path={routes.recurrence.root} exact>
            <Recurrence />
          </OnlyPrivateRoute>
          <OnlyPrivateRoute path={[routes.recurrence.edit, routes.recurrence.new]}>
            <RecurrenceForm />
          </OnlyPrivateRoute>
          <OnlyPrivateRoute path={routes.pickup.edit} exact>
            <PickupForm />
          </OnlyPrivateRoute>
          <OnlyPrivateRoute path={routes.weeklyFollowUp.root} exact>
            <WeeklyFollowUp />
          </OnlyPrivateRoute>
          <OnlyPrivateRoute path={routes.monthlyFollowUp.root} exact>
            <MonthlyFollowUp />
          </OnlyPrivateRoute>
          <OnlyPrivateRoute path={routes.deposit.new} exact>
            <DepositForm />
          </OnlyPrivateRoute>
          <OnlyPrivateRoute path={routes.administration.root} exact>
            <Parameter />
          </OnlyPrivateRoute>
          <OnlyPrivateRoute path={routes.home.root}>
            <Dashboard />
          </OnlyPrivateRoute>
        </Switch>
      </div>
    </>
  );
}

export default SecuredArea;
