import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { useGetAddress } from 'hooks/queries/geoApiQueries';
import {
  openingDatesToHourString,
  openingHourStringToDates,
  getOpeningHoursChanges,
} from 'services/openingHoursService';
import {
  Container,
  Section,
  ButtonsContainer,
  ButtonWithLoading,
  ErrorPage,
  Loading,
  CancelButton,
  SubmitAndRedirectButton,
} from 'components';
import {
  useAddPlatformMutation,
  useUpdatePlatformMutation,
} from 'hooks/mutations/platformMutations';
import {
  useAddPlatformHoursMutation,
  useDeletePlatformHoursMutation,
  useUpdatePlatformHoursMutation,
} from 'hooks/mutations/platformHoursMutations';
import { useGetPlatformHours } from 'hooks/queries/platformHoursQueries';
import { useGetPlatform } from 'hooks/queries/platformQueries';
import { routes } from 'config';
import LeftSection from './component/LeftSection';
import RightSection from './component/RightSection';
import { validateForm } from './validation';

const initialValues = {
  name: '',
  address: '',
  additionalAddress: '',
  zip: '',
  city: '',
  contactName: '',
  phone: '',
  email: '',
  latitude: '',
  longitude: '',
  agreementNumber: '',
  siret: '',
  isActive: true,
  type: '',
  comments: '',
};

function PlatformForm() {
  // platform id it's edit form
  const { id } = useParams();
  // values contains only the platform attributes, without platform hours
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(null);
  const valuesBeforeUpdate = useRef(values);
  const [
    addPlatform,
    { isLoading: isAdding, isSuccess: isAdded, data: addedPlatform },
  ] = useAddPlatformMutation(setErrors);
  const [
    updatePlatform,
    { isLoading: isUpdating, isSuccess: isUpdated },
  ] = useUpdatePlatformMutation(setErrors);
  const { data: platform, isLoading, error } = useGetPlatform(id);
  // platform hours
  const [timeTable, setTimeTable] = useState({});
  const timeTableBeforeUpdate = useRef(timeTable);
  const { data: platformHours, isLoading: isLoadingHours } = useGetPlatformHours(id);
  const [addPlatformHours] = useAddPlatformHoursMutation();
  const [updatePlatformHours] = useUpdatePlatformHoursMutation();
  const [deletePlatformHours] = useDeletePlatformHoursMutation();
  // geoAPi
  useGetAddress(values.address, values.city, values.zip, (lat, long) => {
    setValues((prev) => ({ ...prev, latitude: lat, longitude: long }));
  });

  useEffect(() => {
    if (id && platform && !isLoading) {
      setValues(platform);
      valuesBeforeUpdate.current = platform;
    }
  }, [id, platform, isLoading]);

  useEffect(() => {
    if (id && platformHours && !isLoadingHours) {
      const convertedPlatformHours = openingHourStringToDates(platformHours);
      setTimeTable(convertedPlatformHours);
      timeTableBeforeUpdate.current = convertedPlatformHours;
    }
  }, [id, platformHours, isLoadingHours]);

  const isTimeTableChanged = useMemo(
    () => JSON.stringify(timeTableBeforeUpdate.current) !== JSON.stringify(timeTable),
    [timeTable],
  );

  useEffect(() => {
    // reset form and add platform hours when the platform is successfully added
    if (isAdded && addedPlatform) {
      if (isTimeTableChanged) {
        addPlatformHours({
          id: addedPlatform.id,
          platformHours: openingDatesToHourString(timeTable),
        });
        // reset timeTable
        setTimeTable({});
      }
      setValues(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdded, addedPlatform]);

  const submit = () => {
    const formErrors = validateForm(values);
    if (formErrors !== true) {
      setErrors(formErrors);
    } else {
      setErrors({});
      const valuesClone = { ...values };
      if (values.type) {
        valuesClone.type = values.type.id;
      }
      if (values.phone) {
        valuesClone.phone = removePhoneEmptySpace();
      }
      if (id) {
        updatePlatform(valuesClone);
        // update platform hours if it has been modified
        if (isTimeTableChanged) {
          const [
            newPlatformHours,
            modifiedPlatformHours,
            deletedPlatformHoursIds,
          ] = getOpeningHoursChanges(timeTable, timeTableBeforeUpdate.current);
          // call the API to add, update, delete platformHours
          deletedPlatformHoursIds.length > 0 &&
            deletePlatformHours({ id, platformHoursIdArray: deletedPlatformHoursIds });
          !isEmpty(modifiedPlatformHours) &&
            updatePlatformHours({ id, platformHours: modifiedPlatformHours });
          !isEmpty(newPlatformHours) && addPlatformHours({ id, platformHours: newPlatformHours });
        }
      } else {
        addPlatform(valuesClone);
      }
    }
  };

  // before update or add, should remove the extra space in phone number
  // added by the mask, otherwise backend will reply with BAD REQUEST
  const removePhoneEmptySpace = () => {
    return values.phone.replace(/\D/g, '');
  };

  if (isLoading) {
    return (
      <Container fullHeight>
        <Loading />
      </Container>
    );
  } else if (error && error.response) {
    return (
      <Container fullHeight>
        <ErrorPage error={error} notFoundMessage="La plateforme n'existe pas" />
      </Container>
    );
  }

  return (
    <Container>
      <Section spacing={2}>
        <LeftSection values={values} onChange={setValues} errors={errors} isEditMode={id} />
        <RightSection
          values={values}
          onChange={setValues}
          timeTable={timeTable}
          setTimeTable={setTimeTable}
          errors={errors}
        />
      </Section>
      <ButtonsContainer>
        <SubmitAndRedirectButton
          onClick={submit}
          isLoading={isAdding || isUpdating}
          isSuccess={isAdded || isUpdated}
          redirectPath={routes.platforms.root}
          isError={errors}
        />
        <ButtonWithLoading isLoading={isAdding || isUpdating} onClick={submit}>
          Valider
        </ButtonWithLoading>
        <CancelButton redirectPath={routes.platforms.root} />
      </ButtonsContainer>
    </Container>
  );
}

export default PlatformForm;
