import React from 'react';
import { Paper, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    height: ({ fullHeight }) => (fullHeight ? '100%' : ''),
    flexGrow: 1,
  },
}));

function Container({ children, fullHeight = false, className }) {
  const classes = useStyles({ fullHeight });
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {isPhone ? (
        <div>{children}</div>
      ) : (
        <Paper elevation={3} className={clsx(classes.root, className)}>
          {children}
        </Paper>
      )}
    </>
  );
}

export default Container;
