import { omitBy } from 'lodash';
import {
  nonNullAndLessThan,
  requiredNotEmptyString,
  requiredValue,
} from 'services/validationUtils';

export const validateForm = (
  values,
  isClientChecked,
  signedWith,
  newContactName,
  isContactNameDeleted,
) => {
  const validations = {
    collectionTime: values.isRefused ? true : requiredValue(values.collectionTime),
    weight: values.isRefused ? true : nonNullAndLessThan(values.weight, 5000),
    clientSignature: validateClientSignature(
      values.clientSignature,
      isClientChecked,
      signedWith,
      newContactName,
      isContactNameDeleted,
    ),
    driverSignature: validateDriverSignature(values.driverSignature, values.isRefused),
    comments: values.isRefused ? requiredNotEmptyString(values.comments) : true,
    type: values.isRefused ? true : requiredValue(values.type),
  };
  const errors = omitBy(validations, (validation) => validation === true);

  return Object.keys(errors).length > 0 ? errors : true;
};

const validateClientSignature = (
  signature,
  isClientChecked,
  signedWith,
  newContactName,
  isContactNameDeleted,
) => {
  if (isClientChecked) {
    if (!signature) {
      return 'La signature est vide';
    }
    if (signedWith !== newContactName && isContactNameDeleted) {
      return 'Le nom de la personne signée a changé';
    }
    return true;
  }
  return true;
};

const validateDriverSignature = (signature, isRefused) => {
  if (!signature) {
    return 'Signature obligatoire';
  }
  return true;
};
