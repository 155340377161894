import React from 'react';
import { Typography, Grid, IconButton } from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { format } from 'date-fns';
import uuidv4 from 'uuid/v4';

import { numberToDay } from 'services/dateUtils';
import { iconRed } from 'theme/colors';

function OpeningDateList({ values, onChange }) {
  const deleteTimePeriod = (dayNumber) => {
    const newValues = { ...values };
    delete newValues[dayNumber];
    onChange(newValues);
  };

  const displayMorning = (day) => {
    return format(new Date(day.am[0]), 'HH:mm') + ' - ' + format(new Date(day.am[1]), 'HH:mm');
  };

  const displayAfternoon = (day) => {
    // when is a full day without stop, nothing to show

    if (
      format(day.am[0], 'HH:mm') === format(day.pm[0], 'HH:mm') &&
      format(day.am[1], 'HH:mm') === format(day.pm[1], 'HH:mm')
    ) {
      return '';
    } else {
      return format(new Date(day.pm[0]), 'HH:mm') + ' - ' + format(new Date(day.pm[1]), 'HH:mm');
    }
  };

  return (
    <Grid container>
      {Object.keys(values).map((key, index) => (
        <Grid key={uuidv4()} item container alignItems="center" justify="space-between">
          <Grid item xs={3}>
            <Typography component="span">{numberToDay(key)}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography component="span">{displayMorning(values[key])}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography component="span">{displayAfternoon(values[key])}</Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => deleteTimePeriod(key)} style={{ color: iconRed }}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
export default OpeningDateList;
