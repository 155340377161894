import React from 'react';
import { TextField } from '@material-ui/core';

function ZipInput({ value, onChange, ...props }) {
  return (
    <TextField
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Code postal"
      inputProps={{
        maxLength: 5,
      }}
      {...props}
    />
  );
}

export default ZipInput;
