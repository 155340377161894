import terraLeoApiClient from 'services/api/terraLeoApi/terraLeoApiClient';

import { BACKEND_RECURRENCES_ENDPOINTS } from '../constants';

async function getRecurrences() {
  const response = await terraLeoApiClient.getRequest(
    BACKEND_RECURRENCES_ENDPOINTS.LIST_RECURRENCES,
  );
  return response.data;
}

async function getRecurrence(id) {
  const response = await terraLeoApiClient.getRequest(
    BACKEND_RECURRENCES_ENDPOINTS.GET_RECURRENCE(id),
  );
  return response.data;
}

async function addRecurrence(recurrence) {
  const response = await terraLeoApiClient.postRequest(
    BACKEND_RECURRENCES_ENDPOINTS.ADD_RECURRENCE,
    recurrence,
  );
  return response.data;
}

async function updateRecurrence(recurrence) {
  const response = await terraLeoApiClient.putRequest(
    BACKEND_RECURRENCES_ENDPOINTS.EDIT_RECURRENCE(recurrence.id),
    recurrence,
  );
  return response.data;
}

export { getRecurrences, getRecurrence, addRecurrence, updateRecurrence };
