import React from 'react';
import { Grid } from '@material-ui/core';

import { FormGroup } from 'components';
import DelayPlatformsEditor from '../DelayPlatformsEditor';

function LeftSection({ values, zoneId, onChange, errors }) {
  return (
    <FormGroup.Right sm={12} lg={6}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DelayPlatformsEditor
            values={values}
            onChangeZonePlatformTimes={(platformsDelay) => onChange(platformsDelay)}
            zoneId={zoneId}
            errors={errors}
          />
        </Grid>
      </Grid>
    </FormGroup.Right>
  );
}

export default LeftSection;
