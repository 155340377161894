import React from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import useStyles from './styles';

function Label({ className, subLabel = false, small = false, ...other }) {
  const classes = useStyles();
  return (
    <Typography
      className={clsx(className, {
        [classes.label]: !subLabel,
        [classes.labelSmall]: small,
        [classes.subLabel]: subLabel,
      })}
      {...other}
    />
  );
}

export default Label;
