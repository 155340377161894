import React from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import clsx from 'clsx';
import {
  addDays,
  startOfWeek,
  isAfter,
  isSameDay,
  isSameMonth,
  isSameYear,
  differenceInDays,
} from 'date-fns';
import { WeekPicker } from 'components';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  fullWidth: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
  },
}));

const sameDatePart = (date1, date2) =>
  isSameDay(date1, date2) && isSameMonth(date1, date2) && isSameYear(date1, date2);

/**
 * this component allow week navigation using arrows
 * props.minDate and props.maxDate are the min start week and max start week of pickups in the database
 */
function WeekPickerWithArrows({ value, onChange, isReadOnly, fullWidth = true, ...props }) {
  const classes = useStyles();

  const prevWeek = startOfWeek(addDays(value, -7), { weekStartsOn: 1 });
  const nextWeek = startOfWeek(addDays(value, 7), { weekStartsOn: 1 });

  return (
    <div className={clsx({ [classes.container]: true, [classes.fullWidth]: fullWidth })}>
      <IconButton
        disabled={
          !value ||
          isReadOnly ||
          differenceInDays(prevWeek, props.minDate) < -4 || // Monday is more than 4 days ahead of minDay (4 days = monday to friday)
          props.disabled
        }
        onClick={() => onChange(prevWeek)}
      >
        <ArrowBackIcon />
      </IconButton>
      <WeekPicker
        value={value}
        onChange={(date) => onChange(date)}
        isReadOnly={isReadOnly}
        {...props}
      />
      <IconButton
        disabled={
          !value ||
          isReadOnly ||
          isAfter(nextWeek, props.maxDate) ||
          sameDatePart(nextWeek, props.maxDate) ||
          props.disabled
        }
        onClick={() => onChange(nextWeek)}
      >
        <ArrowForwardIcon />
      </IconButton>
    </div>
  );
}

export default WeekPickerWithArrows;
