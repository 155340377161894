import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Label } from 'components';

import {
  FormGroup,
  OpeningDateInput,
  ErrorHelper,
  BillableClientSelector,
  PlatformSelector,
} from 'components';

function LeftSection({ values, onChange, isBillable, timeTable, setTimeTable, errors }) {
  return (
    <FormGroup.Right sm={12} md={6} lg={6}>
      <Grid container spacing={2}>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Plateforme</Label>
          </Grid>
          <Grid item xs>
            <PlatformSelector
              value={values.platform}
              onChange={(e, value) => onChange((prev) => ({ ...prev, platform: value }))}
              fullWidth
            />
            {errors?.platform && <ErrorHelper message={errors.platform} />}
          </Grid>
        </Grid>

        <Grid item container alignItems="baseline">
          <Grid item xs={4}>
            <Label small>Horaire</Label>
          </Grid>
          <Grid item xs={8}>
            <OpeningDateInput value={timeTable} onChange={setTimeTable} />
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item xs={4}>
            <Label small>Quantité déchet/jour</Label>
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              type="number"
              value={values.dailyWasteAmount}
              onChange={(e) => onChange((prev) => ({ ...prev, dailyWasteAmount: e.target.value }))}
              placeholder="Quantité déchet/jour"
            />
            {errors?.dailyWasteAmount && <ErrorHelper message={errors.dailyWasteAmount} />}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>N° Agrément</Label>
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              value={values.agreementNumber}
              onChange={(e) => onChange((prev) => ({ ...prev, agreementNumber: e.target.value }))}
              placeholder="N° Agrément"
            />
          </Grid>
        </Grid>
        {!isBillable && (
          <Grid item container>
            <Grid item xs={4}>
              <Label small>Client de facturation</Label>
            </Grid>
            <Grid item xs>
              <BillableClientSelector
                value={values.billable}
                onChange={(e, value) => onChange((prev) => ({ ...prev, billable: value }))}
              />
              {errors?.billable && <ErrorHelper message={errors.billable} />}
            </Grid>
          </Grid>
        )}
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Commentaire</Label>
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              value={values.comments}
              onChange={(e) => onChange((prev) => ({ ...prev, comments: e.target.value }))}
              placeholder="Commentaire"
              rows={4}
              multiline
            />
          </Grid>
        </Grid>
      </Grid>
    </FormGroup.Right>
  );
}

export default LeftSection;
