import { useQuery } from 'react-query';

import { getVehicle, getVehicles, getVehiclesTypes } from 'services/api/terraLeoApi/vehicleApi';

function useGetVehiclesTypes() {
  // vehiclesTypes are considered fresh for 1h then will be fetched again
  return useQuery('vehiclesTypes', getVehiclesTypes, { staleTime: 3600000 });
}

function useGetVehicle(id) {
  return useQuery(['vehicle', id], () => getVehicle(id), { enabled: id });
}

function useGetVehicles() {
  return useQuery('vehicles', getVehicles);
}

export { useGetVehiclesTypes, useGetVehicle, useGetVehicles };
