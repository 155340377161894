/**
 * Read `process.env` variables. If not present, throw an error if required or provide a default value.
 * @param {string} name Name of the env variable
 * @param {Boolean} [required=false] Indicate if the variable is required - a missing required variable will throw an error
 * @param {any} fallback A fallback value in case the variable is not required and is missing
 * @return {any} The variable value or the fallback value
 * @throws If the variable is required and missing
 */
function readEnv(name, required = false, fallback = null) {
  if (process.env[name]) {
    return process.env[name];
  }

  if (required) {
    throw new Error(`Required environment variable "${name}" not found`);
  }

  return fallback;
}

const env = {
  build: readEnv('REACT_APP_BUILD', true),
  version: readEnv('REACT_APP_VERSION', true),
  terraLeoApiBaseUrl: readEnv('REACT_APP_TERRA_LEO_API_BASE_URL', true),
};

export default env;
