import React from 'react';
import { Grid, TextField, Checkbox, FormControlLabel } from '@material-ui/core';

import { FormGroup, ErrorHelper, Label, AddressWithSuggestion } from 'components';

function LeftSection({ values, onChange, errors, isEditMode }) {
  return (
    <FormGroup.Left sm={12} lg={6}>
      <Grid container spacing={2}>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Désignation</Label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              value={values.name}
              onChange={(e) => onChange((prev) => ({ ...prev, name: e.target.value }))}
              fullWidth
              placeholder="Désignation"
            />
            {errors?.name && <ErrorHelper message={errors.name} />}
          </Grid>
        </Grid>
        {isEditMode && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.isActive}
                  onChange={(e) => onChange((prev) => ({ ...prev, isActive: e.target.checked }))}
                  name="Actif"
                />
              }
              label="Actif"
            />
          </Grid>
        )}
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Commentaire</Label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              value={values.comments}
              onChange={(e) => onChange((prev) => ({ ...prev, comments: e.target.value }))}
              fullWidth
              multiline
              rowsMax={3}
              rows={3}
              placeholder="Commentaire"
            />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>N° et rue</Label>
          </Grid>
          <Grid item xs>
            <AddressWithSuggestion
              value={values.startAddress}
              onChange={(value) => onChange((prev) => ({ ...prev, startAddress: value }))}
              onAutoComplete={(value) =>
                onChange((prev) => ({
                  ...prev,
                  startZip: value.zip,
                  startCity: value.city,
                  latitude: value.latitude,
                  longitude: value.longitude,
                }))
              }
            />
            {errors?.address && <ErrorHelper message={errors.address} />}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Complément</Label>
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              value={values.startAdditionalAddress}
              onChange={(e) =>
                onChange((prev) => ({ ...prev, startAdditionalAddress: e.target.value }))
              }
              placeholder="Complément"
            />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Code postal</Label>
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              value={values.startZip}
              onChange={(e) => onChange((prev) => ({ ...prev, startZip: e.target.value }))}
              placeholder="Code postal"
            />
            {errors?.zip && <ErrorHelper message={errors.zip} />}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Ville</Label>
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              value={values.startCity}
              onChange={(e) => onChange((prev) => ({ ...prev, startCity: e.target.value }))}
              placeholder="Ville"
            />
            {errors?.city && <ErrorHelper message={errors.city} />}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Label small>Coordonnées</Label>
          </Grid>
          <Grid container item xs={8} justify="space-between">
            <Grid item xs={5}>
              <TextField
                placeholder="Latitude"
                value={values.latitude}
                onChange={(e) => onChange((prev) => ({ ...prev, latitude: e.target.value }))}
                fullWidth
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                placeholder="Longtitude"
                value={values.longitude}
                onChange={(e) => onChange((prev) => ({ ...prev, longitude: e.target.value }))}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormGroup.Left>
  );
}

export default LeftSection;
