import terraLeoApiClient from 'services/api/terraLeoApi/terraLeoApiClient';

import { BACKEND_PLATFORMS_ENDPOINTS } from '../constants';

async function getPlatforms() {
  const response = await terraLeoApiClient.getRequest(BACKEND_PLATFORMS_ENDPOINTS.LIST_PLATEFORMS);
  return response.data;
}

async function getPlatform(id) {
  const response = await terraLeoApiClient.getRequest(BACKEND_PLATFORMS_ENDPOINTS.GET_PLATFORM(id));
  return response.data;
}

async function getPlatformsTypes() {
  const response = await terraLeoApiClient.getRequest(
    BACKEND_PLATFORMS_ENDPOINTS.LIST_PLATFORMS_TYPES,
  );
  return response.data;
}

async function addPlatform(platform) {
  const response = await terraLeoApiClient.postRequest(
    BACKEND_PLATFORMS_ENDPOINTS.ADD_PLATFORM,
    platform,
  );
  return response.data;
}

async function updatePlatform(platform) {
  const response = await terraLeoApiClient.putRequest(
    BACKEND_PLATFORMS_ENDPOINTS.EDIT_PLATFORM(platform.id),
    platform,
  );
  return response;
}

async function deletePlatform(id) {
  const response = await terraLeoApiClient.patchRequest(
    BACKEND_PLATFORMS_ENDPOINTS.DELETE_PLATFORM(id),
    { isActive: false },
  );
  return response.data;
}

export {
  getPlatforms,
  getPlatform,
  getPlatformsTypes,
  addPlatform,
  updatePlatform,
  deletePlatform,
};
