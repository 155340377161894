import { useMutation, useQueryCache } from 'react-query';
import { useSnackbar } from 'notistack';

import {
  addClient as addRequest,
  updateClient as updateRequest,
  deleteClient as deleteRequest,
} from 'services/api/terraLeoApi/clientApi';

function useAddClientmMutation(setErrors) {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();

  return useMutation(addRequest, {
    onError: (error) => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
      if (error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      }
    },
    onSuccess: () => {
      // update the list of cached clients
      // must be the same key as the key that has
      // been used in useQuery of clients fetch
      queryCache.refetchQueries('clients');
      enqueueSnackbar('le client a bien été ajouté.', {
        variant: 'success',
      });
    },
  });
}

function useUpdateClientMutation(setErrors) {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(updateRequest, {
    onError: (error) => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
      if (error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      }
    },
    onSuccess: () => {
      queryCache.refetchQueries('clients');
      enqueueSnackbar('Le client a bien été modifié.', {
        variant: 'success',
      });
    },
  });
}

function useDeleteClientMutation() {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(deleteRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryCache.refetchQueries('clients');
      enqueueSnackbar('Le client a bien été supprimé.', {
        variant: 'success',
      });
    },
  });
}

export { useAddClientmMutation, useUpdateClientMutation, useDeleteClientMutation };
