import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';

import WarningIcon from '@material-ui/icons/Warning';
import { upperFirst } from 'lodash';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

import { Table, Loading } from 'components';
import { useGetPickups } from 'hooks/queries/pickupQueries';

const headCells = [
  { id: '1', label: 'Jour' },
  { id: '2', label: 'Période' },
  { id: '3', label: 'Véhicule' },
  { id: '4', label: 'Plateforme' },
];

function ClientWeekPickupsTable({ date, clientId }) {
  // get the pickups for selected client for the selected week
  const { data: clientWeekPickups, isLoading } = useGetPickups(new Date(date), clientId);

  if (isLoading || !clientId) {
    return <Loading small />;
  }

  return (
    <Table
      data={clientWeekPickups || []}
      size="small"
      getKey={(pickup) => pickup.id}
      getRowKey={(pickup) => pickup.id}
      headCells={headCells}
      isSortable={false}
      renderRow={(row) => (
        <TableRow key={row.id}>
          <TableCell>{upperFirst(format(new Date(row.date), 'iiii', { locale: fr }))}</TableCell>
          <TableCell> {row.isAm ? 'Matin' : 'Après midi'}</TableCell>
          <TableCell> {row.vehicle.name}</TableCell>
          <TableCell>
            {row.deposit && row.deposit.id ? row.deposit.platform.name : <WarningIcon />}
          </TableCell>
        </TableRow>
      )}
    />
  );
}

export default ClientWeekPickupsTable;
