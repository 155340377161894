import { isString } from 'lodash';
import { isValid, getISODay } from 'date-fns';
import { validate } from 'email-validator';

const REQUIRED_MESSAGE = 'Champ obligatoire';
const INVALID_EMAIL_MESSAGE = 'Email invalide';
const POSITIVE_NON_NULL_NUMBER_MESSAGE = 'Doit être un nombre positif non nul';
const POSITIVE_NUMBER_MESSAGE = 'Doit être un nombre positif';
const MAXiMUM_VALUE_MESSAGE = (maxValue) => `Maximum ${maxValue}`;
const EXACT_LENGTH_NUMBER = (length) => `Doit être égale à ${length} chiffres`;
const NOT_NUMBER_MESSAGE = `Doit être un nombre`;
const DATE_INVALID_MESSAGE = 'Date invalide';
const DISABLED_WEEKEND_MESSAGE = 'Weekend non autorisé';

// validate if a string value isn't empty
export const requiredNotEmptyString = (value, requiredMessage = REQUIRED_MESSAGE) => {
  if (!value || !isString(value) || !value.trim()) {
    return requiredMessage;
  }
  return true;
};

export const requiredValidEmail = (
  email,
  requiredMessage = REQUIRED_MESSAGE,
  invalidEmailMessage = INVALID_EMAIL_MESSAGE,
) => {
  if (requiredNotEmptyString(email) !== true) {
    return requiredMessage;
  }
  if (!validate(email)) {
    return invalidEmailMessage;
  }
  return true;
};

export const validEmail = (email, invalidEmailMessage = INVALID_EMAIL_MESSAGE) => {
  if (email && !validate(email)) {
    return invalidEmailMessage;
  }
  return true;
};

export const positiveAndNonNull = (value, nonNullMessage = POSITIVE_NON_NULL_NUMBER_MESSAGE) => {
  if (Number(value) === 0 || Number(value) < 0) {
    return nonNullMessage;
  }
  return true;
};

export const positive = (value, negativeMessage = POSITIVE_NUMBER_MESSAGE) => {
  if (Number(value) < 0) {
    return negativeMessage;
  }
  return true;
};

export const nonNullAndLessThan = (
  value,
  maxValue,
  maximumValueMessage,
  nonNullMessage = POSITIVE_NON_NULL_NUMBER_MESSAGE,
) => {
  if (!value || Number(value) < 0) {
    return nonNullMessage;
  }
  if (value > maxValue) {
    return maximumValueMessage || MAXiMUM_VALUE_MESSAGE(maxValue);
  }
  return true;
};

export const exactLengthNumber = (
  number,
  length,
  exactLengthMessage,
  notNumberMessage = NOT_NUMBER_MESSAGE,
) => {
  if (!number || number.trim() === '') {
    return true;
  }
  if (number.length !== length) {
    return exactLengthMessage || EXACT_LENGTH_NUMBER(length);
  }
  if (isNaN(number)) {
    return notNumberMessage;
  }
  return true;
};
/**
 * validate the length of phone number without space
 * it doesn't validate the format.  06 06 06 06 06 and 0606060606 are accpeted
 * @param {*} phone
 * @param {Number} phoneLength
 */
export const validPhone = (phone, phoneLength = 10) => {
  if (!phone || phone.trim() === '') {
    return true;
  }
  const trimedPhoneNumber = phone.replaceAll(' ', '');
  if (trimedPhoneNumber.length !== phoneLength) {
    return EXACT_LENGTH_NUMBER(phoneLength);
  }
  if (isNaN(trimedPhoneNumber)) {
    return NOT_NUMBER_MESSAGE;
  }
  return true;
};

export const validZip = (zip, zipLength = 5) => {
  return exactLengthNumber(zip, zipLength);
};

export const requiredvalidZip = (zip, zipLength = 5) => {
  const isValid = requiredValue(zip);
  if (isValid === true) {
    return validZip(zip, zipLength);
  }
  return isValid;
};

// this is used for select inputs
export const requiredValue = (value, requiredMessage = REQUIRED_MESSAGE) => {
  if (!value) {
    return requiredMessage;
  }
  return true;
};

export const requiredValidDate = (
  value,
  isWeekendDisabled,
  requiredMessage = REQUIRED_MESSAGE,
  invalidDateMessage = DATE_INVALID_MESSAGE,
  disabledWeekendMessage = DISABLED_WEEKEND_MESSAGE,
) => {
  if (!value) {
    return requiredMessage;
  } else if (!isValid(value)) {
    return invalidDateMessage;
  } else if (isWeekendDisabled && (getISODay(value) === 6 || getISODay(value) === 7)) {
    return disabledWeekendMessage;
  }
  return true;
};
