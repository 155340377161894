import { USER_ROLES } from 'config/constants';
const TOKEN_STORAGE_KEY = 'terraLeoToken';
const REFRESH_TOKEN_STORAGE_KEY = 'terraLeoRefreshToken';

/**
 * Save a Terra Léo API token and refresh token to the local localStorage.
 * @param {string} token
 */
function saveTokens(token, refreshToken) {
  localStorage.setItem(TOKEN_STORAGE_KEY, token);
  localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
}

/**
 * Return the Terra Léo API token stored in the local storage.
 * @returns {string|null} The token when it exists or null
 */
function getToken() {
  return localStorage.getItem(TOKEN_STORAGE_KEY);
}

/**
 * Return the Terra Léo API refresh token stored in the local storage.
 * @returns {string|null} The refresh token when it exists or null
 */
function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
}

function clearTokens() {
  localStorage.removeItem(TOKEN_STORAGE_KEY);
  localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
}

function getRoleFromArray(user) {
  // TODO : the backend contains a bugs, sometime roles attribut is object and sometime is an array
  // a temporary fix : check if roles is array or object
  if (user) {
    if (Array.isArray(user.roles)) {
      if (user.roles.includes(USER_ROLES.ADMIN)) {
        return USER_ROLES.ADMIN;
      } else if (user.roles.includes(USER_ROLES.PLANNER)) {
        return USER_ROLES.PLANNER;
      } else if (user.roles.includes(USER_ROLES.DRIVER)) {
        return USER_ROLES.DRIVER;
      }
      return null;
    } else {
      if (Object.values(user.roles).includes(USER_ROLES.ADMIN)) {
        return USER_ROLES.ADMIN;
      } else if (Object.values(user.roles).includes(USER_ROLES.PLANNER)) {
        return USER_ROLES.PLANNER;
      } else if (Object.values(user.roles).includes(USER_ROLES.DRIVER)) {
        return USER_ROLES.DRIVER;
      }
      return null;
    }
  }
}

export { getRefreshToken, getToken, clearTokens, saveTokens, getRoleFromArray };
