import { useState, useCallback } from 'react';

/**
 * Hook to measure a component's size in screen.
 *
 * Pass the ref to the component you need to measure and read its properties in the rect object.
 *
 * The rect object has the same shape as Element.getBoundingClientRect()'s return value.
 * @see https://developer.mozilla.org/fr/docs/Web/API/Element/getBoundingClientRect
 * @returns {[Object, Function]}
 */
function useClientRect() {
  const [rect, setRect] = useState(null);
  const ref = useCallback((node) => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);
  return [rect, ref];
}

export default useClientRect;
