import { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useAuth } from 'contexts/authContext';

/**
 * A route component that can only be accessed if the user IS NOT authenticated.
 *
 * If they are authenticated, redirects to the home page.
 */
function OnlyPublicRoute({ children, showMessage = false, ...rest }) {
  const { isAuthenticated } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isAuthenticated && showMessage) {
      enqueueSnackbar('Vous ne pouvez pas accéder à cette page si vous êtes connecté.', {
        variant: 'error',
      });
    }
  }, [showMessage, isAuthenticated, enqueueSnackbar]);

  return <Route {...rest} render={() => (isAuthenticated ? <Redirect to="/" /> : children)} />;
}

export default OnlyPublicRoute;
