import terraLeoApiClient from 'services/api/terraLeoApi/terraLeoApiClient';
import { BACKEND_BILLABLE_CLIENTS_ENDPOINTS } from '../constants';

async function getBillableClients() {
  const response = await terraLeoApiClient.getRequest(
    BACKEND_BILLABLE_CLIENTS_ENDPOINTS.LIST_BILLABLE_CLIENTS,
  );
  return response.data;
}

async function addBillableClient(billableClient) {
  const response = await terraLeoApiClient.postRequest(
    BACKEND_BILLABLE_CLIENTS_ENDPOINTS.ADD_BILLABLE_CLIENT,
    billableClient,
  );
  return response.data;
}

async function updateBillableClient(billableClient) {
  const response = await terraLeoApiClient.putRequest(
    BACKEND_BILLABLE_CLIENTS_ENDPOINTS.EDIT_BILLABLE_CLIENT(billableClient.id),
    billableClient,
  );
  return response.data;
}

async function deleteBillableClient(id) {
  const response = await terraLeoApiClient.patchRequest(
    BACKEND_BILLABLE_CLIENTS_ENDPOINTS.DELETE_BILLABLE_CLIENTS(id),
    { isActive: false },
  );
  return response.data;
}

export { getBillableClients, addBillableClient, updateBillableClient, deleteBillableClient };
