import { useMutation, useQueryCache } from 'react-query';
import { useSnackbar } from 'notistack';

import {
  addVehicle as addRequest,
  updateVehicle as updateRequest,
  deleteVehicle as deleteRequest,
} from 'services/api/terraLeoApi/vehicleApi';

function useAddVehicleMutation(setErrors) {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(addRequest, {
    onError: (error) => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
      if (error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      }
    },
    onSuccess: () => {
      // update the list of cached vehicles
      queryCache.refetchQueries('vehicles');
      enqueueSnackbar('Le véhicule a bien été ajouté.', {
        variant: 'success',
      });
    },
  });
}

function useUpdateVehicleMutation(setErrors) {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(updateRequest, {
    onError: (error) => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
      if (error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      }
    },
    onSuccess: () => {
      queryCache.refetchQueries('vehicles');
      enqueueSnackbar('Le véhicule a bien été modifié.', {
        variant: 'success',
      });
    },
  });
}

function useDeleteVehicleMutation() {
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = useQueryCache();
  return useMutation(deleteRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryCache.refetchQueries('vehicles');
      enqueueSnackbar('Le véhicule a bien été supprimé.', {
        variant: 'success',
      });
    },
  });
}

export { useAddVehicleMutation, useUpdateVehicleMutation, useDeleteVehicleMutation };
