import React, { useMemo, useState, useEffect } from 'react';
import {
  TableRow,
  TableCell,
  TextField,
  FormControlLabel,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import get from 'lodash/get';

import { TableButtons, Container, Table, Loading } from 'components';
import { routes } from 'config';
import { useGetClients } from 'hooks/queries/clientQueries';
import { useDeleteClientMutation } from 'hooks/mutations/clientMutations';
import { useDeleteBillableClientMutation } from 'hooks/mutations/billableClientMutations';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
}));

const headCells = [
  { id: 'name', label: 'Client collecte' },
  { id: 'billable.name', label: 'Client facturable' },
  { id: 'city', label: 'Ville' },
  { id: 'dailyWasteAmount', label: 'Quantité moyenne de biodéchets' },
  { id: 'isActive', label: 'Statut' },
];

function filterClients(data, search, activeOnly) {
  if (search === '') {
    return activeOnly ? data.filter((client) => client.isActive) : data;
  }
  const searchRegexp = new RegExp(search, 'gi');
  const searchedFields = ['name', 'billable.name', 'city'];
  return activeOnly
    ? data
        .filter((client) => client.isActive)
        .filter((u) => {
          return searchedFields.some((field) => get(u, field, '').match(searchRegexp));
        })
    : data.filter((u) => {
        return searchedFields.some((field) => get(u, field, '').match(searchRegexp));
      });
}

function Clients() {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState(null);
  const [filter, setFilter] = useState({
    search: '',
    activeOnly: true,
  });
  const { data: clients, isLoading } = useGetClients();
  const [
    deleteCollectableClient,
    { isLoading: isDeletingCollectable, isSuccess: isCollectableDeleted },
  ] = useDeleteClientMutation();
  const [
    deleteBillableClient,
    { isLoading: isDeletingBillable, isSuccess: isBillableDeleted },
  ] = useDeleteBillableClientMutation();
  const filtredClients = useMemo(
    () => (clients ? filterClients(clients, filter.search, filter.activeOnly) : []),
    [clients, filter],
  );

  // check if the selected client is billable or collectable client
  const isBillable = useMemo(
    () => (selectedItem && selectedItem.name === selectedItem.billable.name ? true : false),
    [selectedItem],
  );

  useEffect(() => {
    if (isCollectableDeleted || isBillableDeleted) {
      setSelectedItem(null);
    }
  }, [isCollectableDeleted, isBillableDeleted]);

  return (
    <Container fullHeight={isLoading}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className={classes.filterContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.activeOnly}
                  onChange={(e) => setFilter((prev) => ({ ...prev, activeOnly: e.target.checked }))}
                />
              }
              label="Actif seulement"
            />
            <TextField
              value={filter.search}
              onChange={(e) => setFilter((prev) => ({ ...prev, search: e.target.value }))}
              placeholder="Client/Ville"
              label="Rechercher"
              variant="outlined"
            />
          </div>

          <Table
            data={filtredClients}
            getKey={(client) => client.id}
            getRowKey={(client) => client.id}
            paginate
            headCells={headCells}
            renderRow={(client) => (
              <TableRow
                key={client.id}
                hover
                tabIndex={-1}
                onClick={() => setSelectedItem(selectedItem === client ? null : client)}
                selected={client === selectedItem}
              >
                <TableCell>{client.name}</TableCell>
                <TableCell>
                  {client.name !== client.billable.name ? client.billable.name : ''}
                </TableCell>
                <TableCell> {client.city}</TableCell>
                <TableCell> {client.dailyWasteAmount}</TableCell>
                <TableCell>{client.isActive ? 'Actif' : 'Inactif'}</TableCell>
              </TableRow>
            )}
          />
          <TableButtons
            deleteMessage={
              isBillable
                ? 'Attention, la désactivation de ce client entraînera la désactivation ' +
                  'de tous les clients de collecte associés. Voulez-vous continuer ?'
                : 'Vous voulez vraiment désactiver ce client ?'
            }
            informationMessage="Ce client est déjà inactif"
            editRoute={(item) => `${routes.clients.root}/edit/${item.id}`}
            addRoute={routes.clients.new}
            onDelete={() =>
              isBillable
                ? deleteBillableClient(selectedItem.billable.id)
                : deleteCollectableClient(selectedItem.id)
            }
            selectedItem={selectedItem}
            isDeleting={isDeletingCollectable || isDeletingBillable}
          />
        </>
      )}
    </Container>
  );
}

export default Clients;
