import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

import JWT from 'services/jwt';
import { getToken } from 'services/userService';

/**
 * Schedule a logout action when the token expires
 * @param {Function} logout The logout function called at token expiration
 */
function useAutoLogout(logout) {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let timeoutId = null;
    const token = getToken();

    if (token) {
      const ttlMs = new JWT(token).getTtlMilliseconds();

      timeoutId = setTimeout(() => {
        logout();
        enqueueSnackbar('Votre session a expiré.', { variant: 'warning' });
      }, ttlMs);

      return () => {
        if (timeoutId !== null) {
          clearTimeout(timeoutId);
        }
      };
    }
  });
}

export default useAutoLogout;
