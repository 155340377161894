/**
 * output value : xx xx xx xx xx
 * @param {*} phoneNumber
 */
export const maskPhoneNumber = (phoneNumber) => {
  // Filter only numbers from the input
  let cleaned = ('' + phoneNumber).replace(/\D/g, '');
  // Match five first groups of two digits + the rest
  let match = cleaned.match(/^(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(.)*$/);
  if (match && match.length > 0) {
    let result = '';
    // Loop on the matches and append them if not undefined
    // (Missing groups of two digits will return an undefined match while typing)
    for (let i = 1; i < match.length; i++) {
      if (match[i]) {
        result += match[i] + ' ';
      }
    }
    return result.trim();
  }
  return phoneNumber;
};
