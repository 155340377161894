import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import { fr } from 'date-fns/locale';

import './App.css';

import Pages from 'pages';
import { AuthProvider } from 'contexts/authContext';

import theme from 'theme';

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      // by default react-query retry 3 times if the request fails
      retry: false,
      // disable the refetch on window focus
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <SnackbarProvider maxSnack={3}>
        <ReactQueryCacheProvider queryCache={queryCache}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
              <AuthProvider>
                <Router>
                  <Pages />
                </Router>
              </AuthProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </ReactQueryCacheProvider>
      </SnackbarProvider>
    </div>
  );
}

export default App;
