import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    margin: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
}));

function ButtonsContainer({ children }) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}

export default ButtonsContainer;
