import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';

import { green } from 'theme/colors';
import { ClientSelector, WeekPickerWithArrows } from 'components';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  inlineContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    width: '300px',
  },
  marginRight: {
    marginRight: theme.spacing(2),
    marginBottom: '0px',
  },
}));

function Filter({ values, onChange }) {
  const classes = useStyles();

  return (
    <Grid container justify="flex-start" alignItems="center" className={classes.container}>
      <Grid item sm={12} md={6} lg={6}>
        <div className={classes.inlineContainer}>
          <PersonIcon style={{ color: green }} className={classes.marginRight} />
          <ClientSelector
            value={values.client}
            onChange={(e, value) => onChange((prev) => ({ ...prev, client: value }))}
            style={{ flexGrow: 1 }}
            placeholder="Client"
          />
        </div>
      </Grid>

      <Grid item sm={12} md={6} lg={6}>
        <WeekPickerWithArrows
          value={values.selectedDate}
          onChange={(date) => onChange((prev) => ({ ...prev, selectedDate: date }))}
          fullWidth={false}
        />
      </Grid>
    </Grid>
  );
}

export default Filter;
