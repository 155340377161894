import { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

import { ButtonWithLoading } from 'components';
import { useGeneratePlanning } from 'hooks/mutations/planningMutations';

function GeneratePlanningButton() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [generatePlanning, { isLoading: isGenerating, isSuccess }] = useGeneratePlanning();

  const onConfirm = () => {
    generatePlanning();
  };

  useEffect(() => {
    if (!isGenerating && isSuccess) {
      setDialogOpen(false);
    }
  }, [isSuccess, isGenerating]);

  return (
    <>
      <Tooltip title="Relance le calcul de planification">
        <Button color="primary" onClick={() => setDialogOpen(true)} startIcon={<SendIcon />}>
          Planifier
        </Button>
      </Tooltip>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Planifier</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Voulez-vous relancer le calcul de planification ? Les collectes programmées sur cette
            période seront écrasées.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonWithLoading startIcon={<SendIcon />} onClick={onConfirm} isLoading={isGenerating}>
            Valider
          </ButtonWithLoading>
          <Button disabled={isGenerating} onClick={() => setDialogOpen(false)}>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default GeneratePlanningButton;
