import terraLeoApiClient from 'services/api/terraLeoApi/terraLeoApiClient';

import { BACKEND_USERS_ENDPOINTS } from '../constants';

async function getUsers() {
  const response = await terraLeoApiClient.getRequest(BACKEND_USERS_ENDPOINTS.LIST_USERS);
  return response.data;
}

async function getUser(id) {
  const response = await terraLeoApiClient.getRequest(BACKEND_USERS_ENDPOINTS.GET_USER(id));
  return response.data;
}

async function addUser(user) {
  const response = await terraLeoApiClient.postRequest(BACKEND_USERS_ENDPOINTS.ADD_USER, user);
  return response;
}

async function updateUser(user) {
  const response = await terraLeoApiClient.patchRequest(
    BACKEND_USERS_ENDPOINTS.EDIT_USER(user.id),
    user,
  );
  return response;
}

async function deleteUser(id) {
  const response = await terraLeoApiClient.patchRequest(BACKEND_USERS_ENDPOINTS.DELETE_USER(id), {
    isActive: false,
  });
  return response;
}

async function forgotPassword(id) {
  const response = await terraLeoApiClient.getRequest(BACKEND_USERS_ENDPOINTS.FORGOT_PASSWORD(id));
  return response.data;
}

async function updatePassword({ id, newPassword, token }) {
  const response = await terraLeoApiClient.patchRequest(
    BACKEND_USERS_ENDPOINTS.EDIT_USER(id),
    {
      password: newPassword,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}
/**
 * return the user profile with temporary token, the response looks like : {user :{}, token :''}
 * @param {*} uuid
 */
async function getUserWithUuid(uuid) {
  const response = await terraLeoApiClient.getRequest(
    BACKEND_USERS_ENDPOINTS.GET_USER_WITH_UUID(uuid),
  );
  return response.data;
}

export {
  getUsers,
  getUser,
  addUser,
  updateUser,
  deleteUser,
  forgotPassword,
  updatePassword,
  getUserWithUuid,
};
