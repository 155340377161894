import { makeStyles } from '@material-ui/core/styles';
import { green, yellow, darkGreen } from 'theme/colors';

export default makeStyles((theme) => ({
  tableTitle: {
    backgroundColor: 'white',
    color: 'black',
    fontWeight: 'bold',
  },
  totalRow: {
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: green,
  },
  title: {
    fontWeight: 'bold',
    backgroundColor: darkGreen,
    color: 'white',
  },
  selected: {
    backgroundColor: yellow,
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}));
