import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  Grid,
  TextField,
  DialogTitle,
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { format, isBefore } from 'date-fns';
import { isEqual, pick } from 'lodash';
import { DatePicker } from '@material-ui/pickers';
import { useGetPickup, useGetSameDayPickups } from 'hooks/queries/pickupQueries';
import { useDeletePickupMutation, useUpdatePickupMutation } from 'hooks/mutations/pickupMutation';
import useClientRect from 'hooks/useClientRect';
import { ClientSelector, Label, DeleteButton, ConfirmDialog, ButtonWithLoading } from 'components';
import ClientWeekPickupsTable from '../../TableComponents/ClientWeekPickupsTable';
import { PICKUP_STATUS } from 'config/constants';
import { getISODay } from 'date-fns';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const INITIAL_STATE = {
  date: new Date(),
  weekNumber: 0,
  pickupOrder: 1,
  vehicle: '',
  client: { id: '' },
  isAm: true,
  status: { codename: '' },
};

// disable saturdays and sundays
function disableWeekends(date) {
  return getISODay(date) === 6 || getISODay(date) === 7;
}

/**
 * This component is a popup and is used to edit a pickup from the planning table.
 * only the client and pickupOrder can be modified
 */
function EditPickupDialog({ selectedPickup, open, onClose, unSelectItem }) {
  const [tempValues, setTempValues] = useState(INITIAL_STATE);
  const tempValuesBeforeUpdate = useRef(tempValues);
  // dialog box to confirm the interchange of two pickups orders
  const [openConfirmInterchange, setOpenConfirmInterchange] = useState(false);
  const [dialogRect, dialogRef] = useClientRect();
  const { data: pickup, isLoading: isLoadingPickup } = useGetPickup(selectedPickup.id);
  // get the same day pickups, in case if the user change the pickup order.
  const { data: sameDayPickups, isLoading: isLoadingSameDayPickups } = useGetSameDayPickups(
    format(new Date(tempValues.date), 'yyyy/MM/dd'),
    tempValues.isAm,
  );
  const [deletePickup, { isSuccess: isDeleted, isLoading: isDeleting }] = useDeletePickupMutation(
    unSelectItem,
  );
  const [updatePickup, { isSuccess: isUpdated, isLoading: isUpdating }] = useUpdatePickupMutation();

  useEffect(() => {
    // close Modal when pickup is successfully updated
    if (isUpdated && !isUpdating) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated, isUpdating]);

  useEffect(() => {
    // close Modal when pickup is successfully deleted
    if (isDeleted && !isDeleting) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleted, isDeleting]);

  const orderList = useMemo(() => {
    if (sameDayPickups && !isLoadingSameDayPickups) {
      // create order list using the number of pickups in that half day of the selected pickup
      return Array.from(new Array(sameDayPickups.length)).map((v, index) => index + 1);
    } else {
      return [];
    }
  }, [sameDayPickups, isLoadingSameDayPickups]);

  useEffect(() => {
    if (pickup && !isLoadingPickup) {
      setTempValues(pickup);
      tempValuesBeforeUpdate.current = pickup;
    }
  }, [pickup, isLoadingPickup]);

  //  pickup status is nullable
  const isReadOnly = useMemo(() => {
    return (
      tempValues.status &&
      (tempValues.status.codename === PICKUP_STATUS.CANCELLED ||
        tempValues.status.codename === PICKUP_STATUS.DELIVERED)
    );
  }, [tempValues]);

  const onDeleteClicked = () => {
    deletePickup(selectedPickup.id);
  };

  const onUpdateClicked = () => {
    // open the confirm interchange dialog if the order has been changed
    if (tempValuesBeforeUpdate.current.pickupOrder !== tempValues.pickupOrder) {
      setOpenConfirmInterchange(true);
    } else {
      updatePickup(
        pick(
          {
            ...tempValues,
            client: tempValues.client.id,
            date: format(tempValues.date, 'yyyy-MM-dd 00:00:00'),
          },
          ['id', 'client', 'date', 'isAm'],
        ),
      );
    }
  };

  const updateInterChangePickups = () => {
    // save the current selected pickup with new order and the new client if it's has been changed
    updatePickup(
      pick({ ...tempValues, client: tempValues.client.id }, ['id', 'client', 'pickupOrder']),
    );
    setOpenConfirmInterchange(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth
        PaperProps={{ ref: dialogRef }}
      >
        <DialogTitle>Modification collecte</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={1}>
            <Grid item container direction="row" xs={12}>
              <Grid item xs={4}>
                <Label small>Véhicule</Label>
              </Grid>
              <Grid item xs={8}>
                <TextField value={tempValues.vehicle.name} fullWidth disabled={true} />
              </Grid>
            </Grid>
            <Grid item container direction="row" xs={12}>
              <Grid item xs={4}>
                <Label small> Client</Label>
              </Grid>
              <Grid item xs={8}>
                <ClientSelector
                  value={tempValues.client}
                  onChange={(e, value) => setTempValues((prev) => ({ ...prev, client: value }))}
                  disabled={isReadOnly}
                  fullWidth
                  disableClearable
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" xs={12}>
              <Grid item xs={4}>
                <Label small> Ordre</Label>
              </Grid>
              <Grid item xs={8}>
                <Select
                  value={tempValues.pickupOrder}
                  onChange={(e) =>
                    setTempValues((prev) => ({ ...prev, pickupOrder: e.target.value }))
                  }
                  fullWidth
                  disabled={isReadOnly}
                >
                  {orderList.map((order) => (
                    <MenuItem key={`order-menu-${order}`} value={order}>
                      {order}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid item container direction="row" xs={12} alignItems="center">
              <Grid item xs={4}>
                <Label small>Date </Label>
              </Grid>
              <Grid item xs={8}>
                <DatePicker
                  value={tempValues.date}
                  format="dd/MM/yyyy"
                  onChange={(value) => setTempValues((prev) => ({ ...prev, date: value }))}
                  fullWidth
                  cancelLabel="Annuler"
                  invalidDateMessage=""
                  shouldDisableDate={disableWeekends}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" xs={12} alignItems="center">
              <Grid item xs={4}>
                <Label small>Période </Label>
              </Grid>
              <Grid item xs={8}>
                <Select
                  value={tempValues.isAm ? 'Matin' : 'Après midi'}
                  onChange={(e) =>
                    setTempValues((prev) => ({ ...prev, isAm: e.target.value === 'Matin' }))
                  }
                  fullWidth
                  disabled={isReadOnly}
                >
                  {['Matin', 'Après midi'].map((period) => (
                    <MenuItem key={`period-menu-${period}`} value={period}>
                      {period}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid item container direction="row" xs={12}>
              <Grid item xs={12}>
                <Label small>
                  Planification pour cette semaine pour le client : {tempValues.client.name}
                </Label>
              </Grid>
              <Grid item xs={12}>
                <ClientWeekPickupsTable
                  date={selectedPickup.date}
                  clientId={tempValues.client.id}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DeleteButton
            onDelete={onDeleteClicked}
            disabled={isReadOnly || isBefore(selectedPickup.date, new Date()) || isUpdating}
            deleteMessage="Vous voulez vraiment supprimer cette intervention ?"
            parentWidth={dialogRect?.width}
            isDeleting={isDeleting}
          />
          <ButtonWithLoading
            onClick={onUpdateClicked}
            disabled={
              isReadOnly ||
              isEqual(tempValuesBeforeUpdate.current, tempValues) ||
              isBefore(selectedPickup.date, new Date()) ||
              isUpdating ||
              isDeleting
            }
            isLoading={isUpdating}
          >
            Valider
          </ButtonWithLoading>
          <Button onClick={onClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
      {openConfirmInterchange && (
        <ConfirmDialog
          open={openConfirmInterchange}
          message="Vous voulez vraiment intervertir les rendez-vous ?"
          onConfirm={updateInterChangePickups}
          onClose={() => setOpenConfirmInterchange(false)}
          parentWidth={dialogRect.width}
        />
      )}
    </>
  );
}

export default EditPickupDialog;
