import terraLeoApiClient from 'services/api/terraLeoApi/terraLeoApiClient';

import { BACKEND_PICKUPS_ENDPOINTS } from '../constants';
/**
 * get pickups by half day
 * @param {String} date : required  "yyyy/MM/dd"
 * @param {Boolean} isAm : optionnel, by default isAm is false in backend !
 */
async function getHalfDayPickups(date, isAm) {
  const response = await terraLeoApiClient.getRequest(
    typeof isAm === 'boolean'
      ? `${BACKEND_PICKUPS_ENDPOINTS.LIST_PICKUPS}?date=${date}&isAm=${isAm}`
      : `${BACKEND_PICKUPS_ENDPOINTS.LIST_PICKUPS}?date=${date}`,
  );
  return response.data;
}

/**
 * get pickups by weeknumber and year
 * @param {*} weekNumber : required
 * @param {*} year : required
 * @param {*} clientId : optionnel
 */
async function getPickups(weekNumber, year, clientId) {
  const response = await terraLeoApiClient.getRequest(
    clientId
      ? `${BACKEND_PICKUPS_ENDPOINTS.LIST_PICKUPS}?weekNumber=${weekNumber}&year=${year}&client=${clientId}`
      : `${BACKEND_PICKUPS_ENDPOINTS.LIST_PICKUPS}?weekNumber=${weekNumber}&year=${year}`,
  );
  return response.data;
}

async function changePickupsDeposit({ oldDeposit, newDeposit }) {
  const response = await terraLeoApiClient.postRequest(
    `${BACKEND_PICKUPS_ENDPOINTS.CHANGE_PICKUPS_DEPOSIT}?oldDeposit=${oldDeposit}&newDeposit=${newDeposit}`,
  );
  return response.data;
}

async function getPickup(id) {
  const response = await terraLeoApiClient.getRequest(BACKEND_PICKUPS_ENDPOINTS.GET_PICKUPS(id));
  return response.data;
}

async function getPickupsDateRange() {
  const response = await terraLeoApiClient.getRequest(
    BACKEND_PICKUPS_ENDPOINTS.GET_PICKUPS_DATE_RANGE,
  );
  return response.data;
}

async function addPickup(pickup) {
  const response = await terraLeoApiClient.postRequest(
    BACKEND_PICKUPS_ENDPOINTS.ADD_PICKUPS,
    pickup,
  );
  return response;
}

async function updatePickup(pickup) {
  const response = await terraLeoApiClient.patchRequest(
    BACKEND_PICKUPS_ENDPOINTS.EDIT_PICKUPS(pickup.id),
    pickup,
  );
  return response.data;
}

async function deletePickup(id) {
  const response = await terraLeoApiClient.deleteRequest(
    BACKEND_PICKUPS_ENDPOINTS.DELETE_PICKUPS(id),
  );
  return response;
}

async function getProductTypes() {
  const response = await terraLeoApiClient.getRequest(BACKEND_PICKUPS_ENDPOINTS.GET_PRODUCT_TYPES);
  return response.data;
}

export {
  getPickups,
  getHalfDayPickups,
  getPickup,
  addPickup,
  updatePickup,
  getProductTypes,
  deletePickup,
  changePickupsDeposit,
  getPickupsDateRange,
};
