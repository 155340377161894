import React, { useState, useCallback } from 'react';
import {
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Table,
  TableBody,
  Menu,
  MenuList,
  MenuItem,
  IconButton,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import uuidv4 from 'uuid/v4';
import { useHistory } from 'react-router-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { addDays, isFuture } from 'date-fns';

import { getRoleFromArray } from 'services/userService';
import { USER_ROLES } from 'config/constants';
import { useAuth } from 'contexts/authContext';
import AddPickupDialog from '../../DialogBoxes/AddPickupDialog';
import { Label } from 'components';
import routes from 'config/routes';
import useStyles from './styles';
import { startOfWeek } from 'date-fns';

const weekCells = [
  { label: 'LUNDI' },
  { label: 'MARDI' },
  { label: 'MERCREDI' },
  { label: 'JEUDI' },
  { label: 'VENDREDI' },
];

function WeekCircuitTableBase({
  displayRows,
  emptyDataText,
  isMorningCircuit,
  depositList,
  selectedItem,
  setSelectedItem,
  setOpen,
  mondayOfTheSelectedPeriod,
}) {
  const classes = useStyles();
  const [pickupAnchorEls, setPickupAnchorEls] = useState(null);
  const history = useHistory();
  const [openAddPickup, setOpenAddPickup] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [hoveredCell, setHoveredCell] = useState(null);
  const { user } = useAuth();

  const selectPickup = useCallback((e, pickup) => {
    // Pickups before the current week cannot be selected
    const thisMonday = startOfWeek(new Date(), { weekStartsOn: 1 }).setTime(0, 0, 0, 0);
    if (pickup && new Date(pickup.date) >= thisMonday) {
      setSelectedItem({ isDeposit: false, item: pickup });
      setPickupAnchorEls(e.currentTarget);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectDeposit = useCallback((e, deposit) => {
    setSelectedItem({ isDeposit: true, item: deposit });
    setPickupAnchorEls(e.currentTarget);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeMenu = () => {
    setPickupAnchorEls(null);
  };

  const onEditClicked = () => {
    setOpen(true);
    closeMenu();
  };

  const onCellHover = (rowIndex, columnIndex) => {
    const day = addDays(mondayOfTheSelectedPeriod, columnIndex).setHours(23, 59, 59);
    if (getRoleFromArray(user) !== USER_ROLES.DRIVER && isFuture(day)) {
      setHoveredCell({ row: rowIndex, column: columnIndex });
    }
  };

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={10} className={classes.tableTitle}>
                {isMorningCircuit ? 'COLLECTE MATIN' : 'COLLECTE APRES MIDI'}
              </TableCell>
            </TableRow>
            <TableRow>
              {weekCells.map((week) => (
                <TableCell
                  key={uuidv4()}
                  align="center"
                  colSpan={2}
                  className={clsx(classes.weekTitle, classes.headCell)}
                >
                  {week.label}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {weekCells.map(() => (
                <React.Fragment key={uuidv4()}>
                  <TableCell className={classes.headCell}>CLIENTS</TableCell>
                  <TableCell className={classes.headCell}>ORDRE PASSAGE</TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayRows ? (
              <>
                {displayRows.map((row, rowIndex) => (
                  <TableRow key={rowIndex} className={classes.tableRow}>
                    {row.map((pickup, columnIndex) => (
                      <React.Fragment key={pickup ? pickup.id : uuidv4()}>
                        <TableCell
                          onClick={(e) => selectPickup(e, pickup)}
                          className={clsx({
                            [classes.clientCell]: true,
                            [classes.selected]:
                              selectedItem &&
                              pickup &&
                              selectedItem.isDeposit === false &&
                              selectedItem.item.id === pickup.id,
                          })}
                        >
                          {pickup ? (
                            pickup.client.name
                          ) : (
                            <div
                              onMouseEnter={() => onCellHover(rowIndex, columnIndex)}
                              onMouseLeave={() => setHoveredCell(null)}
                            >
                              {hoveredCell &&
                              hoveredCell.row === rowIndex &&
                              hoveredCell.column === columnIndex ? (
                                <IconButton
                                  onClick={() => {
                                    setOpenAddPickup(true);
                                    setSelectedDay(addDays(mondayOfTheSelectedPeriod, columnIndex));
                                  }}
                                  size="small"
                                >
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              ) : (
                                <Typography>-</Typography>
                              )}
                            </div>
                          )}
                        </TableCell>
                        <TableCell
                          className={clsx({
                            [classes.selected]:
                              selectedItem &&
                              pickup &&
                              selectedItem.isDeposit === false &&
                              selectedItem.item.id === pickup.id,
                          })}
                          onClick={(e) => selectPickup(e, pickup)}
                        >
                          {pickup ? pickup.pickupOrder : '-'}
                        </TableCell>
                      </React.Fragment>
                    ))}
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell align="center" colSpan={10} className={classes.platformTitle}>
                    PLATEFORME
                  </TableCell>
                </TableRow>
                <TableRow>
                  {depositList?.map((deposit) => (
                    <TableCell
                      key={deposit ? deposit.id : uuidv4()}
                      colSpan={2}
                      className={clsx({
                        [classes.selected]:
                          selectedItem &&
                          deposit &&
                          selectedItem.isDeposit === true &&
                          selectedItem.item.id === deposit.id,
                      })}
                      onClick={(e) => (deposit ? selectDeposit(e, deposit) : null)}
                    >
                      {deposit && deposit.platform ? deposit.platform.name : '-'}
                    </TableCell>
                  ))}
                </TableRow>
              </>
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={10}>
                  <Label subLabel>{emptyDataText || 'Aucun élément'} </Label>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        open={Boolean(pickupAnchorEls)}
        anchorEl={pickupAnchorEls}
        keepMounted
        onClose={closeMenu}
        autoFocus={false}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuList>
          {selectedItem && selectedItem.isDeposit === false && (
            <MenuItem onClick={onEditClicked}>Modifier </MenuItem>
          )}
          <MenuItem
            onClick={() =>
              selectedItem && selectedItem.isDeposit
                ? history.push(routes.deposit.root + '/edit/' + selectedItem.item.id)
                : history.push(routes.pickup.root + '/edit/' + selectedItem.item.id)
            }
          >
            Saisie
          </MenuItem>
        </MenuList>
      </Menu>
      <AddPickupDialog
        open={openAddPickup}
        onClose={() => setOpenAddPickup(false)}
        isAm={isMorningCircuit}
        selectedDate={selectedDay}
      />
    </>
  );
}

export default WeekCircuitTableBase;
