import { useQuery } from 'react-query';

import { getZonePlatformTimes } from 'services/api/terraLeoApi/zonePlatformTimes';

function useGetPlatformHours(zoneId) {
  // run the query if the id is defined
  return useQuery(['zonePlatformTimes', zoneId], () => getZonePlatformTimes(zoneId), {
    enabled: zoneId,
  });
}

export { useGetPlatformHours };
