import { round } from 'lodash';

function extractPayload(tokenString) {
  const parts = tokenString.split('.');
  if (parts.length !== 3) {
    throw new Error('Invalid JWT');
  }

  return parts[1];
}

function decodePayload(tokenString) {
  const b64Payload = extractPayload(tokenString);
  const clearPayload = atob(b64Payload);

  return JSON.parse(clearPayload);
}

const REFRESH_THRESHOLD_MS = 1000 * 60 * 5;

class JWT {
  constructor(tokenString) {
    this.payload = decodePayload(tokenString);
  }

  getPayload() {
    return this.payload;
  }

  getTtlMilliseconds() {
    return round((this.payload.exp - Date.now() / 1000) * 1000);
  }

  /**
   * Get the timeout duration when the token has to be refreshed.
   *
   * Five minutes before the token expires.
   *
   * @returns {number} Number of milliseconds after which the token must be refreshed
   */
  getRefreshTimeout() {
    return this.getTtlMilliseconds() - REFRESH_THRESHOLD_MS;
  }

  getUser() {
    return this.payload.user;
  }
}

export default JWT;
