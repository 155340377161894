import React, { useMemo } from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import clsx from 'clsx';
import { addMonths, format } from 'date-fns';
import { DatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  fullWidth: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
  },
}));

/**
 * this component allow month navigation using arrows
 * props.minDate : the first day of the min yyyy/MM of pickups in the database
 * props.maxDate : the last day of the max yyyy/MM of pickups in the database
 */
function MonthPickerWithArrows({
  value,
  onChange,
  isReadOnly,
  className,
  fullWidth = true,
  ...props
}) {
  const classes = useStyles();

  const prevMonth = addMonths(value, -1);
  const prevDate = new Date(format(prevMonth, 'yyyy/MM'));

  const nextMonth = addMonths(value, 1);
  const nextDate = new Date(format(nextMonth, 'yyyy/MM'));

  const minDate = useMemo(
    () => (props.minDate ? new Date(format(props.minDate, 'yyyy/MM')) : null),
    [props.minDate],
  );
  const maxDate = useMemo(
    () => (props.maxDate ? new Date(format(props.maxDate, 'yyyy/MM')) : null),
    [props.maxDate],
  );

  return (
    <div className={clsx({ [classes.container]: true, [classes.fullWidth]: fullWidth, className })}>
      <IconButton
        disabled={!value || isReadOnly || (props.minDate && prevDate < minDate)}
        onClick={() => onChange(prevMonth)}
      >
        <ArrowBackIcon />
      </IconButton>
      <DatePicker
        openTo="month"
        views={['year', 'month']}
        value={value}
        onChange={(date) => onChange(date)}
        cancelLabel="Annuler"
        {...props}
      />
      <IconButton
        disabled={!value || isReadOnly || (props.maxDate && nextDate > maxDate)}
        onClick={() => onChange(nextMonth)}
      >
        <ArrowForwardIcon />
      </IconButton>
    </div>
  );
}

export default MonthPickerWithArrows;
