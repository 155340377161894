import React, { useMemo, useEffect } from 'react';
import { useGetVehicles } from 'hooks/queries/vehicleQueries';
import SelectAutoComplete from '../SelectAutoComplete';

function getOptionLabel(option) {
  return option ? option.name : '';
}

function getOptionSelected(option, value) {
  return value !== null && option.id === value.id;
}

function VehicleSelector({ isFirstDefaultSelected, onChange, ...props }) {
  const { data: vehicles, isLoading } = useGetVehicles();

  const data = useMemo(
    () =>
      vehicles
        ? vehicles
            .filter((vehicle) => vehicle.isActive)
            .map((vehicle) => {
              return { id: vehicle.id, name: vehicle.name };
            })
        : [],

    [vehicles],
  );

  // select first element by default when isFirstDefaultSelected prop is true
  useEffect(() => {
    if (isFirstDefaultSelected && data && data.length > 0 && onChange) {
      onChange(null, data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFirstDefaultSelected]);

  return (
    <SelectAutoComplete
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      loading={isLoading}
      variables={data}
      onChange={onChange}
      {...props}
    />
  );
}

export default VehicleSelector;
