import React from 'react';
import { FormControlLabel, FormControl, Radio, RadioGroup } from '@material-ui/core';
import { ErrorHelper, Loading } from 'components';

function RadioGroupWithLoading({
  onChange,
  isLoading,
  isError,
  errorMessage = "Une erreur s'est produite lors de la récupération de données.",
  value,
  vehiclesTypes,
}) {
  return (
    <FormControl component="fieldset">
      {isLoading ? (
        <Loading small />
      ) : isError ? (
        <ErrorHelper message={errorMessage} />
      ) : (
        <RadioGroup row value={String(value)} onChange={onChange}>
          {vehiclesTypes?.map((type) => (
            <FormControlLabel
              key={type.id}
              value={String(type.id)}
              label={type.name}
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      )}
    </FormControl>
  );
}

export default RadioGroupWithLoading;
