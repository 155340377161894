import React from 'react';
import { IconButton } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import {
  startOfWeek,
  isValid,
  format,
  endOfWeek,
  isWithinInterval,
  isSameDay,
  isBefore,
  isAfter,
} from 'date-fns';
import MyToolbar from './Toolbar';
import useStyles from './styles';

const formatWeekSelectLabel = (date, invalidLabel) => {
  let dateClone = date;

  return dateClone && isValid(dateClone)
    ? `Semaine de ${format(startOfWeek(dateClone, { weekStartsOn: 1 }), 'dd/MM/yyyy')}`
    : invalidLabel;
};

function WeekPicker({ value, onChange, isReadOnly, fullWidth, ...props }) {
  const classes = useStyles();

  const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    let dateClone = new Date(date.valueOf());
    let selectedDateClone = new Date(selectedDate.valueOf());

    const start = startOfWeek(selectedDateClone, { weekStartsOn: 1 });
    const end = endOfWeek(selectedDateClone, { weekStartsOn: 1 });
    const dayIsBetween = isWithinInterval(dateClone, { start: start, end: end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
      [classes.outOfRangeMinAndMax]: isBefore(date, props.minDate) || isAfter(date, props.maxDate),
    });

    return (
      <div>
        <div className={wrapperClassName}>
          <IconButton className={dayClassName}>
            <span>{format(dateClone, 'dd')}</span>
          </IconButton>
        </div>
      </div>
    );
  };

  return (
    <DatePicker
      value={value}
      onChange={(date) => onChange(startOfWeek(date, { weekStartsOn: 1 }))}
      renderDay={renderWrappedWeekDay}
      labelFunc={formatWeekSelectLabel}
      ToolbarComponent={MyToolbar}
      readOnly={isReadOnly}
      fullWidth={fullWidth}
      cancelLabel="Annuler"
      {...props}
    />
  );
}

export default WeekPicker;
