import React from 'react';
import { Checkbox, Grid, TextField } from '@material-ui/core';
import { ErrorHelper } from 'components';

function RefusalMessage({ isChecked, setIsChecked, comments, setComments, error, readOnly }) {
  return (
    <Grid container>
      <Grid item xs={2}>
        <div>
          <Checkbox
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            disabled={readOnly}
          />
        </div>
      </Grid>
      <Grid item xs={10}>
        <TextField
          fullWidth
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          placeholder="Commentaires"
          rows={4}
          multiline
          disabled={!isChecked || readOnly}
        />
        {error && <ErrorHelper message={error} />}
      </Grid>
    </Grid>
  );
}
export default RefusalMessage;
