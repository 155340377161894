import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  formContainer: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(7),
      boxShadow: '0px 0px 15px #D2D0D0',
      backgroundColor: 'white',
    },
  },
  formGridItem: { display: 'flex', flexGrow: 1, justifyContent: 'center' },
  logoGridItem: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    width: '200px',
    height: '200px',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  loginButton: {
    marginTop: theme.spacing(2),
  },
}));
