export const USER_ROLES = {
  DRIVER: 'ROLE_DRIVER',
  PLANNER: 'ROLE_PLANNER',
  ADMIN: 'ROLE_ADMIN',
};

export const USER_ROLES_LABELS = {
  [USER_ROLES.DRIVER]: 'Chauffeur',
  [USER_ROLES.PLANNER]: 'Planificateur',
  [USER_ROLES.ADMIN]: 'Administrateur',
};

export const PICKUP_STATUS = {
  PLANNED: 'PLANNED',
  COLLECTED: 'COLLECTED',
  DELIVERED: 'DELIVERED',
  CANCELLED: 'CANCELLED',
};

export const PICKUP_STATUS_LABELS = {
  [PICKUP_STATUS.PLANNED]: 'Planifié',
  [PICKUP_STATUS.COLLECTED]: 'Collecté',
  [PICKUP_STATUS.DELIVERED]: 'Livré',
  [PICKUP_STATUS.CANCELLED]: 'Annulé',
};

export const PICKUP_TYPES = {
  BULK: 'BULK',
  PACKAGED: 'PACKAGED',
};

export const PICKUP_TYPES_LABELS = {
  [PICKUP_TYPES.BULK]: 'En vrac',
  [PICKUP_TYPES.PACKAGED]: 'Conditionné',
};
