import { cloneDeep } from 'lodash';
import { format } from 'date-fns';

/**
 * backend store opening hours of clients and platforms as string with this format HH:mm.
 * convert date to "HH:mm" string
 * frontend timeTable param look like this :
  {  dayNumber: {
    am: ['2020-12-23T08:21:57.121Z', '2020-12-23T08:21:57.121Z'],
    pm: ['2020-12-23T08:21:57.121Z', '2020-12-23T08:21:57.121Z'],
  },
 }
 * @param {*} timeTable 
 */
export const openingDatesToHourString = (timeTable) => {
  const timeTableClone = cloneDeep(timeTable);
  Object.keys(timeTableClone).forEach((dayNumber) => {
    timeTableClone[dayNumber].am[0] = format(timeTableClone[dayNumber].am[0], 'HH:mm');
    timeTableClone[dayNumber].am[1] = format(timeTableClone[dayNumber].am[1], 'HH:mm');
    timeTableClone[dayNumber].pm[0] = format(timeTableClone[dayNumber].pm[0], 'HH:mm');
    timeTableClone[dayNumber].pm[1] = format(timeTableClone[dayNumber].pm[1], 'HH:mm');
  });
  return timeTableClone;
};

/**
 * convert "HH:mm" to date so the Timepicker can read the values
 * only the time part is important, thus a dumb date is added (2020/12/23)
 * timeTable param look like this :
 {  dayNumber: {
    am: ['08:30', '12:00'],
    am: ['13:30', '17:00'],
  },
 }
 * @param {*} timeTable 
 */
export const openingHourStringToDates = (timeTable) => {
  const timeTableClone = cloneDeep(timeTable);
  Object.keys(timeTableClone).forEach((dayNumber) => {
    timeTableClone[dayNumber].am[0] = new Date(
      '2020/12/23 ' + timeTableClone[dayNumber].am[0] + ':00',
    );
    timeTableClone[dayNumber].am[1] = new Date(
      '2020/12/23 ' + timeTableClone[dayNumber].am[1] + ':00',
    );
    timeTableClone[dayNumber].pm[0] = new Date(
      '2020/12/23 ' + timeTableClone[dayNumber].pm[0] + ':00',
    );
    timeTableClone[dayNumber].pm[1] = new Date(
      '2020/12/23 ' + timeTableClone[dayNumber].pm[1] + ':00',
    );
  });
  return timeTableClone;
};

/**
 * it returns an array of 3 elements as follow in order : object of the new opening hours with format HH:mm,
 * object of modified opening hours, and deleted opening hours.
 * @param {*} timeTable
 * @param {*} timeTableBeforeUpdate
 */
export const getOpeningHoursChanges = (timeTable, timeTableBeforeUpdate) => {
  // array of opening hours ids to delete
  let deletedOpeningHoursIds = [];
  // object of opening hours to update, it should like {openingHourId : [start,end], ...}
  const modifiedOpeningHours = {};
  // object of platformHours to add
  const newOpeningHours = {};
  // when there is already opening hour for an entity in database
  // timeTableBeforeUpdate look like this {dayNumber: am : [start,end,openingHourId] , pm : [start,end,openingHourId]}
  for (let dayNumber = 1; dayNumber < 6; dayNumber++) {
    if (timeTable[dayNumber] || timeTableBeforeUpdate[dayNumber]) {
      if (timeTable[dayNumber] && !timeTableBeforeUpdate[dayNumber]) {
        newOpeningHours[dayNumber] = timeTable[dayNumber];
      } else if (!timeTable[dayNumber] && timeTableBeforeUpdate[dayNumber]) {
        deletedOpeningHoursIds.push(
          timeTableBeforeUpdate[dayNumber].am[2],
          timeTableBeforeUpdate[dayNumber].pm[2],
        );
        // when platformHour is "updated", actually it has been deleted from timetable and new day is entred
        // thus, am and pm attributes will have only two elments [start,end]
      } else if (
        timeTable[dayNumber] &&
        timeTable[dayNumber].am.length === 2 &&
        timeTableBeforeUpdate[dayNumber]
      ) {
        modifiedOpeningHours[timeTableBeforeUpdate[dayNumber].am[2]] = [
          format(timeTable[dayNumber].am[0], 'HH:mm'),
          format(timeTable[dayNumber].am[1], 'HH:mm'),
        ];
        modifiedOpeningHours[timeTableBeforeUpdate[dayNumber].pm[2]] = [
          format(timeTable[dayNumber].pm[0], 'HH:mm'),
          format(timeTable[dayNumber].pm[1], 'HH:mm'),
        ];
      }
    }
  }

  return [openingDatesToHourString(newOpeningHours), modifiedOpeningHours, deletedOpeningHoursIds];
};
