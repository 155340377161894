import React from 'react';
import { makeStyles } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TodayIcon from '@material-ui/icons/Today';
import SearchIcon from '@material-ui/icons/Search';
import BarChartIcon from '@material-ui/icons/BarChart';
import { PeopleAltOutlined } from '@material-ui/icons';
import StoreIcon from '@material-ui/icons/Store';

import { getRoleFromArray } from 'services/userService';
import { useAuth } from 'contexts/authContext';
import { USER_ROLES } from 'config/constants';
import { Container } from 'components';
import routes from 'config/routes';
import { Recycle } from 'components/Icons';

import Item from './Item';

const useStyles = makeStyles(() => ({
  subContainer: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
  },
}));

function Dashboard() {
  const classes = useStyles();
  const { user } = useAuth();
  const userRole = getRoleFromArray(user);
  return (
    <Container fullHeight>
      <div className={classes.subContainer}>
        <Item Icon={StoreIcon} label="Clients" route={routes.clients.root} />
        <Item Icon={TodayIcon} label="Circuits" route={routes.circuit.root} />

        {userRole === USER_ROLES.ADMIN && (
          <>
            <Item Icon={PeopleAltOutlined} label="Utilisateurs" route={routes.users.root} />
            <Item Icon={LocalShippingIcon} label="Véhicules" route={routes.vehicles.root} />
            <Item Icon={LocationOnIcon} label="Zone" route={routes.zone.root} />
            <Item Icon={Recycle} label="Plateformes" route={routes.platforms.root} />
            <Item Icon={SettingsIcon} label="Administration" route={routes.administration.root} />
            <Item Icon={BarChartIcon} label="Suivi mensuel" route={routes.monthlyFollowUp.root} />
          </>
        )}
        {(userRole === USER_ROLES.ADMIN || userRole === USER_ROLES.PLANNER) && (
          <>
            <Item Icon={AccessTimeIcon} label="Collectes" route={routes.recurrence.root} />
            <Item Icon={SearchIcon} label="Suivi hebdo" route={routes.weeklyFollowUp.root} />
          </>
        )}
      </div>
    </Container>
  );
}
export default Dashboard;
