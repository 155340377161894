import terraLeoApiClient from 'services/api/terraLeoApi/terraLeoApiClient';
import { BACKEND_DAC_ENDPOINT } from '../constants';

async function getDac(dacNumber) {
  const response = await terraLeoApiClient.getRequest(BACKEND_DAC_ENDPOINT.GET_DAC(dacNumber), {
    responseType: 'blob',
  });
  return response.data;
}

async function getZip(month, year, clientId) {
  const response = await terraLeoApiClient.getRequest(
    BACKEND_DAC_ENDPOINT.GET_ZIP(month, year, clientId),
    {
      responseType: 'blob',
    },
  );
  return response.data;
}

export { getDac, getZip };
