import React from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';

function TableHeadComponent({ headCells, order, orderBy, onRequestSort, isSortable = true }) {
  const makeSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {isSortable
          ? headCells.map((headCell) => (
              <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={makeSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {!orderBy === headCell.id && null}
                </TableSortLabel>
              </TableCell>
            ))
          : headCells.map((headCell) => <TableCell key={headCell.id}>{headCell.label}</TableCell>)}
      </TableRow>
    </TableHead>
  );
}

export default TableHeadComponent;
