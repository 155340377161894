import { omitBy } from 'lodash';
import { requiredNotEmptyString, requiredValue } from 'services/validationUtils';

export const validateForm = (
  values,
  isSignatureChecked,
  signedWith,
  newContactName,
  isContactNameDeleted,
) => {
  const validations = {
    depositTime: requiredValue(values.depositTime),
    depositSignature: validateSignature(
      values.depositSignature,
      isSignatureChecked,
      signedWith,
      newContactName,
      isContactNameDeleted,
    ),
    comments: values.isRefused ? requiredNotEmptyString(values.comments) : true,
  };
  const errors = omitBy(validations, (validation) => validation === true);

  return Object.keys(errors).length > 0 ? errors : true;
};

const validateSignature = (
  signature,
  isClientChecked,
  signedWith,
  newContactName,
  isContactNameDeleted,
) => {
  if (isClientChecked) {
    if (!signature) {
      return 'La signature est vide';
    }
    if (signedWith !== newContactName && isContactNameDeleted) {
      return 'Le nom de la personne signée a changé';
    }
    return true;
  }
  return true;
};
