import terraLeoApiClient from 'services/api/terraLeoApi/terraLeoApiClient';

import { BACKEND_VEHICLES_ENDPOINTS } from '../constants';

async function getVehicles() {
  const response = await terraLeoApiClient.getRequest(BACKEND_VEHICLES_ENDPOINTS.LIST_VEHICLES);
  return response.data;
}

async function getVehicle(id) {
  const response = await terraLeoApiClient.getRequest(BACKEND_VEHICLES_ENDPOINTS.GET_VEHICLE(id));
  return response.data;
}

async function getVehiclesTypes() {
  const response = await terraLeoApiClient.getRequest(
    BACKEND_VEHICLES_ENDPOINTS.LIST_VEHICLES_TYPES,
  );
  return response.data;
}

async function addVehicle(vehicle) {
  const response = await terraLeoApiClient.postRequest(
    BACKEND_VEHICLES_ENDPOINTS.ADD_VEHICLE,
    vehicle,
  );
  return response.data;
}

async function updateVehicle(vehicle) {
  const response = await terraLeoApiClient.patchRequest(
    BACKEND_VEHICLES_ENDPOINTS.EDIT_VEHICLE(vehicle.id),
    vehicle,
  );
  return response.data;
}

async function deleteVehicle(id) {
  const response = await terraLeoApiClient.patchRequest(
    BACKEND_VEHICLES_ENDPOINTS.DELETE_VEHICLE(id),
    { isActive: false },
  );
  return response.data;
}

export { getVehicle, getVehiclesTypes, getVehicles, updateVehicle, deleteVehicle, addVehicle };
