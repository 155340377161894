import React from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';

import useStyles, { useStylesLeft, useStylesRight } from './styles';

function FormGroup({ className, gutterLeft, gutterRight, gutter, ...other }) {
  const classes = useStyles();

  return (
    <Grid
      item
      className={clsx(className, classes.formGroup, {
        [classes.gutterLeft]: gutter || gutterLeft,
        [classes.gutterRight]: gutter || gutterRight,
      })}
      {...other}
    />
  );
}

function LeftFormGroup({ className, ...other }) {
  const classes = useStylesLeft();

  return <FormGroup className={clsx(className, classes.left)} {...other} />;
}

function RightFormGroup({ className, ...other }) {
  const classes = useStylesRight();

  return <FormGroup className={clsx(className, classes.right)} {...other} />;
}

FormGroup.Left = LeftFormGroup;
FormGroup.Right = RightFormGroup;

export default FormGroup;
