import React from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';

import useStyles from './styles';

function Section({ className, marginBottom = false, subSection = false, ...other }) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      item={subSection}
      className={clsx(className, classes.section, {
        [classes.marginBottom]: marginBottom,
        [classes.subSection]: subSection,
      })}
      {...other}
    />
  );
}

export default Section;
