import { useState } from 'react';
import { ButtonWithLoading } from 'components';
import { useForgotPassword } from 'hooks/queries/userQueries';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    maxWidth: ({ title }) => `${title.length + 2}rem`,
    transition: 'max-width 0.5s',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

function ResetPasswordButton({ userId }) {
  const [hovered, setHovered] = useState(false);
  const { isLoading, refetch: onClick } = useForgotPassword(userId);
  const title = hovered || isLoading ? 'Réinitialiser le mot de passe' : 'MDP';
  const classes = useStyles({ title });

  return (
    <ButtonWithLoading
      className={classes.root}
      onClick={() => onClick()}
      isLoading={isLoading}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {title}
    </ButtonWithLoading>
  );
}

export default ResetPasswordButton;
