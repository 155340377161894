import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  makeStyles,
  InputAdornment,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import {
  Container,
  ButtonsContainer,
  ErrorHelper,
  ButtonWithLoading,
  Label,
  ZoneSelector,
  Loading,
  ErrorPage,
  CancelButton,
  SubmitAndRedirectButton,
} from 'components';
import { routes } from 'config';
import { useAddVehicleMutation, useUpdateVehicleMutation } from 'hooks/mutations/vehicleMutations';
import { useGetVehiclesTypes, useGetVehicle } from 'hooks/queries/vehicleQueries';
import RadioGroupWithLoading from './RadioGroupWithLoading';
import { validateForm } from './validation';

const useStyles = makeStyles((theme) => ({
  CheckboxContainer: {
    marginLeft: theme.spacing(3),
    justifyContent: 'center',
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}));

const initialValues = {
  name: '',
  numberPlate: '',
  capacity: 0,
  type: '',
  dacCode: '',
  email: '',
  isActive: true,
  startZone: '',
};

function VehicleForm() {
  const { id } = useParams();
  const classes = useStyles();
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(null);
  const valuesBeforeUpdate = useRef(values);
  const { data: vehicle, isLoading, error } = useGetVehicle(id);
  const [addVehicle, { isLoading: isAdding, isSuccess: isAdded }] = useAddVehicleMutation(
    setErrors,
  );
  const [updateVehicle, { isLoading: isUpdating, isSuccess: isUpdated }] = useUpdateVehicleMutation(
    setErrors,
  );
  const {
    data: vehiclesTypes,
    isLoading: isLoadingTypes,
    isError: isErrorTypes,
  } = useGetVehiclesTypes();

  useEffect(() => {
    if (id && vehicle && !isLoading) {
      setValues(vehicle);
      valuesBeforeUpdate.current = vehicle;
    }
  }, [id, isLoading, vehicle]);

  useEffect(() => {
    isAdded && setValues(initialValues);
  }, [isAdded]);

  const submit = () => {
    const formErrors = validateForm(values);
    if (formErrors !== true) {
      setErrors(formErrors);
    } else {
      setErrors({});
      const valuesClone = cloneDeep(values);
      valuesClone.startZone = valuesClone.startZone.id;
      if (id && valuesClone.type) {
        valuesClone.type = valuesClone.type.id;
      }
      id ? updateVehicle(valuesClone) : addVehicle(valuesClone);
    }
  };

  if (isLoading) {
    return (
      <Container fullHeight>
        <Loading />
      </Container>
    );
  } else if (error && error.response) {
    return (
      <Container fullHeight>
        <ErrorPage error={error} notFoundMessage="Le véhicule n'existe pas" />
      </Container>
    );
  }

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={2}>
            <Label small>Désignation</Label>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={values.name}
              onChange={(e) => setValues((prev) => ({ ...prev, name: e.target.value }))}
              fullWidth
              placeholder="Désignation"
            />
            {errors?.name && <ErrorHelper message={errors.name} />}
          </Grid>
          {id && (
            <Grid item xs className={classes.CheckboxContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isActive}
                    onChange={(e) => setValues((prev) => ({ ...prev, isActive: e.target.checked }))}
                    name="Actif"
                  />
                }
                label="Actif"
              />
            </Grid>
          )}
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={2}>
            <Label small>Type</Label>
          </Grid>
          <Grid item xs={4}>
            <RadioGroupWithLoading
              value={id && values.type && values.type.id ? values.type.id : values.type}
              vehiclesTypes={vehiclesTypes}
              onChange={(e) => setValues((prev) => ({ ...prev, type: e.target.value }))}
              isLoading={isLoadingTypes}
              isError={isErrorTypes}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={2}>
            <Label small>Immatriculation</Label>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={values.numberPlate}
              onChange={(e) => setValues((prev) => ({ ...prev, numberPlate: e.target.value }))}
              fullWidth
              placeholder="Immatriculation"
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={2}>
            <Label small>Capacité</Label>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={values.capacity}
              onChange={(e) => setValues((prev) => ({ ...prev, capacity: e.target.value }))}
              fullWidth
              placeholder="Capacité"
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
              }}
            />
            {errors?.capacity && <ErrorHelper message={errors.capacity} />}
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={2}>
            <Label small>Email chauffeur</Label>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={values.email}
              onChange={(e) => setValues((prev) => ({ ...prev, email: e.target.value }))}
              fullWidth
              placeholder="Email"
            />
            {errors?.email && <ErrorHelper message={errors.email} />}
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={2}>
            <Label small>Code DAC</Label>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={values.dacCode}
              onChange={(e) => setValues((prev) => ({ ...prev, dacCode: e.target.value }))}
              fullWidth
              placeholder="Code DAC"
            />
            {errors?.dacCode && <ErrorHelper message={errors.dacCode} />}
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={2}>
            <Label small>Zone</Label>
          </Grid>
          <Grid item xs={4}>
            <ZoneSelector
              value={values.startZone}
              onChange={(e, zone) => setValues((prev) => ({ ...prev, startZone: zone }))}
            />
            {errors?.startZone && <ErrorHelper message={errors.startZone} />}
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <ButtonsContainer>
          <SubmitAndRedirectButton
            onClick={submit}
            isLoading={isAdding || isUpdating}
            isSuccess={isAdded || isUpdated}
            redirectPath={routes.vehicles.root}
            isError={errors}
          />
          <ButtonWithLoading onClick={submit} isLoading={isAdding || isUpdating}>
            Valider
          </ButtonWithLoading>

          <CancelButton redirectPath={routes.vehicles.root} />
        </ButtonsContainer>
      </Grid>
    </Container>
  );
}

export default VehicleForm;
