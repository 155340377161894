import { useState } from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import ConfirmDialog from 'components/DialogBoxes/ConfirmDialog';
import { useHistory } from 'react-router-dom';

const DEFAULT_LABEL = 'Annuler';
const DEFAULT_CONFIRM_MESSAGE =
  'Êtes-vous sûr de vouloir annuler ? Les données saisies seront supprimées.';

/**
 * Cancel button that displays a confirmation dialog and redirects to a path when confirmed.
 */
function CancelButton({
  children = DEFAULT_LABEL,
  confirmMessage = DEFAULT_CONFIRM_MESSAGE,
  redirectPath,
  ...other
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const history = useHistory();

  return (
    <>
      <Button color="primary" {...other} onClick={() => setDialogOpen(true)}>
        {children}
      </Button>
      <ConfirmDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        message={confirmMessage}
        onConfirm={() => history.push(redirectPath)}
      />
    </>
  );
}

CancelButton.propTypes = {
  /** Button children, e.g. button label */
  children: PropTypes.string,
  /** Message displayed on the confirmation dialog */
  confirmMessage: PropTypes.string,
  /** Path where the button should redirect if the cancellation is confirmed */
  redirectPath: PropTypes.string.isRequired,
};

export default CancelButton;
