import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';

import { PICKUP_STATUS } from 'config/constants';
import { Table } from 'components';

const headCells = [
  { id: '1', label: 'DAC' },
  { id: '2', label: 'Client' },
];
/**
 *  show the pickups that belongs to the selected deposit, and have a status COLLECTED
 */
function PickupsTable({ values }) {
  return (
    <Table
      size="small"
      data={values.filter(
        (pickup) => pickup.status && pickup.status.codename === PICKUP_STATUS.COLLECTED,
      )}
      getKey={(pickup) => pickup.id}
      getRowKey={(pickup) => pickup.id}
      headCells={headCells}
      isSortable={false}
      renderRow={(row) => (
        <TableRow key={row.id}>
          <TableCell> {row.dacNumber}</TableCell>
          <TableCell> {row.client.name}</TableCell>
        </TableRow>
      )}
    />
  );
}

export default PickupsTable;
