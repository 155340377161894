import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';

import {
  addPlatformHours as addRequest,
  updatePlatformHours as updateRequest,
  deletePlatformHours as deleteRequest,
} from 'services/api/terraLeoApi/platformHoursApi';

function useAddPlatformHoursMutation() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(addRequest, {
    onError: () => {
      enqueueSnackbar("Une Erreur est survenue lors de l'ajout des horaires.", {
        variant: 'error',
      });
    },
  });
}

function useUpdatePlatformHoursMutation() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(updateRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue lors de la modification des horaires.', {
        variant: 'error',
      });
    },
  });
}

function useDeletePlatformHoursMutation() {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(deleteRequest, {
    onError: () => {
      enqueueSnackbar('Une Erreur est survenue lors de la suppression des horaires.', {
        variant: 'error',
      });
    },
  });
}

export {
  useAddPlatformHoursMutation,
  useUpdatePlatformHoursMutation,
  useDeletePlatformHoursMutation,
};
